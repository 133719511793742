// import necessary libraries //
import Quill from 'quill';
import TaskBlot from './format-task-blot.js';

// import store so we can have access to store actions and getters //
import { store } from '../../../../../store/index.js';

// define module-specific variables //
const Delta = Quill.import('delta');
const Module = Quill.import('core/module');

// register format-task-blot //
Quill.register('formats/task', TaskBlot);

// declare TaskModule //
class TaskModule extends Module {
  constructor(quill, options) {
    super(quill, options);

    // define constructor variables //
    this.quill = quill;
    this.onClose = options.onClose;
    this.onOpen = options.onOpen;
    this.toolbar = quill.getModule('toolbar');
    this.ignoreTags = ['PRE'];

    // define pattern for keyboard shortcut to create new task //
    this.matches = [{
      name: 'taskBlot',
      pattern: /^(\/t)\s$/ig,
      action: (text, selection, pattern) => {
        console.log("match: " + pattern, text);
        var match = pattern.exec(text);
        if (!match) return


        const size = match[0].length;
        const startIndex = selection.index - size;
        console.log(match.index)
        setTimeout(() => {
          quill.deleteText(startIndex, size)
          quill.insertEmbed(quill.selection.savedRange.index, 'taskBlot', {title: "", uuid: "", instantiator: store.getters['authentication/getUser'].uid}, Quill.sources.USER);
          //quill.insertEmbed(quill.selection.savedRange.index + 5, '', Quill.sources.USER); //this throws an error
        }, 0)
      }
    }];

    // extract task-specific toolbar buttons //
    var taskButtons = document.getElementsByClassName('ql-task');
    if (taskButtons) {
      [].slice.call( taskButtons ).forEach(function ( taskButton ) {
        taskButton.innerHTML = options.buttonIcon;
      });
    }

    // handler that looks for insert deltas that match specific characters //
    this.quill.on('text-change', (delta, oldContents, source) => {
      for (let i = 0; i < delta.ops.length; i++) {
        if (delta.ops[i].hasOwnProperty('insert')) {
          if (delta.ops[i].insert == ' ' || delta.ops[i].insert === '\n') {
            return this.onSpaceOrEnter()
          }
        }
      }
    })

    // initialize task module //
    fn_taskInit(quill);
  }

  // function for determining if a value is valid //
  isValid (text, tagName) {
    return (
      typeof text !== 'undefined' && text && this.ignoreTags.indexOf(tagName) === -1
    )
  }

  // function that checks if entered text matches the match text for the task keyboard shortcut regex //
  onSpaceOrEnter () {
    const selection = this.quill.getSelection()
    if (!selection) return
    const [line, offset] = this.quill.getLine(selection.index)
    const text = line.domNode.textContent
    const lineStart = selection.index - offset
    if (this.isValid(text, line.domNode.tagName)) {
      for (let match of this.matches) {
        const matchedText = text.match(match.pattern)
        if (matchedText) {
          match.action(text, selection, match.pattern, lineStart)
          return
        }
      }
    }
  }

}


TaskModule.DEFAULTS = {
  buttonIcon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">    <path d="M19,3h-4.184C14.403,1.837,13.304,1,12,1S9.597,1.837,9.184,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14 c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M12,3c0.552,0,1,0.448,1,1c0,0.552-0.448,1-1,1s-1-0.448-1-1 C11,3.448,11.448,3,12,3z M9,17H7v-2l6.146-6.146l2,2L9,17z M16.854,9.146l-1,1l-2-2l1-1c0.195-0.195,0.512-0.195,0.707,0 l1.293,1.293C17.049,8.635,17.049,8.951,16.854,9.146z M19,19H5V5h14V19z"></path></svg>'
};

// main initializiation function for the task module //
function fn_taskInit(quill) {
  let taskButton = document.querySelector('.ql-task');
  // specifically for inserting a new task when the toolbar icon is clicked //
  taskButton.addEventListener('click', function() {
    quill.insertEmbed(quill.selection.savedRange.index, 'taskBlot', {title: "", uuid: "", instantiator: store.getters['authentication/getUser'].uid}, Quill.sources.USER);
    
    //quill.insertEmbed(quill.selection.savedRange.index + 5, '', Quill.sources.USER); //this throws an error
  });
}

export default TaskModule;