/* templates.store.js */

// define initial state object //
const initialState = () => ({
  availableTemplates: null
});

// load state object //
const state = initialState();

// templates getter functions //
const getters = {
  getAvailableTemplates(state) {
    return state.availableTemplates
  }
}

// templates actions // 
const actions = {

}
// templates mutations //
const mutations = {
  storeAvailableTemplates (state, templates) {
    state.availableTemplates= templates
  },
  templatesLogout: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
          state[key] = newState[key]
    });
  },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}