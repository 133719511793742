<template>
  <div class="notes-editor-wrapper w-full h-full">
    <div class="notes-editor-inner h-full flex flex-col">


      <div class="notes-editor-header flex flex-col md:flex-row py-2 px-5 md:justify-between border-greythree bg-white">
        <div class="flex items-center">
          <a @click="$router.go(-1)" class="cursor-pointer p-0.5 rounded-md bg-light hover:bg-primaryonelight rounded-md">
            <svg class="w-5 fill-current text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 10.8125 9.28125 L 4.09375 16 L 10.8125 22.71875 L 12.21875 21.28125 L 7.9375 17 L 28 17 L 28 15 L 7.9375 15 L 12.21875 10.71875 Z"></path></svg>
          </a>
          <div class="flex ml-3">
            <div class="">
              <div class="text-2xl font-gteesti font-medium antialiased text-dark">{{ meetingTitle }}</div>
                <div class="flex">
                  <p class="text-xs font-light text-gray-500">{{ meetingDate }}</p> <p v-show="summarySent" class="font-gtamerica font-normal mx-6 px-2 text-xs rounded-full text-primaryone bg-primaryonelight margin-x-10 uppercase text-gray-500">Summary Sent</p>
                  <label for="public-meeting" :class="{ 'active': publicMeeting }" class="text-sm mr-1 ml-4" v-show="isOwnerOrParticipant"> Public Meeting</label>
                  <div class="toggle" :class="{ 'active': publicMeeting }" @click="togglePublicMeeting" v-show="isOwnerOrParticipant">
                    <div class="toggle-switch"></div>
                  </div>                  

                </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <!-- <a class="cursor-pointer" @click="this.particpantsModalActive = !this.particpantsModalActive">
            <svg class="w-6 mr-4 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 4.5 3 C 3.125 3 2 4.125 2 5.5 C 2 6.441406 2.535156 7.253906 3.304688 7.679688 C 1.40625 8.210938 0 9.9375 0 12 L 1 12 C 1 10.0625 2.5625 8.5 4.5 8.5 C 5.65625 8.5 6.664063 9.0625 7.296875 9.929688 C 7.113281 10.421875 7 10.945313 7 11.5 C 7 13.980469 9.019531 16 11.5 16 C 13.980469 16 16 13.980469 16 11.5 C 16 9.617188 14.832031 8.003906 13.183594 7.335938 C 13.679688 6.875 14 6.226563 14 5.5 C 14 4.125 12.875 3 11.5 3 C 10.125 3 9 4.125 9 5.5 C 9 6.226563 9.320313 6.875 9.816406 7.335938 C 8.988281 7.671875 8.28125 8.242188 7.78125 8.96875 C 7.230469 8.367188 6.527344 7.902344 5.71875 7.671875 C 6.476563 7.238281 7 6.429688 7 5.5 C 7 4.125 5.875 3 4.5 3 Z M 4.5 4 C 5.335938 4 6 4.664063 6 5.5 C 6 6.335938 5.335938 7 4.5 7 C 3.664063 7 3 6.335938 3 5.5 C 3 4.664063 3.664063 4 4.5 4 Z M 11.5 4 C 12.335938 4 13 4.664063 13 5.5 C 13 6.335938 12.335938 7 11.5 7 C 10.664063 7 10 6.335938 10 5.5 C 10 4.664063 10.664063 4 11.5 4 Z M 11.5 8 C 13.4375 8 15 9.5625 15 11.5 C 15 13.4375 13.4375 15 11.5 15 C 9.5625 15 8 13.4375 8 11.5 C 8 10.910156 8.15625 10.363281 8.414063 9.875 L 8.4375 9.859375 C 8.4375 9.855469 8.433594 9.851563 8.429688 9.847656 C 9.019531 8.75 10.164063 8 11.5 8 Z M 11 9 L 11 11 L 9 11 L 9 12 L 11 12 L 11 14 L 12 14 L 12 12 L 14 12 L 14 11 L 12 11 L 12 9 Z"></path></svg>
          </a> -->
          <!-- <a class="cursor-pointer">
            <svg class="w-6 mr-4 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 3.5 2 C 2.675781 2 2 2.675781 2 3.5 L 2 14 L 14 14 L 14 11 L 12 11 L 12 3.5 C 12 2.675781 11.324219 2 10.5 2 Z M 3.5 3 L 7 3 L 7 11 L 3 11 L 3 3.5 C 3 3.21875 3.21875 3 3.5 3 Z M 8 3 L 10.5 3 C 10.78125 3 11 3.21875 11 3.5 L 11 11 L 8 11 Z M 4 4 L 4 5 L 6 5 L 6 4 Z M 4 6 L 4 7 L 6 7 L 6 6 Z M 9 9 L 9 10 L 10 10 L 10 9 Z M 3 12 L 13 12 L 13 13 L 3 13 Z"></path></svg>
          </a> -->
          <a class="cursor-pointer" @click="toggleTemplateModal()">
            <svg class="w-6 mr-4 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 4.5 2 C 3.675781 2 3 2.675781 3 3.5 L 3 12.5 C 3 13.324219 3.675781 14 4.5 14 L 9 14 L 9 13 L 4.5 13 C 4.21875 13 4 12.78125 4 12.5 L 4 3.5 C 4 3.21875 4.21875 3 4.5 3 L 11.5 3 C 11.78125 3 12 3.21875 12 3.5 L 12 9 L 13 9 L 13 3.5 C 13 2.675781 12.324219 2 11.5 2 Z M 5 4 L 5 5 L 11 5 L 11 4 Z M 5 6 L 5 7 L 11 7 L 11 6 Z M 5.5 8 C 5.222656 8 5 8.222656 5 8.5 L 5 11.5 C 5 11.777344 5.222656 12 5.5 12 L 10.5 12 C 10.777344 12 11 11.777344 11 11.5 L 11 8.5 C 11 8.222656 10.777344 8 10.5 8 Z M 6 9 L 10 9 L 10 11 L 6 11 Z M 12 10 L 12 13 L 10 13 L 12.5 15.5 L 15 13 L 13 13 L 13 10 Z"></path></svg>
          </a>
          <!-- <a class="cursor-pointer" @click="privateMeetingNoteClick">
            <svg class="w-6 mr-4 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 13.330078 1.0039062 C 13.00355 1.0059962 12.678783 1.1310493 12.435547 1.3808594 L 7.7714844 6.1230469 L 7.0761719 9.0488281 L 10.001953 8.3535156 L 10.099609 8.2558594 L 14.744141 3.6894531 C 15.243761 3.2029808 15.24874 2.3874144 14.755859 1.8945312 L 14.230469 1.3691406 C 13.984027 1.1226991 13.656606 1.0018122 13.330078 1.0039062 z M 13.335938 1.9960938 C 13.402619 1.9957637 13.469879 2.0226118 13.523438 2.0761719 L 14.048828 2.6015625 C 14.155945 2.7086795 14.155302 2.8690817 14.046875 2.9746094 L 14.044922 2.9746094 L 9.5019531 7.4433594 L 8.4238281 7.7011719 L 8.6796875 6.6269531 L 13.150391 2.0800781 L 13.150391 2.078125 C 13.203154 2.0239351 13.269256 1.9964238 13.335938 1.9960938 z M 4.5 2 C 3.67 2 3 2.67 3 3.5 L 3 4 L 2 4 L 2 5 L 4 5 L 4 3.5 C 4 3.22 4.22 3 4.5 3 L 9.4394531 3 L 10.419922 2 L 4.5 2 z M 3 6 L 3 6.5 L 3 7 L 2.5 7 L 2 7 L 2 8 L 2.5 8 L 4 8 L 4 6.5 L 4 6 L 3 6 z M 13 6.8105469 L 12 7.7890625 L 12 12.5 C 12 12.78 11.78 13 11.5 13 L 4.5 13 C 4.22 13 4 12.78 4 12.5 L 4 12 L 3 12 L 3 12.5 C 3 13.33 3.67 14 4.5 14 L 11.5 14 C 12.33 14 13 13.33 13 12.5 L 13 6.8105469 z M 3 9 L 3 9.5 L 3 10 L 2.5 10 L 2 10 L 2 11 L 2.5 11 L 4 11 L 4 9.5 L 4 9 L 3 9 z"></path></svg>
          </a> -->
          <a class="cursor-pointer" @click="this.shareNotesModalActive = !this.shareNotesModalActive">
            <svg class="w-6 mr-4 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 10.605469 1.023438 L 9.898438 1.726563 L 12.167969 4 L 10.125 4 C 8 4 6.210938 4.933594 4.976563 6.382813 C 3.742188 7.832031 3.039063 9.785156 3 11.863281 C 3 11.921875 3 11.964844 3 12 L 4 12 C 4 11.949219 4 11.910156 4 11.886719 C 4.035156 10.015625 4.667969 8.28125 5.734375 7.03125 C 6.804688 5.78125 8.289063 5 10.125 5 L 12.167969 5 L 9.898438 7.273438 L 10.605469 7.976563 L 14.082031 4.5 Z M 1 4 L 1 12.5 C 1 13.324219 1.675781 14 2.5 14 L 12.5 14 C 13.324219 14 14 13.324219 14 12.5 L 14 9 L 13 9 L 13 12.5 C 13 12.78125 12.78125 13 12.5 13 L 2.5 13 C 2.21875 13 2 12.78125 2 12.5 L 2 4 Z"></path></svg>
          </a>
          <a class="cursor-pointer" @click="this.shortcutsModalActive = !this.shortcutsModalActive">
            <svg class="w-6 fill-current hover:text-primaryone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M 1.5 3 C 0.675781 3 0 3.675781 0 4.5 L 0 10.5 C 0 11.324219 0.675781 12 1.5 12 L 13.5 12 C 14.324219 12 15 11.324219 15 10.5 L 15 4.5 C 15 3.675781 14.324219 3 13.5 3 Z M 1.5 4 L 13.5 4 C 13.78125 4 14 4.21875 14 4.5 L 14 10.5 C 14 10.78125 13.78125 11 13.5 11 L 1.5 11 C 1.21875 11 1 10.78125 1 10.5 L 1 4.5 C 1 4.21875 1.21875 4 1.5 4 Z M 2 5 L 2 6 L 3 6 L 3 5 Z M 4 5 L 4 6 L 5 6 L 5 5 Z M 6 5 L 6 6 L 7 6 L 7 5 Z M 8 5 L 8 6 L 9 6 L 9 5 Z M 10 5 L 10 6 L 11 6 L 11 5 Z M 12 5 L 12 6 L 13 6 L 13 5 Z M 2 7 L 2 8 L 3 8 L 3 7 Z M 4 7 L 4 8 L 5 8 L 5 7 Z M 6 7 L 6 8 L 7 8 L 7 7 Z M 8 7 L 8 8 L 9 8 L 9 7 Z M 10 7 L 10 8 L 11 8 L 11 7 Z M 12 7 L 12 8 L 13 8 L 13 7 Z M 5 9 L 5 10 L 10 10 L 10 9 Z M 2 9.007813 L 2 10.011719 L 4 10.011719 L 4 9.007813 Z M 11.015625 9.007813 L 11.015625 10.011719 L 13.015625 10.011719 L 13.015625 9.007813 Z"></path></svg>
          </a>
      

        </div>
      </div>
        

      <div class="notes-editor-content">
        <div class="md:flex">
          <div class="w-full mx-auto">
            <div>
              <quill-editor-component ref="editor" :content="editorContent" @ready="updateEditorHtml" @change="onEditorContentUpdated"></quill-editor-component>
            </div>
          </div>
          <!-- <div class="bg-gray-50 border-t-2 border-l-2 border-gray-100 px-4 py-6 transform top-0 right-0 w-1/3 bg-white absolute h-full overflow-auto ease-in-out transition-all duration-500 z-30" :class="privateMeetingNoteActive ? '-translate-x-1/6' : 'translate-x-full'" >
            <div @click="privateMeetingNoteClick()" class="private-editor-close absolute top-6 right-6 py-1 px-1 cursor-pointer bg-white rounded-sm border-t-2 border-l-2 border-gray-100">
              <svg class="text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path></svg>
            </div>
            <h4 class="text-sm uppercase text-gray-400">Private Notes</h4>  
            <quill-private-notes-component ref="privateNotesEditor" :content="privateNotesContent" @change="onPrivateNotesEditorContentUpdated"></quill-private-notes-component>                   
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!--<transition name="fade">
    <notes-participants-modal-component v-if="isMeetingLoaded"
    v-show="particpantsModalActive"
    :participants="this.participants"
    v-on:CloseParticipantsModal="this.particpantsModalActive = false"
    />
  </transition>-->

  <transition name="fade">
    <meeting-notes-sharing-modal v-if="isMeetingLoaded"
      v-show="shareNotesModalActive" 
      v-on:CloseSharingModal="this.shareNotesModalActive = false" 
      v-on:meetingSummaryWasSent="this.summarySent = true" 
      :participants="this.participants"     
      :editorContent="this.editorContent" 
      :meetingDocumentId="this.$route.params.document"
      :meetingTitle="this.meetingTitle" 
      :meetingDateTime="this.meetingDateTime" 
      :scheduledMeetingTime="this.scheduledMeetingTime"
    />
  </transition>
  
  <transition name="fade">
    <templates-modal v-if="isMeetingLoaded"
      v-show="templatesModalActive" 
      v-on:CloseTemplatesModal="this.templatesModalActive = false"   
      :editorContent="this.editorContent" 
      :editorQuillContent="this.editorQuillContent"
      v-on:templateContentLoaded="loadFromTemplate"
    />
  </transition>
  <transition name="fade">
    <keyboard-shortcuts-modal-component v-show="shortcutsModalActive" v-on:CloseShortcutsModal="this.shortcutsModalActive = false"/>
  </transition>
</template>

<script>

//import PrivateEditorComponent from '../components/PrivateNotesEditorComponent.vue';
import QuillEditorComponent from '../components/QuillEditor/QuillEditorComponent.vue';
import QuillPrivateNotesComponent from '../components/QuillEditor/QuillPrivateNotesComponent.vue';
import { getPriorMeeting, getTasks, loadMeetingData, getMeetingTemplates, getSingleCalendarEvent, updateMeetingProperty, updateMeetingAttendees } from '../utilities/CalendarUtilities.js';
import { initializeTrelloIntegration } from '../utilities/TrelloUtilities.js';

import MeetingNotesSharingModal from '../components/NotesSharingModal.vue';
import TemplatesModal from '../components/TemplatesModal.vue';
import KeyboardShortcutsModalComponent from '../components/KeyboardShortcutsModalComponent.vue';
import Swal from 'sweetalert2';
//import NotesParticipantsModalComponent from '../components/NotesParticipantsModalComponent.vue';

export default {
  name: 'Editor',
  data: () => ({
    isMeetingLoaded: false,
    hoverMenu: '',
    meetingData: '',
    particpantsModalActive: false,
    shareNotesModalActive: false,
    syncTaskModalActive: false,
    templatesModalActive: false,
    editorContent: '',
    editorQuillContent: {},
    privateNotesContent: '',
    participants: [],
    meetingTitle: '',
    meetingDate: '',
    meetingStartTime: '',
    meetingEndTime: '',
    scheduledMeetingTime: '',
    shortcutsModalActive: false,
    privateMeetingNoteActive: false,
    summarySent: false, 
    publicMeeting: Boolean(), 
    isOwnerOrParticipant: false
  }),
  created() {
    
  },
  components: {
    MeetingNotesSharingModal,
    KeyboardShortcutsModalComponent,
    QuillEditorComponent,
    QuillPrivateNotesComponent,
    TemplatesModal,
    //NotesParticipantsModalComponent
  },
  computed: {

  },
  emits: ['viewDataLoaded'],
  methods: {
    onEditorContentUpdated(value) {     
      //console.log('onEditorContentUpdated: ', value);
      this.editorContent = value.html;
      this.editorQuillContent = value.content;
    },
    onPrivateNotesEditorContentUpdated(value) {
      this.privateNotesContent = value.html;
    },
    privateMeetingNoteClick() {
      this.privateMeetingNoteActive = !this.privateMeetingNoteActive;      
    },
    loadFromTemplate(data) {    
      console.log('run loadFromTemplate: ', data);  
      this.editorContent = data.body;
      //TODO
      //merge this
      //this.editorQuillContent = data.quillContent;
      this.$refs.editor.updateFromTemplate(data);
    },
    loadPrivateNotesInChild(value) { 
      this.$refs.privateNotesEditor.updateFromParent(value);
    },
    toggleTemplateModal() {
      this.templatesModalActive = !this.templatesModalActive;
    },
    togglePublicMeeting() {
      this.publicMeeting = !this.publicMeeting;      

      let swalText = this.publicMeeting ? 'public' : 'private';      

      if (this.meetingData.recurringEventId) {
        let recurringPublicSet = false;
        Swal.fire({
          title: 'Recurring Meeting Update',
          text: "This is a recurring meeting. Do you want all future events to be "+swalText+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1f3fff',
          cancelButtonColor: '#999999',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            recurringPublicSet = true;
            Swal.fire(
              'Updated!',
              'Your future recurring meetings for this event will be set to '+swalText+'.',
              'success'
            )
          } else {
            Swal.fire(
              'Updated!',
              'Only this meeting will be set to '+swalText+'.',
              'success'
            )
          }
          updateMeetingProperty(this.meetingData.documentId,'public',this.publicMeeting,recurringPublicSet);
        });
      } else {
        updateMeetingProperty(this.meetingData.documentId,'public',this.publicMeeting,false);
      }
      
      
    },

    updateEditorHtml() {
      const htmlContent = '<p>This is the HTML content to be set in the Quill editor.</p>';
      // this.$refs.editor.updateHtml(htmlContent);
    }
  },
  async beforeMount() {
    console.log('notes editor: beforeMount');

    let documentId = this.$route.params.document;
    let meetingId = this.$route.params.meeting;
    let result = await loadMeetingData(documentId);
    
    this.meetingData = result;
    let userObject = this.$store.getters["authentication/getUser"];

    //get attendee list from meeting
    this.participants = result?.attendees; 
    this.organizer = result?.organizer?.email;

    //if result.public does not exist or is false, and user.email is not in attendee list, redirect
    const isUserInParticipants = userObject && this.participants.some(
      participant => participant.email.toLowerCase() == userObject.email.toLowerCase()
    );
    const isOrganizer = this.organizer.toLowerCase() == userObject.email.toLowerCase();   
    
    if (isUserInParticipants || isOrganizer) this.isOwnerOrParticipant = true;

    if (!isUserInParticipants && !isOrganizer && (this.meetingData?.public === false)) {
      console.log(userObject.email + ' is not an organizer or participant in meeting: ' + this.meetingData.name);
      return this.$router.push('/upcoming');
    }

    if (this.meetingData.summarySent && this.meetingData.summarySent == true) {
      this.summarySent = true;
    }

    //This line includes legacy support for meetings that do not have a public property
    //This only applies to people using the Alpha product (McGaw team members)
    this.publicMeeting = (this.meetingData?.public === true || !this.meetingData?.hasOwnProperty('public')) ? true : false;

    if (this.meetingData.summarySent && this.meetingData.summarySent == true) {
      this.summarySent = true;
    }

    //This line includes legacy support for meetings that do not have a public property
    //This only applies to people using the Alpha product (McGaw team members)
    this.publicMeeting = (this.meetingData?.public === true || !this.meetingData?.hasOwnProperty('public')) ? true : false;


    console.log('meeting data', this.meetingData);
    console.log(this.meetingData);
    if (this.meetingData && this.meetingData.name) document.title = this.meetingData.name + ' - Holepunch';
    
    //update attendees based on gcal meeting (keep attendees in sync)
    //we can assume that we can search the meeting in vuex
    //the paginated functions will update the store
    if (result.summarySent && result.summarySent == true) {
      this.summarySent = true;
    }
    
    let allMeetings = this.$store.getters["meetings/getCalendarEvents"] ? this.$store.getters["meetings/getCalendarEvents"] : [];
    let loadedGcalMeeting = allMeetings.find(item => item.id === meetingId);

    if (!loadedGcalMeeting) {
      //get loaded meeting from paged meeting store
      allMeetings = this.$store.getters["meetings/getPagedCalendarEvents"] ? this.$store.getters["meetings/getPagedCalendarEvents"] : [];
      loadedGcalMeeting = allMeetings.find(item => item.id === meetingId);
    }

    //query the specific event from GA so we can update it
    //if token is null, skip
    
    let loadedSingleEvent = null;
    if (userObject && userObject.integrations && userObject.integrations.google && userObject.integrations.google.googleAccessToken) {
      let primaryCalendar = this.$store.getters["calendars/getPrimaryCalendar"];
      if (primaryCalendar) { 
        loadedSingleEvent = await getSingleCalendarEvent(userObject.integrations.google.googleAccessToken,primaryCalendar,meetingId);
        if (loadedSingleEvent) {
          loadedSingleEvent = JSON.parse(loadedSingleEvent);
          console.log('loaded event: ', loadedSingleEvent);
        }
      }
    }

    if (loadedSingleEvent && loadedSingleEvent.summary) {
      if (result.name !== loadedSingleEvent.summary) {
        //meeting name has been changed in GCal
        this.meetingTitle = loadedSingleEvent.summary;
      } else {
        this.meetingTitle = result.name;
      }
    } else {
      this.meetingTitle = result.name;
    }


    //check for loaded meeting
    let meetingDateTime = new Date(result.start.dateTime);

    if (loadedSingleEvent || loadedGcalMeeting) {      
      let curParticipants = this.participants;      
      if (loadedSingleEvent) {
        if (loadedSingleEvent.attendees) {
          console.log('updated from gcal');
          this.participants = loadedSingleEvent.attendees;          
        }
      } else if (loadedGcalMeeting) {
        if (loadedGcalMeeting.attendees) {
          console.log('updated from stored gcal');
          this.participants = loadedGcalMeeting.attendees;          
        }
      }

      //TODO
      //lets update owners/creators IF changed?
    
      //we should update the participants list on the saved meeting          
      

      
      //if the loadedSingleEvent start time is different than the imported meeting timestamp
      //we should update the meeting timestamp to the new one
      

      if (loadedSingleEvent) {
        if (loadedSingleEvent.start && loadedSingleEvent.start.dateTime && result.start && result.start.dateTime) {
            var loadedDate = new Date(loadedSingleEvent.start.dateTime);
            var meetingDataDate = new Date(result.start.dateTime);

            if(loadedDate.getTime() > meetingDataDate.getTime() || loadedDate.getTime() < meetingDataDate.getTime()){
                //the loaded gcal date is different, update the meeting in Firebase
                //this helps keep the startTimestamp up to date
                console.log('update startTimestamp');
                let updateFromGCal = await updateMeetingProperty(documentId,'startTimestamp',loadedDate); 
                meetingDateTime = loadedDate;                
            }
        }
      }
    }

    var month = meetingDateTime.getMonth();
    var year = meetingDateTime.getFullYear();
    var date = meetingDateTime.getDate();

    this.meetingDate = (month + 1) + '/' + (date) + '/' + year;
    
    this.meetingDateTime = result.start.dateTime;
    this.scheduledMeetingTime = result.start.dateTime;
    
    if (result.organizer) {
      let organizer = result.organizer;

    //if owner is not already in the participants, add them!  
      let organizerInParticipants = this.participants.filter(o => o.email == organizer.email);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    
      if (organizerInParticipants.length < 1) {
        this.participants.push(organizer);
      }
    }

    this.isMeetingLoaded = true;
    this.$emit("viewDataLoaded");
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.notes-editor-header {
  height: 64px;
  border-bottom: 1px solid #f4f6f8;
}
.notes-editor-content {
  height: calc(100% - 64px);
  overflow-y: auto;
  background-color: #fff;
}

</style>