<template>
  <div ref="privateNotesEditor" class="w-full mx-auto mt-4 py-2 font-gtamerica" id="privateNotesEditor"></div>
  <div class="w-3/4 mx-auto py-5 text-right text-gray-400" id="counter"></div>
</template>

<script>
// import functions //


// import libraries //
import _Quill from 'quill';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';
import { DOMAIN, WEBSOCKET_PORT } from '../../utilities/ServerUtilities.js';
import { TaskBlot, Task } from './modules/task/task.js';
import { RecurringMeetingBlockBlot, RecurringMeetingBlock } from './modules/recurringMeetingBlock/recurringMeetingBlock.js';

// import modules //

const Quill = window.Quill || _Quill;
var Delta = Quill.import('delta');

// options //
const toolbarOptions = {}
const quillOptions = {  
  modules: {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        //[{ 'direction': 'rtl' }],
        //[{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        //['link'],
        ['emoji','task']
      ]
    },
    task: true,
    recurringMeetingBlock: true,
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
    history: {
      userOnly: true
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 800, // default
      maxHeight: 800, // default
      imageType: 'image/jpeg', // default
      debug: true, // default
      suppressErrorLogging: false, // default
    }
  },  
  placeholder: ``,
  theme: 'snow',
};

// register modules as necessary //

export default {
  name: "QuillPrivateNotesComponent",
  data() {
    return {
      _options: {},      
      quillOptions
    }
  },
  computed: {
    documentId() {
      const documentId = this.$route.params.document
      return documentId;
    },
  },
  emits: ['input','change','ready'],
  methods: {
    initialize() {
      console.log('private notes component initialize');
      if (this.$el) {

        console.log('privateNotes init');
        console.log(this.documentId);

        // options //
        this._options = quillOptions;

        // instance //
        this.quill = new Quill(this.$refs.privateNotesEditor, this._options);
       
        this.quill.enable(false);

        const ydoc = new Y.Doc();
        const ytext = ydoc.getText('quill');
        /*
        const provider = new WebsocketProvider(
          `ws://${DOMAIN}:${WEBSOCKET_PORT}`, this.documentId+'_privatenote', ydoc
        );
        provider.on('status', event => {
          this.status = event.status;
          console.log(this.status);
        });
        const binding = new QuillBinding(ytext, this.quill, provider.awareness);
        const awareness = provider.awareness;
        */

              
        //TODO      
        // set editor content from prop
        if (this.content) {  
          console.log('setContents',this.content);
          this.quill.setContents(this.content);
        }

        // disabled editor
        if (!this.disabled) {
          this.quill.enable(true)
        }

        //this.quill.on('editor-change', function(eventName, ...args) {   

        //});

        // update model if text changes
        var change = new Delta();
        
        //this.quill.on('text-change', (delta, oldDelta, source) => {                  
                           
            //not in use   

        //});
       

        // emit ready event
        this.$emit('ready', this.quill);
      }

      this.quill.on('text-change', (delta, oldDelta, source) => {
        //this will occur automatically when the document loads from websocket
        //it will also occur when a change occurs (someone editing the doc)
        try {

          this.$emit('change', {delta, oldDelta});             

        } catch (error) {
          
        }
      });
    },
    updateFromParent(value) {      
      //this method is for loading in existing private notes when parent meeting loads in
      this.quill.root.innerHTML = value;
    },
    updateFromEditTemplate(data) {
      this.quill.setContents(data);  
    },
    async getCurrentData() {
      return this.quill.getContents();
    }
  },
  mounted() {
    this.initialize()
  },
  beforeUnmount() {
    this.quill = null
    delete this.quill
  },
  props: {
    content: String,    
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
}
</script>
<style src="../../../../public/css/quill-styling.css"></style>
<style>
#privateNotesEditor {
  border: none;
  font-size: 1rem;
}
#privateNotesEditor > .ql-editor.ql-blank::before{
  font-size: .9rem;   
  margin-left: 2px;
}
.template-editor-modal .ql-toolbar.ql-snow {
  position: relative;
  top: auto;
}
</style>