import axios from "axios";
import _ from "lodash";

import { API_DOMAIN, PROTOCOL } from './ServerUtilities';
import { store } from "../../store/index.js";
import { logout } from "./AuthUtilities"
import { getExistingUser, updateGoogleUserIntegrationCredentails } from "../utilities/UserUtilities.js";

const api = axios.create();

api.interceptors.response.use(
  response => {
    return response.data || null;
  }
)

const refreshGoogleAccessToken = async () => {

    //console.log('refreshGoogleAccessToken');

    const userObject = await store.getters["authentication/getUser"];
    //console.log('refreshGoogleAccessToken userObject',userObject);
    
    const response = await axios.post(`${PROTOCOL}${API_DOMAIN}/api/googleAuth/refreshToken`,
        {
            refresh_token: userObject.integrations.google.googleRefreshToken,
        },
        {
            headers: {
                'Accept': '*',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XmlHttpRequest'
            },
        }
    );

    //console.log('refreshGoogleAccessToken response',response);

    if (response.status && response.status !== 200) {
      console.log('refreshGoogleAccessToken failure');
      logout();
    }

    const { access_token } = response.data;

    //save new token in store
    //console.log('calling updateUserGoogleCredentials',userObject, response.data);
    await updateUserGoogleCredentials(userObject, response.data);   

    return access_token;

}

async function updateUserGoogleCredentials(userData, refreshResponse) {

  //TODO
  //probably need error handling in this function

  //console.log('updateUserGoogleCredentials',userData, refreshResponse);

  let existingUser = await getExistingUser(userData.uid).then(async (data) => {                         
    return data;
  });

  let accessData = { integrations: {
    google: {
      googleAccessToken: refreshResponse.access_token,
      googleIdToken: refreshResponse.id_token,
      googleRefreshToken: refreshResponse.refresh_token,
      expiryTimestamp: refreshResponse.expiry_date,
      googleId: userData.uid
    }
  }};

  //console.log('updateUserGoogleCredentials new accessData',accessData)
  
  //copy user and update the google integration and store locally
  let updateUser = Object.assign({},existingUser[0]);

  //console.log('update user with new data');
  //console.log(updateUser);
  
  await updateGoogleUserIntegrationCredentails(existingUser[0].documentId, existingUser[0].uid, accessData.integrations); 
  
  updateUser.integrations["google"] = accessData.integrations.google;

  //console.log('updatedUser here, we should update local store',updateUser);

  //we need to update the user store here
  store.commit("authentication/storeUser", updateUser);

  return null;
}


const resendRequestWithNewToken = (requestConfig, newToken) => {
  console.log('resendRequestWithNewToken');
  requestConfig.headers.Authorization = `Bearer ${newToken}`;
  return axios(requestConfig);
}

async function extractErrorMessage(err) {
  // the UTM.io backend is very inconsiste about the return messages
  // here we try to map how to extract errors from the wide variety
  // of response format we found during development
  if (typeof err === 'string') {
    return err;
  }

  if (err && typeof err.response === 'undefined') {
    console.log('err with no response',err);
    return err;
  }
  
  if (err.response && typeof err.response.data === 'undefined') {
    console.log('err with response and no data',err);
    return err;
  }

  if (typeof err.response.data === 'object') {
    const responseData = err.response.data;
    if (responseData.text) {
      const text = await responseData.text();
      try {
        return JSON.parse(text);
      } catch (error) {}
    }
  }

  let errorMessage =
    _.get(err, 'response.data.message') ||
    _.get(err, 'response.data.errors') ||
    _.get(err, 'response.data.error') ||
    _.get(err, 'error.message') ||
    _.get(err, 'message') ||
    err;

  if (errorMessage && errorMessage.toJSON) {
    errorMessage = errorMessage.toJSON();
  }

  if (typeof errorMessage === 'object') {
    let errorMessageString = '';

    Object.keys(errorMessage).forEach((key) => {
      const textMessage = `${errorMessage[key]}`;
      if (textMessage !== '[object Object]') {
        errorMessageString += errorMessage[key] + '\n';
      }
    });

    if (errorMessageString === '') {
      console.log('Failed to get a friendly error message from ', err);
      errorMessage = 'Unknown error';
    } else {
      errorMessage = errorMessageString;
    }
  }

  return errorMessage;
}

async function throwFriendlyErrorMessage(err) {
  const errorMessage = await extractErrorMessage(err);
  throw new Error(errorMessage);
}

const refreshTokenOnError = async (error) => {

    //console.log('refreshTokenOnError', error);

    if (!(error && error.response && error.response.status === 401)) {
        await throwFriendlyErrorMessage(error);
    }

    try {

        const newToken = await refreshGoogleAccessToken();

        if (!newToken) {
            console.log('newToken bad',newToken);
            await throwFriendlyErrorMessage(error);
        }

        let { data } =  await resendRequestWithNewToken(error.config, newToken);  //this method resends with axios
        return data;
    } catch (error) {

      //TODO
      //if there is some bad error like 500, logout
     
      //logout();
      console.log('refreshTokenOnError catch',error);
      await throwFriendlyErrorMessage(error);
    }
}

const get = async (route, params, config) => {
  try {
    let getResponse = await api.get(route, {
      params,
      ...config,
    });
    return getResponse;
  } catch (err) {
    console.error(err);
    return refreshTokenOnError(err);
  }
};

const post = async function (route, payload, config) {
    try {
      let postResponse = await api.post(route, payload, {
        ...config,
      });
      return postResponse;
    } catch (err) {
        console.error(err);
        return refreshTokenOnError(err);
    }
}

export {
    post,
    get
}