import Quill from 'quill';

/*

Thanks to "guillaumepotier" on StackOverflow for the solution to this problem which is to extend the Clipboard
and prevent the onPaste(event) call if target is DIV, INPUT or TEXTAREA

https://stackoverflow.com/questions/50274531/how-to-paste-into-a-text-input-contained-within-a-custom-quill-embed-blot

*/
const QuillClipboard = Quill.import('modules/clipboard');
class Clipboard extends QuillClipboard {
  onPaste (event) {
    // Contenteditables, inputs and textarea are embeded into rich elements
    // Do not capture the copy paste with Quill
    if (['DIV', 'INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) !== -1) {
      return;
    }

    super.onPaste(event);
  }
}
Quill.register('modules/clipboard', Clipboard, true);