// import core libraries //
import Quill from 'quill';

// for assigning a uuid to the task nodes //
//import { v4 as uuidv4 } from 'uuid';

// alert popup, specifically for deleting task nodes //
import Swal from 'sweetalert2';

// import store so we can have access to store actions and getters //
import { store } from '../../../../../store/index.js';

// core function for creating new tasks //
//import { createNewTask } from '../../../../utilities/TaskUtilities.js';

// import the task contents template //
import { RecurringMeetingBlockNode } from './recurringMeetingBlock-contents.js';
import { simpleTaskNode } from '../task/task-contents.js';

// import quill blot type //
let BlockEmbed = Quill.import('blots/block/embed');
//let ContainerBlot = Quill.import('blots/container');
//let Inline = Quill.import('blots/inline');
//let TextBlot = Quill.import('blots/text');

// define variable for the contents of the task //
const RecurringMeetingBlockContent = RecurringMeetingBlockNode;
const simpleTaskContent = simpleTaskNode;

class RecurringMeetingBlockBlot extends BlockEmbed {
  static create(value) {

    console.log('recurring block static create: ', value);

    let node = super.create();
    node.contentEditable = 'false';
    
    node.setAttribute('title',value.title);
    node.setAttribute('link',value.link);
    node.setAttribute('dateTime',value.dateTime);
    node.setAttribute('tasks',btoa(value.tasks));



    // sets the node content to the task html in the variable //

    ////

    node.innerHTML = RecurringMeetingBlockContent;

    let taskList = node.querySelector('.recurring-meeting-task-list');
    let tasks = JSON.parse(value.tasks);
    let title = value.title;
    let link = value.link;
    let dateTime = value.dateTime;
    

    console.log('gotta set tasks',tasks);
    console.log('simpleTaskNode',typeof simpleTaskContent);
    console.log(taskList);

    node.querySelector('h3').innerHTML = title;
    node.querySelector('.recurring-meeting-link').setAttribute('href',link);    
    node.querySelector('h5').innerHTML = dateTime;
    let deleteRecurringNode = node.querySelector('.delete-recurring-meeting-block');
    
    let userData = store.getters['authentication/getUser'];
    let token = (userData.integrations.trello && userData.integrations.trello.token) ? userData.integrations.trello.token : null;

     
    
      

      

    if (tasks) {
        for (let task of tasks) {
            console.log(task);
            if (task.insert.taskBlot.title) {
                let taskNode = document.createElement('div');
                taskNode.innerHTML = simpleTaskContent;
                taskList.append(taskNode);
                taskNode.querySelector('.simple-task-title').innerHTML = task.insert.taskBlot.title;    
                
                if (token && task.insert.taskBlot.trelloCard) {

                  let checkBoxNode = taskNode.querySelector('.checkmark');
                  let taskIsSyncedIcon = taskNode.querySelector('.taskIsSyncedIcon');

                    store.dispatch("integrations/getTrelloCard", {token:token,cardId:task.insert.taskBlot.trelloCard}).then(response => {
                        if (response.status == 200 && response.data) {
                          
                            console.log('response.data from Trello', response.data);  
                            
                              
                              taskNode.querySelector('.simple-task-title').innerHTML = response.data.name;
                              taskNode.querySelector('.link-to-trello').setAttribute('href',response.data.url);                           
                              taskNode.querySelector('.link-to-trello').classList.remove('hidden');
                              taskIsSyncedIcon.classList.remove('hidden');

                              //check checkbox based on response
                              checkBoxNode.checked = response.data.dueComplete; 
                       
                          } 
                    });    

                    //add listener to this taskNode checkbox                   
                    
                    checkBoxNode.onclick = function(e) {                    
                
                      let data = {dueComplete: e.target.checked};
                      if (task.insert.taskBlot.trelloCard) {
                        store.dispatch("integrations/updateTrelloCard", {token:token,cardId:task.insert.taskBlot.trelloCard, data: data}).then(response => {
                          //TODO error handling
                        });
                      }     
                
                    }
                    
                    
                }

                
    
            }
        }
    }

    deleteRecurringNode.onclick = function(e) {
      Swal.fire({
        title: 'Delete recurring meeting block?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1f3fff',
        cancelButtonColor: '#999999',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          node.remove();
          Swal.fire(
            'Deleted!',
            'Your block has been deleted.',
            'success'
          )
        }
      });
    }

    //let checkBoxNode = node.querySelector('.checkmark');
    //let taskTitleNode = node.querySelector('.task-title');
    //let deleteTaskNode = node.querySelector('.delete-task');
    ////let linkNode = node.querySelector('a');   
    ////let previousTitle = node.querySelector('h2');
    ////let previousDateTime = node.querySelector('h3');

    //TODO
    //we need to handle array of task data
    //this should be passed in when called

    //we need to setup event listeners for these elements to query Trello
    //maybe we can limit this to completed checkbox?

    //we could do full support but maybe not needed

    //there will be no delete button here, this block is not going to support the same actions

    return node;
    
  }

  length() {
    return 1
  }

  // function to run when task node is deleted //
  // return false makes it so that the task node will not be deleted with backspaces //
  deleteAt(index, length) {
    super.deleteAt(index, length);
    this.cache = {};
    // return false;
  }

  static value(node) {
    return {
        title: node.getAttribute('title'),
        link: node.getAttribute('link'),
        dateTime: node.getAttribute('dateTime'),
        tasks: atob(node.getAttribute('tasks'))
    }
  }

  static formats(node) {
    return { title: node.getAttribute('title') };
  }
  

}



// // function that gets fired when a new task is created //
//TODO I don't think we need this
//function recurringMeetingBlockBlotCreateNew(taskId) {
//  const UserId = store.getters['authentication/getUser'];
//  return createNewTask(UserId, taskId);
//}



RecurringMeetingBlockBlot.blotName = 'recurringMeetingBlockBlot';
RecurringMeetingBlockBlot.tagName = 'recurringMeetingBlockBlot';
RecurringMeetingBlockBlot.className = 'recurring-meeting-block-blot';

export default RecurringMeetingBlockBlot;