export const RecurringMeetingBlockNode = `<div class="relative recurring-meeting-block-wrapper">
<div class="recurring-meeting-block shadow border-2 px-4 py-2 pb-3" style="background-color: #fbfbfb;"> 
<div class="delete-recurring-meeting-block flex relative justify-end">
            <svg class="w-4 h-4 my-auto text-dark hover:text-primaryone fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg>  
          </div>
<h2 style="font-weight: 500;">Previous Meeting</h2>
    <a href="#" rel="noopener noreferrer" target="_blank" class="recurring-meeting-link">
        <h3 class="cursor-default"></h3>
    </a>

<h5 class="cursor-default mb-2" style="margin-bottom: 0.5rem;"></h5>
<div class="recurring-meeting-task-list"></div>
</div> 
</div>`