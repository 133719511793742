<template>
  <div class="w-screen h-screen absolute z-50 bg-black bg-opacity-10 backdrop-filter backdrop-blur-sm -mt-16 -ml-20">
    <div class="min-h-screen overflow-y-auto w-full flex items-center justify-center">
      <div class="shortcuts-modal w-full">
        <div class="flex flex-row w-full">
          <div class="px-4 py-5 bg-white rounded-tl-3xl w-full">
            <div class="text-4xl font-gteesti text-primaryone font-thin">
              Shortcuts &amp; Macros
            </div>
            <p class="text-md text-dark font-light">
              Keyboard shortcuts, editor macros, and other quick tips. 
            </p>
          </div>
          <div class="px-4 py-2 relative bg-white rounded-tr-full" >
            <svg @click="closeShortcutsModal" class="-top-3 -right-3 w-10 h-10 p-2 absolute bg-white rounded-full cursor-pointer fill-current shadow-lg hover:shadow-md text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
        <div class="w-full overflow-auto rounded-b-3xl border-t">
          <div class="flex px-4 py-4 bg-white w-full overflow-auto">
            <div class="text-md text-dark font-light w-1/2">
              <p class="text-lg font-normal font-gteesti">Marks</p>
              <p class="text-sm px-3 py-0.5 mb-3">Bold</p>
              <p class="text-sm px-3 py-0.5 mb-3">Italic</p>
              <p class="text-sm px-3 py-0.5 mb-3">Underline</p>
              <p class="text-sm px-3 py-0.5 mb-3">Strikethru</p>
              <p class="text-sm px-3 py-0.5 mb-3">Highlight</p>
              <p class="text-sm px-3 py-0.5 mb-3">Code</p>
            </div>
            <div class="text-md text-dark font-light">
              <p class="font-normal font-gteesti">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">cmd + b</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">cmd + i</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">cmd + u</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">cmd + shift + x</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
          <div class="flex px-4 py-2 bg-white w-full">
            <div class="text-md text-dark font-light w-1/2">
              <p class="text-lg font-normal font-gteesti">Elements</p>
              <p class="text-sm px-3 py-0.5 mb-3">Heading</p>
              <p class="text-sm px-3 py-0.5 mb-3">Ordered List</p>
              <p class="text-sm px-3 py-0.5 mb-3">Bulleted List</p>
              <p class="text-sm px-3 py-0.5 mb-3">Blockquote</p>
              <p class="text-sm px-3 py-0.5 mb-3">Codeblock</p>
            </div>
            <div class="text-md text-dark font-light">
              <p class="font-normal font-gteesti">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">cmd + opt + {1/2/3/4}</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">``` + ↩</p>
            </div>
          </div>
          <div class="flex px-4 py-2 bg-white w-full">
            <div class="text-md text-dark font-light w-1/2">
              <p class="text-lg font-normal font-gteesti">Nodes</p>
              <p class="text-sm px-3 py-0.5 mb-3">Tasks</p>
              <p class="text-sm px-3 py-0.5 mb-3">Divider</p>
            </div>
            <div class="text-md text-dark font-light">
              <p class="font-normal font-gteesti">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">/t + ↩ || /t + space</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">--- + ↩</p>
            </div>
          </div>
          <div class="flex px-4 py-2 bg-white w-full">
            <div class="text-md text-dark font-light w-1/2">
              <p class="text-lg font-normal font-gteesti">Miscellaneous</p>
              <p class="text-sm px-3 py-0.5 mb-3">Alignment</p>
            </div>
            <div class="text-md text-dark font-light">
              <p class="font-normal font-gteesti">&nbsp;&nbsp;&nbsp;</p>
              <p class="text-sm bg-greythree rounded-sm mb-3 px-3 py-0.5">&nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CloseShortcutsModal',
  data: () => ({

  }),
  components: {
  },
  methods: {
    closeShortcutsModal() {
      this.$emit('CloseShortcutsModal');
    }
  }
}
    
</script>
<style>
.shortcuts-modal {
  max-width: 50%;
  max-height: 50%;
  height: calc(90vh);
}
</style>