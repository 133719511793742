/* authentication.store.js */
import firebase from "firebase/app";
import "firebase/auth";
import { createNewUserFromGoogle, createNewUserFromPassword, updateGoogleUserIntegrationCredentails, getExistingUser, stateCleanupOnLogout } from '../../application/utilities/UserUtilities';  
import router from '../../router';
import { PROTOCOL, API_DOMAIN } from '../../application/utilities/ServerUtilities';


// helper functions for randomizing things //
const getRandomElement = list => {
  return list[Math.floor(Math.random() * list.length)]
}

const getRandomColor = () => {
  return getRandomElement([
    '#958DF1',
    '#F98181',
    '#FBBC88',
    '#FAF594',
    '#70CFF8',
    '#94FADB',
    '#B9F18D',
  ])
}

// define initial state object //
const initialState = () => ({
  authenticationMethod: null,
  user: null
});

// load state object //
const state = initialState();

// authentication getter functions //
const getters = {
  getUser(state) {
    return state.user;
  }
}

// authentication actions // 
const actions = {

  // login function for google authentication //
  googleLogIn: ({commit, dispatch}, data) => {

    console.log('begin googleLogIn');
    

    const googleAuthentication = async function (data) {
      console.log('doing googleAuthentication');
      //at this point, we have logged in and have a user from Google

      // log authentication method as google //
      commit('storeUserAuthenticationMethod', 'Google');

      // create user object to hold all of the data coming from google authentication //
      const newUser = {
        uid: data.data.id,       
        firstName: data.data.given_name,
        lastName: data.data.family_name,
        displayName: data.data.name,
        email: data.data.email,
        photo: data.data.picture,
        avatar: data.data.picture,
        hiddenMeetings: [],
        preferences: [],
        integrations: {
          google: {
            googleAccessToken: data.data.tokens.access_token,
            googleRefreshToken: data.data.tokens.refresh_token,
            googleIdToken: data.data.tokens.id_token,
            expiryTimestamp: data.data.tokens.expiry_date,
            googleId: data.data.id
          }
        }
      }

      console.log('newUser');console.log(newUser);

      //TODO
      //GIS will need to return us an idToken? along with the accessToken

      //We may be able to auth with just the accessToken, see here
      //https://firebase.google.com/docs/reference/js/v8/firebase.auth.GoogleAuthProvider#static-credential

      const credential = firebase.auth.GoogleAuthProvider.credential(
        data.data.tokens.id_token,
        data.data.tokens.access_token
        );
        console.log(credential);
        let fireBaseAuth = await firebase.auth().signInWithCredential(credential);
        console.log(fireBaseAuth);

      let userData;
      commit('storeUser', newUser);
      await getExistingUser(newUser.uid).then(async (data) => {
        console.log('getExistingUser then');console.log(data);
        userData = data;
      });

      // user already exists so just update their access data with the most recent token //
      if (userData.length > 0) {
        console.log('userData to accessData');
        let accessData = { integrations: {
          google: {
            googleAccessToken: data.data.tokens.access_token,
            googleRefreshToken: data.data.tokens.refresh_token,
            googleIdToken: data.data.tokens.id_token,
            expiryTimestamp: data.data.tokens.expiry_date,
            googleId: data.data.id
          }
        }}
          let updateUser = Object.assign(userData[0], accessData);

          console.log('continue Auth');        
        
          return updateGoogleUserIntegrationCredentails(userData[0].documentId, userData[0].uid, accessData.integrations)
        .then((updateUser) => {          
          router.push("/");
          return updateUser;
        });
      }

      // this is a new user so create them in the database //
      let updateUser;
      if (userData.length == 0) {

        //remove undefined props from newUser or adding user to Firebase will fail
        for (const key in newUser) {
          if (newUser[key] === undefined) {
            delete newUser[key];
          }
        }

        return createNewUserFromGoogle(newUser).then((documentId) => {
          const document = { documentId: documentId };
          updateUser = Object.assign(newUser, document);
          return updateUser;
        }).then(updatedUser => {
          commit('storeUser', updatedUser);
          return updatedUser;
        })
        .catch((error) => {
          console.log(error);
        })
        .then((user) => {
          router.push("/");
          return user;
        });
      }
    }
    return googleAuthentication(data);
  },
  ///TESTING
  refreshFirebaseCredential: async ({commit, dispatch}, data) => {
    console.log('attempt to reconnect with firebase');
    let reattemptData = data;

    //TODO
    //here we need to attempt to refresh the access token, then get another credential
    let url = `${PROTOCOL}${API_DOMAIN}/api/googleAuth/refreshToken`;
        const refreshResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': '*',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XmlHttpRequest'
          },
          body: JSON.stringify({
            refresh_token: reattemptData.refresh_token,
            //
          })
        }).then(function(response) {
          return response.json();
        }).then(function(data) { 
          return data;
        });

        //now we have new tokens
        if (refreshResponse.error) {
          //TODO
          //if response bad, go ahead and log them out?
          
        } else {
          //update user creds
          

          let credential = firebase.auth.GoogleAuthProvider.credential(
            refreshResponse.id_token,
            refreshResponse.access_token
            );
            console.log(credential);
          let fbReauth = await firebase.auth().signInWithCredential(credential);
          console.log(fbReauth);

            //since we reauth, we can now get user again
          let userData;
          await getExistingUser(reattemptData.uid).then(async (users) => {                         
            userData = users[0];
          });

          


          //TODO
          //can we get access token expiry here? Then we could refresh via the heartbeat
          let accessData = { integrations: {
            google: {
              googleAccessToken: refreshResponse.access_token,
              googleIdToken: refreshResponse.id_token,
              googleRefreshToken: refreshResponse.refresh_token,
              expiryTimestamp: refreshResponse.expiry_date,
              googleId: reattemptData.uid
            }
          }};
          
          //let updateUserDataObj = Object.assign(userData, accessData);
          //console.log('updateUserDataObj');console.log(updateUserDataObj);
          userData.integrations.google = accessData.integrations.google; //reassign after updating
          console.log('update user with new data');
          console.log(userData)
          commit('storeUser', userData);
          
          
          await updateGoogleUserIntegrationCredentails(userData.documentId, userData.uid, accessData.integrations); 
          
          /*await getExistingUser(reattemptData.uid)
          .then(async (ud) => {                         
            updatedUser = ud;
            console.log('getting user from firebase');console.log(updatedUser);
            
            return updatedUser;
          }).catch(err => {
            //handle possible error here
          });*/
          
          
        }

    
  },
  // login function for email and password authentication //
  passwordLogIn: ({ commit, dispatch }, payload) => {
    console.log('doing passwordLogIn');
    const email = payload.email;
    const password = payload.password;

    // firebase authentication method //
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(async (result) => {
      const uid = result.user.uid;
      const newUser = {
        uid: uid,
        firstName: '',
        lastName: '',
        displayName: result.user.displayName,
        email: result.user.email,
        photo: '',
        avatar: result.user.photoURL,
        preferences: [],
        hiddenMeetings: [],
        color: getRandomColor(),
        googleId: ''
      }

      // log authentication method as email and password //
      commit('storeUserAuthenticationMethod', 'Email-and-Password');
      commit('storeUser', newUser);

      // determine if user already exists in the database //
      let userData;
      await getExistingUser(uid).then(async (data) => {
        userData = data;
      });

      // user already exists in database so return //
      if (userData.length > 0) return;

      // this is a new user so create them in the database //
      if (userData.length == 0) {
        createNewUserFromPassword(newUser).then(userReponse => {
          console.log(userReponse)
        });
      }
    })
    .catch((error) => {
      console.log(error)
    }).then(() => {
      router.push("/");
    });
  },
  // signup function for email and password //
  passwordSignUp: ({ commit, dispatch }, payload) => {
    console.log('doing passwordSignUp');
    const email = payload.email;
    const password = payload.password;

    // firebase authentication for creating a new user with email and password //
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(async (result) => {
      const uid = result.user.uid;
      const avatar = getRandomAvatar().url;
      const color = getRandomColor();
      const newUser = {
        uid: uid,
        firstName: '',
        lastName: '',
        displayName: result.user.displayName,
        email: result.user.email,
        photo: result.user.photoURL,
        avatar: avatar,
        color: color,
        googleId: ''
      }

      // log authentication method as email and password //
      commit('storeUserAuthenticationMethod', 'Email-and-Password');
      commit('storeUser', newUser);

      // check if user already exists in the database //
      let userData;
      await getExistingUser(uid).then(async (data) => {
        userData = data;
      });
      
      // **** UPDATE: need to return error to front end if user already exists ***** //
      if (userData.length > 0) return commit('storeUser', userData);
      if (userData.length == 0) {
        createNewUserFromPassword(newUser).then(userReponse => {
          return getExistingUser(uid).then(async (data) => {
            commit('storeUser', data);
          });
        });
      }
    })
    .catch((error) => {
      console.log(error)
      // potential error value: {code: auth/email-already-in-use}
      // potential error value: {code: auth/weak-password}
    }).then(() => {
      router.push("/");
    });
  },
  // logout function for logging out google auth user //
  logout: async ({commit}) => {
    console.log('logout');

    //TODO
    //clear sensitive localStorage
   
    return new Promise((resolve, reject) => {
      commit('authLogout');

      //TODO
      //do we need to logout of GIS here?

      resolve();
    })
  },
  /*refreshToken: async ({commit, dispatch}, gapi) => {
    console.log('refreshing');
    //get current user

    //if there is no current user, return false (or something similar)

    //await? 

    gapi.refreshToken().then(response => {


      //this is where we would refresh tokens in user then return something
      console.log('refresh token response');
      console.log(response);

      return response;

    });

  }*/
}

// authentication mutations //
const mutations = {
  // logout of authentication module //
  authLogout: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  // store user object in storage //
  storeUser: (state, user) => {
    state.user = user
  },
  // record user's authentication method on login //
  storeUserAuthenticationMethod: (state, method) => {
    state.authenticationMethod = method
  }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}