export const taskNode = `<div class="relative inner-task-wrapper">
  <div class="items-center px-2 flex flex-wrap w-full shadow border-2 border-opacity-5 border-gray-300 border-solid" contenteditable="false">
    <div class="relative w-full flex  px-2 cursor-pointer">
      <div class="flex w-9/12 items-center justify-center px-3 text-sm font-normal text-primarytwo">
        <div class="flex h-4 align-middle mr-2">
          <input class="checkmark rounded bg-gray-200 border-transparent focus:border-none focus:ring-0 focus:ring-none text-gray-700 focus:ring-0 focus:ring-offset-0 focus:ring-white" type="checkbox" name="taskComplete" />
        </div>
             
          <input id="taskTitleInput" rows="1" class="task-title task-test overflow-y-hidden resize-none outline-none focus:outline-none ring-transparent focus:ring-transparent w-full text-dark border-transparent focus:border-transparent" placeholder="" />
          
        </div>
      <div class="flex items-center justify-center  px-3 ml-auto text-sm font-normal text-primarytwo">
        <div class="flex">
          <div class="link-button">
            <a class="hidden mt-1 block">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="18px" height="18px"><path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/></svg>
            </a>
          </div>
          <div id="syncButton" class="sync-element relative">
            <svg class="w-6 mx-2 my-auto text-dark hover:text-primaryone fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M 24 7 C 16.51 7 10.330078 12.71 9.5800781 20 L 9 20 C 4.04 20 0 24.04 0 29 C 0 33.96 4.04 38 9 38 L 11.830078 38 C 11.620078 37.41 11.520078 36.759844 11.580078 36.089844 L 11.679688 35 L 9 35 C 5.69 35 3 32.31 3 29 C 3 25.69 5.69 23 9 23 L 11 23 C 11.83 23 12.5 22.33 12.5 21.5 C 12.5 15.16 17.66 10 24 10 C 30.34 10 35.5 15.16 35.5 21.5 C 35.5 22.33 36.17 23 37 23 L 39 23 C 42.31 23 45 25.69 45 29 C 45 32.31 42.31 35 39 35 L 34.380859 35 C 33.730859 36.14 32.889922 37.16 31.919922 38 L 39 38 C 43.96 38 48 33.96 48 29 C 48 24.04 43.96 20 39 20 L 38.419922 20 C 37.669922 12.71 31.49 7 24 7 z M 31.896484 19.998047 A 1.50015 1.50015 0 0 0 30.441406 21.388672 L 30.333984 22.628906 C 28.703567 21.009294 26.468337 20 24 20 C 20.095796 20 16.751191 22.500105 15.513672 26 A 1.50015 1.50015 0 1 0 18.341797 27 C 19.164278 24.673895 21.374204 23 24 23 C 25.6356 23 27.105732 23.653477 28.183594 24.705078 L 26.650391 24.570312 A 1.50015 1.50015 0 1 0 26.388672 27.558594 L 31.369141 27.994141 A 1.50015 1.50015 0 0 0 32.994141 26.630859 L 33.429688 21.650391 A 1.50015 1.50015 0 0 0 31.896484 19.998047 z M 31.117188 29.980469 A 1.50015 1.50015 0 0 0 29.658203 31 C 28.835722 33.326105 26.625796 35 24 35 C 22.3644 35 20.894268 34.346523 19.816406 33.294922 L 21.349609 33.429688 A 1.50015 1.50015 0 1 0 21.611328 30.441406 L 16.630859 30.005859 A 1.50015 1.50015 0 0 0 16.480469 30 A 1.50015 1.50015 0 0 0 15.005859 31.369141 L 14.570312 36.349609 A 1.50015 1.50015 0 1 0 17.558594 36.611328 L 17.666016 35.371094 C 19.296433 36.990706 21.531663 38 24 38 C 27.904204 38 31.248809 35.499895 32.486328 32 A 1.50015 1.50015 0 0 0 31.117188 29.980469 z"></path></svg>  
            <svg id="taskIsSyncedIcon" class="taskIsSyncedIcon absolute hidden top-0 right-1" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Rich-elements-/-task:-sync-with-dropdown" transform="translate(-910.000000, -238.000000)" fill-rule="nonzero">
                  <g id="Group" transform="translate(268.000000, 72.000000)">
                    <g id="task" transform="translate(0.000000, 168.000000)">
                      <g id="task-hover-actions" transform="translate(605.000000, 0.000000)">
                        <g id="Group-2" transform="translate(39.000000, 0.000000)">
                          <circle id="Oval" stroke="#FFFFFF" stroke-width="2" fill="#458DFF" cx="6" cy="6" r="7"></circle>
                          <polygon id="Combined-Shape" fill="#FFFFFF" transform="translate(6.000000, 5.400000) rotate(45.000000) translate(-6.000000, -5.400000) " points="6.8 6.9 6.8 2.4 8.4 2.4 8.4 8.4 3.6 8.4 3.6 6.9"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="delete-task flex relative items-center">
            <svg class="w-4 h-4 mx-2 my-auto text-dark hover:text-primaryone fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg>  
          </div>
        </div>
      </div>
    </div>
      <div class="sync-menu hidden w-full mt-1 ml-auto" contenteditable="false">
        <div class="px-4 py-2 bg-white border-greythree">
          <div class="w-full">
            <div id="syncMenu" class="flex items-center justify-between">
              <div class="w-full">
                <div class="flex w-full justify-between cursor-pointer hover:text-primaryone rounded-full">
                  <div @click="syncWithTrello()" class="flex items-center">
                    <svg class="flex w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="#1E88E5" d="M6,10c0-2.2,1.8-4,4-4h28c2.2,0,4,1.8,4,4v28c0,2.2-1.8,4-4,4H10c-2.2,0-4-1.8-4-4V10z"></path><path fill="#FFF" d="M10,12.2c0-1.2,1-2.2,2.2-2.2h7.6c1.2,0,2.2,1,2.2,2.2v21.6c0,1.2-1,2.2-2.2,2.2h-7.6C11,36,10,35,10,33.8V12.2z M26,22.8c0,1.2,1,2.2,2.2,2.2h7.6c1.2,0,2.2-1,2.2-2.2V12.2c0-1.2-1-2.2-2.2-2.2h-7.6C27,10,26,11,26,12.2V22.8z"></path></svg>
                    <div class="flex items-center h-7 text-xs">Sync with Trello</div>
                  </div>
                  <div class="flex items-center align-middle flex-wrap">
                    <select id="trelloBoardsSelect" class="trelloBoardsSelect py-1 mx-2 mb-1 w-44 text-xs text-dark border-none bg-greythree rounded-full placeholder-gray-100">
                    </select>
                    <select id="trelloListsSelect" class="trelloListsSelect py-1 mx-2 mb-1 w-44 text-xs text-dark border-none bg-greythree rounded-full">
                    </select>
                    <button id="trelloSyncButton" class="trelloSyncButton flex py-1 mb-1 mx-2 px-6 rounded-full bg-primaryfive text-white text-xs hover:underline">
                      Sync
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    

  </div>
</div>`;


export const simpleTaskNode = `<div class="relative inner-task-wrapper">
  <div class="items-center bg-white m-1 p-4 flex flex-wrap w-full shadow border-2 border-opacity-5 border-gray-300 border-solid" contenteditable="false">
    <div class="relative w-full flex  px-2 cursor-pointer">
      <div class="flex w-full text-sm font-normal text-primarytwo">
        <div class="flex w-full justify-between h-4 align-middle mr-2">

          <div class="flex w-full">
            <input class="checkmark mr-2 mt-0.5 rounded bg-gray-200 border-transparent focus:border-none focus:ring-0 focus:ring-none text-gray-700 focus:ring-0 focus:ring-offset-0 focus:ring-white" type="checkbox" name="taskComplete" />
            <div class="simple-task-title truncate cursor-default text-gray-700" style="min-height: 1.5rem;"></div>
          

            <div class="link-button flex flex-row	">
              <div class="sync-icon">
                <p class="taskIsSyncedIcon hidden font-gtamerica font-normal mx-6 px-2 text-xs rounded-full cursor-default text-primaryone bg-primaryonelight margin-x-10 uppercase text-gray-500" style="margin: 0 0.3rem !important;">SYNCED</p>            
              </div>
              <a class="hidden ml-1 right-1 block link-to-trello" target="_blank">
                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="18px" height="18px"><path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/></svg>
              </a>
            </div>

          </div>

          </div>          
        </div>        
      </div>
  </div>
</div>`;