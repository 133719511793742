const fetch = require('node-fetch');
import { store } from '../../store/index.js';
import { PROTOCOL, FULL_DOMAIN, DOMAIN, PROCESS_PORT } from './ServerUtilities';
export const initializeTrelloIntegration = async () => {
  const user = store.getters["authentication/getUser"];
  if (user) {
    if (!user.integrations.trello) return;
    const trelloPayload = { 
      token: user.integrations.trello.token
    }
    await store.dispatch('integrations/initializeTrelloMemberData', trelloPayload).then((res) => {
      console.log("Trello Data: ", res);
      return res;
    }).catch((error) => {
      console.log(error);
    });
  }
}

export const getMemberDetails = async (token) => {
	const res = await fetch(`${PROTOCOL}${API_PORT}/api/trello/getMemberDetails?token=${token}`, {
    method: 'GET',
    headers: {
      'Accept': '*'
    }
  }).then(res => {
    return res.json();
  }).catch((error) => {
    console.log(error);
    return null;
  });
  return res;
}

export const getBoardDetails = async (token, boardId) => {
	const res = await fetch(`${PROTOCOL}${API_PORT}/api/trello/getBoardDetails?token=${token}&boardId=${boardId}`, {
    method: 'GET',
    headers: {
      'Accept': '*'
    }
  }).then(res => {
    return res.json();
  })

  return res;
}

export const getBoardLists = async (token, boardId) => {
	const res = await fetch(`${PROTOCOL}${API_PORT}/api/trello/getBoardLists?token=${token}&boardId=${boardId}`, {
    method: 'GET',
    headers: {
      'Accept': '*'
    }
  }).then(res => {
    return res.json();
  })

  return res;
}