import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { store } from '../../store/index';
import { WEBSOCKET_DOMAIN, WEBSOCKET_PROTOCOL, PROTOCOL, FULL_DOMAIN, DOMAIN, API_DOMAIN, PROCESS_PORT, ALGOLIA_APP_ID, ALGOLIA_ADMIN_API_KEY } from './ServerUtilities';
import algoliasearch from 'algoliasearch';
import { get } from './AxiosUtilities';

// import libraries //
import _Quill from 'quill';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';

const Quill = window.Quill || _Quill;

const getAuthenticatedUser = async () => {
  return await store.getters['authentication/getUser'];
};

const recurringMeetingConfigProps = ['public'];


// retrieve all calendars that the authenticated user has access to //
export const getCalendars = function(token) {
	let url = 'https://www.googleapis.com/calendar/v3/users/me/calendarList?maxResults=9999&q=';
  return get(url, {}, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// retrieve all calendar events from a specific calendar //
export const getCalendarEvents = function(token, calendarId, pageToken, minDate = null, maxDate = null) {
    
  console.log('getCalendarEvents',token, calendarId);
  // calculate the date for when to start pulling events //
  let dateNow = new Date().toISOString();
  function subtractDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  //TODO
  //if minDate and maxDate, we will set manually. Or, just grab the default min max below

  let minEventsDate = minDate ? minDate.toISOString() : subtractDays(dateNow, 14).toISOString();
  let maxEventsDate = maxDate ? maxDate.toISOString() : addDays(dateNow, 14).toISOString();

  
  
  let maxResultsString = 'maxResults=9999&singleEvents=true&'; //testing. This changes the results dramatically when there are many, many events
  let minMaxTimeString = 'timeMin=' + minEventsDate + '&timeMax=' + maxEventsDate;
  
	if (pageToken) {
		var url = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events/?'+maxResultsString+'pageToken=' + pageToken + minMaxTimeString;
	} else {
		var url = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events/?'+maxResultsString + minMaxTimeString;
	}
  console.log(url);
	return fetch(url, { 
		method: 'GET', 
		headers: new Headers({
			'Authorization': 'Bearer ' + token, 
			'Content-Type': 'application/x-www-form-urlencoded'
		}), 
	})
	.then(res => res.text());
};

// retrieve single calendar event from a specific calendar //
export const getSingleCalendarEvent = function(token, calendarId, eventId) {     

	let url = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events/' + eventId;
	
	return fetch(url, { 
		method: 'GET', 
		headers: new Headers({
			'Authorization': 'Bearer ' + token, 
			'Content-Type': 'application/x-www-form-urlencoded'
		}), 
	})
	.then(res => res.text());
};



// get all events from the meetings collection in the database for a particular user uid //
export const getMeetings = async (uid, primaryCalendar, minDate = null, maxDate = null) => {

  console.log('getMeetings',uid, primaryCalendar);
  
  let attendee = [];
  let importer = [];
  let attendeeSearch = {'email':primaryCalendar};  
  let isAttendee = null;
  let isImporter = null; 

  if (minDate && maxDate) {
    //TODO
    //query with new startTimestamp variable
    isAttendee = await firebase.firestore().collection('meetings').where('attendees', 'array-contains', attendeeSearch).where('startTimestamp', '>=', minDate).where('startTimestamp', '<=', maxDate).get();
    isImporter = await firebase.firestore().collection('meetings').where('importer', '==', uid).where('startTimestamp', '>=', minDate).where('startTimestamp', '<=', maxDate).get();
  } else {
    isAttendee = await firebase.firestore().collection('meetings').where('attendees', 'array-contains', attendeeSearch).get();
    isImporter = await firebase.firestore().collection('meetings').where('importer', '==', uid).get();
  }
 
  if (isAttendee.docs) {
    attendee = isAttendee.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));
  }

  if (isImporter.docs) {
    importer = isImporter.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));
  }

  //console.log(attendee);
  //console.log(importer);

  const result = attendee.concat(importer);
  return result;
}

//TODO
//remove this, this is for admins
export const getALLMeetings = async (options = {}) => {
  
  let allMeetings = [];

  let queryMeeting = firebase.firestore().collection('meetings');

  if (options.recurringEventId) {
    queryMeeting = queryMeeting.where("recurringEventId", "==", options.recurringEventId);
  }
  
  let allMDocs = await queryMeeting.get();

  if (allMDocs.docs) {
    allMeetings = allMDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));
  }

  return allMeetings;
}

//this is for searching meetings in Firestore for the historical meetings search
export const searchMeetings = async (uid, primaryCalendar, searchTerm) => {
  let attendee = [];
  let importer = [];
  let attendeeSearch = {'email':primaryCalendar};  

  //query algolia for fulltext search
  const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_API_KEY);
  const index = client.initIndex('meetings');


  let indexresults = [];
  const hits = await index.search(searchTerm,{
    hitsPerPage: 500
  });
  console.log('hits');
  console.log(hits);
  indexresults = hits.hits.map(a => a.objectID);
  //console.log(indexresults);

  //firestore only gets by docId 10 at a time
  var indexarrays = [], size = 10;    
  while (indexresults.length > 0) {
    indexarrays.push(indexresults.splice(0, size));
  }

  //console.log(indexarrays);

  //lets get our docs
  let result = [];

  for (let i = 0; i < indexarrays.length; i++) {

    attendee = [];
    importer = [];  

    //console.log(attendeeSearch);

    const isAttendee = await firebase.firestore().collection('meetings')
      .where('attendees', 'array-contains', attendeeSearch)
      .where(firebase.firestore.FieldPath.documentId(),'in',indexarrays[i])
      .get();

    const isImporter = await firebase.firestore().collection('meetings')    
      .where('importer', '==', uid)
      .where(firebase.firestore.FieldPath.documentId(),'in',indexarrays[i])
      .get();

    if (isAttendee.docs) {
      attendee = isAttendee.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
    }

    if (isImporter.docs) {
      importer = isImporter.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
    }

    //console.log('result '+i);console.log(attendee,importer);

    result = result.concat(attendee,importer);

  }

  console.log('result');
  console.log(result);

  return result;
}


// get all events from the meetings collection in the database for a particular user uid //
export const getImportedMeetings = async (uid) => {
  const meetingsQuery = await firebase.firestore().collection('meetings').where('uid', '==', uid).get();
  const meetings = meetingsQuery.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }))
  return meetings;
}

export const getPriorMeeting = async (meeting) => {
  if (!(meeting && meeting.recurringEventId)) {
    return null;
  }

  let allMeetings = await getALLMeetings({
    recurringEventId: meeting.recurringEventId
  });

  if (!(allMeetings && allMeetings.length)) {
    return null;
  }

  allMeetings = allMeetings.sort((a, b) => {
    const aDate = a.start.dateTime;
    const bDate = b.start.dateTime;

    if (aDate < bDate) {
      return -1;
    }

    if (aDate < bDate) {
      return 1;
    }

    return 0;
  });

  const meetingIndex = allMeetings.findIndex(m => {
    if (!m) {
      return false;
    }

    if (meeting.id && m.id == meeting.id) {
      return true;
    }

    if (meeting.sourceId && m.sourceId == meeting.sourceId) {
      return true;
    }

    if (meeting.documentId && m.documentId == meeting.documentId) {
      return true;
    }

    return false;
  });

  if (meetingIndex >= 0) {
    return allMeetings[meetingIndex - 1];
  }

  return allMeetings.slice(-1);
}

// Get Meeting Data //
export const loadMeetingData = async (id) => {
  const meetingData = await firebase.firestore().collection('meetings').doc(id).get().then((doc) => {
    return doc.data();
  });
  return meetingData;
}

export const getTasks = async meeting => {
  const editorContent = await getEditorContent(meeting);

  if (!(editorContent && editorContent.content && editorContent.content.ops && editorContent.content.ops.length)) {
    return null;
  }

  return editorContent.content.ops.filter(o => o && o.insert && o.insert.taskBlot);
}

const createQuill = async meeting => {
  if (!(meeting && meeting.documentId)) {
    return null;
  }

  const promise = new Promise((resolve, reject) => {
    const quillElement = document.createElement('div');

    // const targetDiv = document.querySelector('body');
    // targetDiv.appendChild(quillElement);

    const quill = new Quill(quillElement, {});

    // websocket config //
    const ydoc = new Y.Doc();
    const ytext = ydoc.getText(`quill`);

    try {
      const provider = new WebsocketProvider(
        `${WEBSOCKET_PROTOCOL}://${WEBSOCKET_DOMAIN}`, meeting.documentId, ydoc
      );

      provider.on("error", function (error) {
        reject(error);
      });

      const binding = new QuillBinding(ytext, quill, provider.awareness);
      // const awareness = provider.awareness;
      // const user = store.getters['authentication/getUser'];
      // const myColor = user.color;

      // awareness.setLocalStateField('user', {
      //   name: this.userDetails.displayName, color: myColor
      // });

      return resolve({ quill, quillElement});

    } catch (error) {
      return reject(error);
    }

  });

  const quill = await promise;
  return quill;
}

export const getEditorContent = (meeting) => {
  if (!(meeting && meeting.documentId)) {
    return null;
  }

  return new Promise((resolve, reject) => {
    let resolved = false;
    let quill = null;

    return createQuill(meeting)
      .then(quillResponse => {


        let { quillElement } = quillResponse;
        quill = quillResponse.quill;
        
        if (!quill) {
          return reject(quill);
        }

        const textChangeHandler = () => {
          try {

            resolved = true;

            console.log('text-change-event');

            let text = quill.getText();
            let content = quill.getContents();

            resolve({ text, content });

            quill.off('text-change', textChangeHandler);
            
            quill.disable();
            // if (quillElement.parentNode) {
            //   quillElement.parentNode.removeChild(quillElement);
            // }
            
            quill = null;
            quillElement = null;

            return;
          } catch (error) {
            console.log('erro no text-change', error);
            return reject(error);
          }
        };

        quill.on('text-change', textChangeHandler);

        let tentativas = 0;
        let interval = setInterval(() => {
          console.log('start-quill-interval-event');

          if (tentativas >= 3) {
            clearInterval(interval);
            return;
          }

          tentativas++;

          if (resolved) {
            clearInterval(interval);
            return;
          }

          try {

            if (!quill) {
              clearInterval(interval);
              return null;
            }

            let text = quill.getText();
            let content = quill.getContents();
            
            resolve({ text, content });

            clearInterval(interval);

          } catch (error) {
            reject(error);
          }
        }, 500);

      })
      .then(res => {
        return res;
      })
      .catch(reject);
  });
}

const loadPriorContent = async documentId => {

  console.log('loadPriorContent start');

  if (!documentId) {
    return;
  }

  const currentMeeting = await loadMeetingData(documentId);
  if (!currentMeeting) {
    return;
  }

  const previous = await getPriorMeeting(currentMeeting);
  if (!previous) {
    return;
  }

  const tasks = await getTasks(previous);

  console.log('check prior content results',{currentMeeting:currentMeeting}, {previous:previous}, {tasks:tasks})

  //if (!(tasks && tasks.length)) {
  //  return;
  //}
  const dateTime = (new Date(previous.start.dateTime)).toLocaleString();

  const previousMeetingUrl = `${PROTOCOL}${FULL_DOMAIN}/editor/${previous.id}/${previous.documentId}`;

  /*
  let contentDelta = [
    {
      attributes: { size: 'huge', bold: true },
      insert: `Previous Meeting`
    },
    '\n',
    '\n',
    {
      attributes: { size: 'large', bold: true, link: previousMeetingUrl},
      insert: previous.name
    },
    '\n',
    {
      attributes: { size: 'normal', bold: true },
      insert: `${dateTime}`
    },
    '\n',
    '\n'
  ];
  

  //add tasks to contentDelta
  if (tasks && tasks.length) {
    for (const task of tasks) {
      contentDelta.push(task);
    }
    contentDelta.push('\n');
    contentDelta.push('\n');
  }
  */

  let contentDelta = [
    {
      attributes: {  },
      insert: {
        recurringMeetingBlockBlot: {
          title: previous.name,
          link: previousMeetingUrl,
          dateTime: dateTime,
          tasks: JSON.stringify(tasks)
        }
      }
    }
  ];

  await addContent(currentMeeting, contentDelta);

}

const addContent = async (meeting, dataToAdd) => {

  if (!(dataToAdd && dataToAdd.length)) {
    return;
  }

  console.log('dataToAdd', dataToAdd);

  dataToAdd = dataToAdd.map(dta => {
    if (!dta) {
      return null;
    }

    if (dta && dta.insert) {
      return dta;
    }

    return {
      insert: dta
    }
  }).filter(dta => dta);

  let quillResponse = await createQuill(meeting);
  if (!quillResponse) {
    return;
  }

  let { quill, quillElement } = quillResponse;

  try {
    let Delta = Quill.import('delta');
    let delta = quill.getContents();
    delta = delta.ops;     
    delta = [...dataToAdd, ...delta];
    let finalDelta = new Delta(delta);

    console.log('finalDelta',finalDelta);

    quill.setContents(finalDelta);

    quill.disable();
    // if (quillElement.parentNode) {
    //   quillElement.parentNode.removeChild(quillElement);
    // }

    quill = null;
    quillElement = null;

    console.log('added.');

  } catch (error) {
    console.log('erro on add content: ', error);
  }
}

// import calendar event into the database //
export const importMeeting = async (meetingData, user) => {

  //TODO
  //needs user validation/permission
  
  let importedMeetingDocs = [];
  
  let importedMeetingQuery = await firebase.firestore().collection('meetings').where('sourceId', '==', meetingData.id).get();
  importedMeetingDocs = await importedMeetingQuery.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));

  if (importedMeetingDocs.length > 0) {
    console.error('This meeting has already been imported');
    return importedMeetingDocs[0].id;
  }

  let newDocumentId;
  let updatedAttendeesArray = [];
  let primaryCalendar = store.getters['calendars/getPrimaryCalendar'];
  if (!meetingData.attendees) { 
    updatedAttendeesArray = [];
  } else {
    for (let i = 0; i < meetingData.attendees.length; i++) {
      let newAttendee = {
        email: meetingData.attendees[i]['email']
      }
      updatedAttendeesArray = updatedAttendeesArray.concat(newAttendee);
    }
  }  

  let importObject = {
    name: meetingData.summary,
    importer: user.uid,
    creator: meetingData.creator,
    organizer: meetingData.organizer,
    attendees: updatedAttendeesArray,
    start: meetingData.start,
    end: meetingData.end,
    created: meetingData.created,
    updated: meetingData.updated,
    body: '',
    sourceId: meetingData.id,
    type: meetingData.eventType,
    imported: true,
    public: true //default public
  };

  if (meetingData.recurringEventId) {
    importObject.recurringEventId = meetingData.recurringEventId;
    let recurringMeetingConfig = await getRecurringMeetingConfigByRecurringEventId(meetingData.recurringEventId);    
    if (recurringMeetingConfig) importObject.public = !!recurringMeetingConfig.public;
  }

  //TODO
  //when we introduce teams, we will have a team config for public/private default

  //save start time as a timestamp for querying
  if (meetingData.start && meetingData.start.dateTime) {
    importObject.startTimestamp = new Date(meetingData.start.dateTime);
  }

  const res = await firebase.firestore().collection('meetings').add(importObject).then(function (docRef) {
    return addDocumentIdToMeeting(docRef.id).then(() => {
      newDocumentId = docRef.id;
      return docRef.id      
    });
  }).then(docId => {
    updateCalendarEventDescription(primaryCalendar, meetingData, docId);
    return docId;
  }).then(() => {
    return loadPriorContent(newDocumentId);
  }).then(() => {
    return newDocumentId;
  })
    .catch(error => {
      console.error(error);
    return error;
  })
  return res;
}

export const upsertRecurringMeetingConfig = async (recurringEvent,key,value) => {
  
  const recurringMeetingConfig = await firebase.firestore().collection('recurringMeetingConfig').where('recurringEventId', '==', recurringEvent.recurringEventId).get();

  let configData = {};
  configData[key] = value;

  if (!recurringMeetingConfig.empty) {
    let res = await firebase.firestore().collection('recurringMeetingConfig').doc(recurringMeetingConfig.docs[0].id).update(configData).then((docRef) => {    
      return docRef.id;    
    })
    .catch((error) => {    
      return false;
    });
  } else {
    configData = { ...configData, ...recurringEvent };
    return firebase.firestore().collection('recurringMeetingConfig').add(configData).then((docRef) => {    
      return docRef.id;    
    })
    .catch((error) => {    
      return false;
    });
  }

}

export const deleteRecurringMeetingConfig = async (documentId, user) => {
  console.log(documentId, user);
  
  await firebase.firestore().collection('recurringMeetingConfig').doc(documentId).delete().then((response) => {   
         
  })
  .catch((error) => {    
    return false;
  });

  return documentId;    
}

export const getRecurringMeetingConfigs = async (user) => {
  
  if (!user.uid && !user.primaryCalendar) return false;

  const userMeetings = await getMeetings(user.uid, user.primaryCalendar);

  console.log('userMeetings', userMeetings);
  
  if (!userMeetings.empty) {
    const recurringEventIds = userMeetings
      .map(meeting => meeting.recurringEventId)
      .filter((id, index, array) => id && array.indexOf(id) === index);

    console.log('recurringEventIds', recurringEventIds);
  
    // Splitting recurringEventIds into chunks of 10 or less
    const chunkedIds = [];
    for (let i = 0; i < recurringEventIds.length; i += 10) {
      chunkedIds.push(recurringEventIds.slice(i, i + 10));
    }

    // Fetching documents from Firestore for each chunk
    const promises = chunkedIds.map(async idsChunk => {
      const recurringMeetingConfigQuery = await firebase.firestore().collection('recurringMeetingConfig')
        .where('recurringEventId', 'in', idsChunk)
        .get();

      // Extracting data from querySnapshot
      const recurringMeetingConfigs = [];
      recurringMeetingConfigQuery.forEach(doc => {
        const data = doc.data();
        data.id = doc.id;
        recurringMeetingConfigs.push(data);
      });

      return recurringMeetingConfigs;
    });

    // Combining results from all queries
    const results = await Promise.all(promises);
    const recurringMeetingConfigs = results.flat();

    return recurringMeetingConfigs;
  }

  return [];
}




export const getRecurringMeetingConfigByRecurringEventId = async (recurringEventId) => {
  const recurringMeetingConfig = await firebase.firestore().collection('recurringMeetingConfig').where('recurringEventId', '==', recurringEventId).get();
  
  if (!recurringMeetingConfig.empty) {    
    return recurringMeetingConfig.docs[0].data();
  } else {    
    return false;
  }
}

// create instant meeting in the database //
export const createInstantMeeting = async (meetingData, user) => {

  //TODO
  //needs user validation/permission

  let newDocumentId;
  let createResponse;
  let primaryCalendar = store.getters['meetings/getPrimaryCalendar'];
  if (!meetingData.attendees) meetingData.attendees = '';
  let newMeetingData = {
    id: uuidv4(),
    name: meetingData.name,
    uid: meetingData.uid,
    attendees: [ { email: primaryCalendar }, { email: "notes@mcgaw.io" } ],
    creator: meetingData.creator,
    organizer: meetingData.organizer,
    start: meetingData.start,
    end: meetingData.end,
    sourceId: meetingData.id,
    imported: true
  }

  //save start time as a timestamp for querying
  if (meetingData.start && meetingData.start.dateTime) {
    importObject.startTimestamp = new Date(meetingData.start.dateTime);
  }

	const res = await firebase.firestore().collection('meetings').add(newMeetingData).then(function(docRef) {
    addDocumentIdToMeeting(docRef.id);
    newDocumentId = docRef.id;
    return docRef.id
  })
  .then(() => {
    let createEvent = createCalendarEvent(primaryCalendar, newMeetingData, newDocumentId);
    return createEvent
  })
  .then((res) => {
    createResponse = res;
    return createResponse;
  })
  .catch(error => {
    return error;
  })
  return {createResponse, newDocumentId};
}

// update calendar event document with its own document id //
export const addDocumentIdToMeeting = async(documentId) => {

  //TODO
  //needs user validation/permission

  const res = await firebase.firestore().collection('meetings').doc(documentId).update({
    documentId
  });
}

export const updateMeetingSummarySent = async(documentId) => {

  //TODO
  //needs user validation/permission

  //flag that the meeting summary was sent
  const res = await firebase.firestore().collection('meetings').doc(documentId).update({summarySent:true});
}

export const updateMeetingAttendees = async(documentId,attendees) => {

  //TODO
  //needs user validation

  if (!attendees) { 
    return null;
  } else {
    let updatedAttendeesArray = [];
    for (let i = 0; i < attendees.length; i++) {
      let newAttendee = {
        email: attendees[i]['email']
      }
      updatedAttendeesArray = updatedAttendeesArray.concat(newAttendee);
    }

    const res = await firebase.firestore().collection('meetings').doc(documentId).update({
      attendees: updatedAttendeesArray
    });
    return res;
  }
}

export const updateMeetingProperty = async(documentId,key,value,setConfig = false) => {
  //update provided property

  //TODO
  //needs user validation

  var obj = {};
  obj[key] = value;
  const res = await firebase.firestore().collection('meetings').doc(documentId).update(obj);

  //TODO error handling below

  //if this is a recurringMeetingConfig prop, we should upsert it
  //TODO this lookup must be changed when we include Teams support

  if (recurringMeetingConfigProps.includes(key) && setConfig) {
   const recurringEvent = await getMeetingRecurringEvent(documentId);
   if (recurringEvent) {
    //upsert config
    await upsertRecurringMeetingConfig(recurringEvent,key,value);
   }
  }

  return res;
}

export const updateRecurringMeetingConfigProperty = async(documentId,key,value) => {
  //update provided property
  var obj = {};
  obj[key] = value;
  const res = await firebase.firestore().collection('recurringMeetingConfig').doc(documentId).update(obj);

  return res;
}

export const getMeetingRecurringEvent = async(documentId) => {
  const meetingData = await firebase.firestore().collection('meetings').doc(documentId).get().then((doc) => {
      return doc.data();
    });
    return {
      recurringEventId: meetingData.recurringEventId,
      recurringEventName: meetingData.name,
      recurringEventCreated: meetingData.created,
      recurringEventCreator: meetingData.creator,
      recurringEventOrganizer: meetingData.organizer,
    };
}

// save meeting notes in the database //
export const saveMeetingNotes = async (id, meetingNotes) => {
	const res = await firebase.firestore().collection('meetings').doc(id).update({
    body: meetingNotes
  });
	return res;
}

// load meeting notes from the database //
export const loadMeetingNotes = async (id) => {
  const meetingNotes = await firebase.firestore().collection('meetings').doc(id).get().then((doc) => {
    return doc.data().body;
  });
  return meetingNotes;
}

// Get Meeting Templates //

export const getMeetingTemplate = async (documentId) => {

  const user = await getAuthenticatedUser();

  if (!user) {
    return undefined;
  } 

  try {
    const templateDoc = await firebase.firestore().collection('templates').doc(documentId).get();
    // Check if the document has a property 'uid' and it matches user.uid
    if (templateDoc.exists && templateDoc.data().uid === user.uid) {
      return templateDoc.data();
    } else {
      // Handle the case where the document doesn't have the correct 'uid'
      console.error('Template not found or invalid user:', templateDoc);
      throw new Error('Template not found or invalid user');
    }
  } catch (error) {
    console.error('Error fetching template:', error);
    throw error;
  }
};

export const getMeetingTemplates = async uid => {
  const templatesQuery = await firebase.firestore().collection('templates').where('public', '==', true).get();
  const templates = templatesQuery.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }))
  return templates;
}

export const getPersonalMeetingTemplates = async uid => {
  const templatesQuery = await firebase.firestore().collection('templates').where('uid', '==', uid).get();
  const templates = templatesQuery.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }))
  return templates;
}

export const saveMeetingTemplate = (template, user) => {
  console.log(template, user);

  let userData = user;

  //trim user data
  delete userData.hiddenMeetings;
  delete userData.hiddenTitleMeetings;
  //delete userData.integrations;
  delete userData.preferences;


	return firebase.firestore().collection('templates').add({
    name: template.name,
    uid: userData.uid,
    description: template.description,
    creator: userData,   
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    updated: firebase.firestore.Timestamp.fromDate(new Date()), 
    body: template.body,
    public: template.public,
    quillContent: template.quillContent   
  }).then((docRef) => {    
      return docRef.id;    
  })
  .catch((error) => {    
    return false;
  });
  
}

export const updateMeetingTemplateNameAndContent = async(documentId,templateName,templateContent) => {

  const user = await getAuthenticatedUser();

  if (!user) {
    return undefined;
  } 

  //TODO
  //ensure this template is owned by user

  //update provided property
  var obj = {
    'name': templateName,
    'quillContent': templateContent
  };
  
  const res = await firebase.firestore().collection('templates').doc(documentId).update(obj);
  return await firebase.firestore().collection('templates').doc(documentId).get();
}

export const updateMeetingTemplateProperty = async(documentId,key,value) => {

  const user = await getAuthenticatedUser();

  if (!user) {
    return undefined;
  } 

  //TODO
  //ensure this template is owned by user

  //update provided property
  var obj = {};
  obj[key] = value;
  const res = await firebase.firestore().collection('templates').doc(documentId).update(obj);
  return await firebase.firestore().collection('templates').doc(documentId).get();
}

export const deleteMeetingTemplate = async (documentId, user) => {
  console.log(documentId, user);

  //NOTE: User validation here on the delete
  //We can use this type of validation in the TODO's elswhere in this utility service

  try {
    const querySnapshot = await firebase.firestore()
      .collection('templates')
      .where('uid', '==', user.uid)
      .where(firebase.firestore.FieldPath.documentId(), '==', documentId)
      .get();

    if (querySnapshot.size === 1) {
      const documentRef = querySnapshot.docs[0].ref;
      await documentRef.delete();
      console.log("Document successfully deleted!");
      return documentId;
    } else {
      console.error("Document not found or multiple documents found.");
      return false;
    }
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};


export const hideMeeting = async (documentId, meetingId) => {

  //TODO
  //needs user validation/permission

  let userHiddenMeetings;
  let updatedUserHiddenMeetings;

  const hiddenMeeting = await firebase.firestore().collection('users').doc(documentId).get().then((doc) => {
    userHiddenMeetings = doc.data().hiddenMeetings;
    return userHiddenMeetings;
  });

  if (!userHiddenMeetings) {
    userHiddenMeetings = []
  }

  userHiddenMeetings.push(meetingId);
  
  const applyUpdateToRecord = await firebase.firestore().collection('users').doc(documentId).update({
    hiddenMeetings: userHiddenMeetings
  })

  return applyUpdateToRecord;
}

export const hideMeetingByTitle = async (documentId, meetingTitle) => {

  //TODO
  //needs user validation/permission

  let userHiddenTitleMeetings;
  let updatedUserHiddenTitleMeetings;

  const hiddenTitleMeeting = await firebase.firestore().collection('users').doc(documentId).get().then((doc) => {
    userHiddenTitleMeetings = doc.data().hiddenTitleMeetings;
    return userHiddenTitleMeetings;
  });

  if (!userHiddenTitleMeetings) {
    userHiddenTitleMeetings = []
  }

  userHiddenTitleMeetings.push(meetingTitle);
  
  const applyUpdateToRecord = await firebase.firestore().collection('users').doc(documentId).update({
    hiddenTitleMeetings: userHiddenTitleMeetings
  })

  return applyUpdateToRecord;
}

export const unhideMeeting = async (documentId, meetingId, meetingSummary, meetingGlobalVisibility) => {

  //TODO
  //needs user validation/permission

  let userHiddenMeetings;
  let userHiddenTitleMeetings;
  let updatedUserHiddenMeetings;

  const hiddenMeeting = await firebase.firestore().collection('users').doc(documentId).get().then((doc) => {
    userHiddenMeetings = doc.data().hiddenMeetings;
    userHiddenTitleMeetings = doc.data().hiddenTitleMeetings;
    return userHiddenMeetings;
  });
  
  userHiddenMeetings = userHiddenMeetings.filter(e => e !== meetingId);
  if (meetingGlobalVisibility) {
    userHiddenTitleMeetings = userHiddenTitleMeetings.filter(e => e !== meetingSummary);
  }
  
  const applyUpdateToRecord = await firebase.firestore().collection('users').doc(documentId).update({
    hiddenMeetings: userHiddenMeetings,
    hiddenTitleMeetings: userHiddenTitleMeetings
  })

  return applyUpdateToRecord;
}



// creates a new calendar event on a user's calendar //
export const createCalendarEvent = async (calendarId, meetingData, documentId) => {
  // get user from storage and pull access token //
  let user = store.getters['authentication/getUser'];
  let token = user.integrations.google.googleAccessToken;

  // assign variables for meeting data //
  let eventId = meetingData.id;

  // create url for the meeting notes to be added to the event description //
  let meetingNotesURL = `${PROTOCOL}${FULL_DOMAIN}/editor/${eventId}/${documentId}`;
  
  // event description //
  let descriptionAddition = `
--- *** View Meeting Notes in Holepunch *** ---
<a href="${meetingNotesURL}">View Meeting Notes</a>
`;

  let descriptionPayload = {
    description: descriptionAddition
  }

  // create new meeting data payload //
  let updatedMeetingData = {
    summary: meetingData.name,
    description: descriptionPayload.description,
    attendees: meetingData.attendees,
    start: {
      dateTime: meetingData.start
    },
    end: {
      dateTime: meetingData.end
    }
  }

  // make request to server so that the calendar update can be made server side //
  let response;
  const url = `${PROTOCOL}${API_DOMAIN}/api/googleCalendar/createEvent`;
  const postEventData = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      calendar: calendarId,
      meeting: eventId,
      meetingData: updatedMeetingData,
      accessToken: token,
      meetingNotesURL: meetingNotesURL
    })
  }).then((res) => {
    response = res;
    return response;
  });
  return response;
}

// update calendar event on a users calendar and adds the meeting notes url //
export const updateCalendarEventDescription = async (calendarId, meetingData, documentId) => {
  
  // get user from storage and pull access token //
  let user = store.getters['authentication/getUser'];
  let token = user.integrations.google.googleAccessToken;

  // assign variables for meeting data //
  let meetingStart = meetingData.start.dateTime;
  let meetingEnd = meetingData.end.dateTime;
  let eventId = meetingData.id;

  // create url for the meeting notes to be added to the event description //
  let meetingNotesURL = `${PROTOCOL}${FULL_DOMAIN}/editor/${eventId}/${documentId}`;

  // set existing description to what is currently there, otherwise set it to an empty string //
  // this prevents an error thrown for description being undefined //
  let existingDescription;
  if (meetingData.description) {
    existingDescription = meetingData.description;
  } else {
    existingDescription = '';
  }

  // content to be added to the existing description //
  let newDescription = '';
  if (existingDescription.includes('--- *** View Meeting Notes in Holepunch *** ---')) {
    //do nothing
    newDescription = existingDescription;
  } else {

  // content to be added to the existing description //
  let descriptionAddition = `
--- *** View Meeting Notes in Holepunch *** ---
<a href="${meetingNotesURL}">View Meeting Notes</a>
`;

  // append the new description to the end of the existing one //
  newDescription = existingDescription + descriptionAddition;
  }

  let descriptionPayload = {
    description: newDescription
  }

  // create new meeting data payload //
  let updatedMeetingData = Object.assign(meetingData, descriptionPayload);

  // make request to server so that the calendar update can be made server side //
  const url = `${PROTOCOL}${API_DOMAIN}/api/googleCalendar/updateEvent`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      calendar: calendarId,
      meeting: eventId,
      meetingData: updatedMeetingData,
      accessToken: token,
      meetingNotesURL: meetingNotesURL
    })
  }).then(res => {
    console.log(res)
    return res.json();
  });
  return res;

}