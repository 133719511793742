<template>
  <div class="w-screen h-screen absolute z-50 bg-black bg-opacity-10 backdrop-filter backdrop-blur-sm -mt-16 -ml-20">
    <div class="min-h-screen overflow-y-auto w-full flex items-center justify-center">
      <div class="share-modal w-full">
        <div class="flex flex-row w-full">
          <div class="px-4 py-5 bg-white rounded-tl-3xl w-full">
            <div class="text-4xl font-gteesti text-dark font-thin">
              Share Meeting Notes
            </div>
            <p class="text-md text-dark font-light">
              Email notes to meeting particpants or export as a file.
            </p>
          </div>
          <div class="px-4 py-2 relative bg-white rounded-tr-full" >
            <svg @click="closeSharingModal" class="-top-3 -right-3 w-10 h-10 p-2 absolute bg-white rounded-full cursor-pointer fill-current shadow-lg hover:shadow-md text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
      <div class="flex w-full bg-white border-t">
        <div class="flex flex-row w-full items-center justify-between rounded-sm">
          <div class="flex flex-row items-center w-full">
            <div class="w-full border-b">
              <nav class="flex flex-col w-full sm:flex-row">
                <button @click="this.sharingMenu = 'Email'" :class="sharingMenu == 'Email' ? 'text-primaryone hover:text-primaryone border-b-2 border-primaryone' : 'text-greyone hover:text-greytwo hover:border-greytwo'" class="py-2 mt-2 mx-3 block focus:outline-none font-medium text-sm border-b-2 border-white">
                  Send Email
                </button>
                <button @click="this.sharingMenu = 'Export'" :class="sharingMenu == 'Export' ? 'text-primaryone hover:text-primaryone border-b-2 border-primaryone' : 'text-greyone hover:text-greytwo hover:border-greytwo'" class="py-2 mt-2 mx-3 block focus:outline-none font-medium text-sm border-b-2 border-white">
                  Export Notes
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div v-show="sharingMenu == 'Email'">
        <div class="w-full bg-white flex relative"> 
         <div class="email-summary px-2 w-1/2 sm:w-full">        
            <div class="email-summary overflow-y-scroll">
              <div class="participants">
                <div class="participants-header w-full flex justify-between py-4">
                  <h4 class="text-md font-semibold text-dark">Email Recipients ( {{ selectedParticipants.length }} )</h4>                
                  <div class="participants-actions">
                    <button @click="removeAllRecipients()" class="px-4 py-1 mr-1 hover:underline text-xs rounded-sm font-normal bg-primaryfour text-white rounded-full">Clear All</button>
                    <button @click="toggleSelectRecipients()" class="px-4 py-1 mr-1 hover:underline text-xs rounded-sm font-normal bg-primaryone text-white rounded-full">Select Recipients</button>
                  </div>  
                </div>            
                <div class="participants-list">                
                  <div id="participants-list-list">
                    <div class="px-1 py-2 flex justify-between align-left" v-for="(participant, index) in this.selectedParticipants" :key="`participant-${index}`">
                      <span class="participant-email w-full font-light">{{ participant.email }}</span>
                      <span class="cursor-pointer bg-gray-300 rounded-sm h-5 w-5 hover:bg-gray-50" @click="removeSelectedParticipant(index)"><svg class="text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path></svg></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="email-summary-type my-8 font-light">
                <h4 class="text-sm uppercase text-gray-400">Summary Type</h4> 
                <div class="email-summary-type-inner">
                  <div>
                    <input type="radio" id="email-summary-full-summary" value="Full summary" v-model="summaryType" @change="changeSummaryType">
                    <label for="email-summary-full-summary"> Full summary</label>
                    <p class="text-gray-400">Send a full summary of the meeting.</p>
                  </div>
                  <div>
                    <input type="radio" id="email-summary-condensed-summary" value="Condensed summary" v-model="summaryType">
                    <label for="email-summary-condensed-summary"> Condensed summary</label>
                    <p class="text-gray-400">Send tasks and a full summary PDF.</p>
                  </div>                
                </div>
              </div> -->
              <div class="email-options my-8">
                <h4 class="text-md font-semibold text-dark py-3">Options</h4>  
                <div class="email-options-inner">
                   <div class=" relative items-center">
                     <div class="option-wrapper mb-4">
                        <div class="h-4 align-middle">
                          <div class="flex h-4 align-middle">
                            <input class="checkmark rounded bg-gray-200 border-transparent focus:border-none focus:ring-0 focus:ring-none text-gray-700 focus:ring-0 focus:ring-offset-0 focus:ring-white" type="checkbox" id="email-options-additional-notes" value="additionalNotes" @change="changeAdditionalNotes" v-model="additionalNotes" />
                            <div class="align-middle h-4 ml-5 font-bold w-full align-middle items-center">
                              <div class="font-light text-md leading-none">Add additional notes to email</div>
                            </div>                            
                          </div>                           
                        </div>                        
                      </div>
                       <div class="option-wrapper block">
                              <div class="additional-notes-text" v-show='additionalNotes'>                      
                                <textarea class="my-3 w-full border-greytwo" v-model="additionalNotesContent" placeholder="Add message"></textarea>
                              </div>
                        </div>  
                      <div class="option-wrapper">
                        <div class="h-4 align-middle">
                          <div class="flex h-4 align-middle">
                            <input class="checkmark rounded bg-gray-200 border-transparent focus:border-none focus:ring-0 focus:ring-none text-gray-700 focus:ring-0 focus:ring-offset-0 focus:ring-white" type="checkbox" id="email-options-as-agenda" value="asAgenda" @change="changeAsAgenda" v-model="asAgenda" />
                              <div class="align-middle h-4 ml-5 font-bold w-full align-middle items-center">
                              <div class="font-light text-md leading-none">Email as Agenda</div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                  </div>
                                  
                  <!-- <div>
                    <input type="checkbox" id="email-options-public-notes" value="publicNotes" v-model="publicNotes">
                    <label for="email-options-public-notes"> [TODO] Public Meeting</label> 
                  </div> -->
                </div>
              </div>
            </div>

         </div>
         <div class="email-preview px-2 py-2 w-1/2 sm:w-full bg-white flex flex-col">
            <h4 class="text-md font-semibold text-dark">Preview</h4>
            <div class="email-preview-wrapper flex-grow px-2 py-2 h-72 bg-white border border-greythree shadow-md overflow-y-scroll">
              <div v-html="convertNewLineToBR(additionalNotesContent)"></div>
              <div v-show="additionalNotes"><hr/></div>
              <div v-html="notesHeaderContent"></div>
              <div v-html="stripUnwantedTags(editorContent)"></div>
              <div v-html="notesFooterContent"></div>
            </div>
         </div>
         <div class="select-recipients px-2 py-2 bg-white absolute z-50 md:w-1/2 md:right-0 sm:w-full h-full" v-show="this.isSelectingParticipants">           
           <h4 class="text-md font-semibold text-dark">Select Recipients </h4>
           <div class="select-recipients-inner">
             <input type="text" id="select-recipients-input" class="w-full my-2 border-greytwo" placeholder="Enter any name or email" v-model="recipientSearchFilter" @input="filterRecipientList()">
             <div id="add-recipients-list">
                  <div v-for="(participant, index) in this.meetingParticipants" :key="`add-participant-${index}`" @click="toggleSelectedParticipant(participant)">
                    <div class="participant-wrapper px-2 py-2 flex justify-between align-left hover:bg-gray-50 cursor-pointer" :class="{'hidden': isFilteredRecipient(participant.email+'|'+participant.displayName) === false}">
                     <span class="participant-displayName w-full">{{ participant.displayName }} <span class="participant-email">{{ participant.email }}</span> </span>
                      
                      <span :class="{'hidden': isSelectedParticipant(participant.email) === false}">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-black inline" width="14" height="14" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                      </span>
                    </div>
                  </div>
                </div>
           </div>
         </div>
        </div>
        <div class="flex flex-col px-6 py-3 bg-white rounded-bl-3xl rounded-br-3xl">  
          <div class="flex flex-row">
            <button @click="emailNotes()" class="flex items-center justify-center h-10 px-8 text-md font-normal rounded-full bg-primaryonelight text-primaryone hover:underline">
              <svg v-if="isSendingEmail" class="email-spin animate-spin -ml-1 mr-3 h-4 w-4 text-primaryone" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Email Notes
            </button>          
          </div>
          <div class="p-2 text-red-600 bg-red-100 mt-2 text-xs" v-show="this.sendEmailError">{{ this.sendEmailError }}</div>
        </div>
      </div>
      <div v-show="sharingMenu == 'Export'">
        <div class="px-6 py-2 w-full bg-white flex"> 
          <p class="text-center z-50 p-1 opacity-100 text-md font-light">
            Click the Export Notes button below to generate a PDF of this meeting.
          </p>
        </div>
        <div class="px-6 py-2 w-full bg-white flex"> 
          <p class="text-center z-50 p-1 opacity-100 text-sm font-light">
          </p>
        </div>
        <div class="flex flex-row px-6 py-3 bg-white rounded-bl-3xl rounded-br-3xl">
          <button @click="exportNotes()" class="flex items-center justify-center h-10 px-8 text-md font-normal rounded-full bg-primaryonelight text-primaryone hover:underline">
            <svg v-if="isSendingExport" class="export-spin animate-spin -ml-1 mr-3 h-4 w-4 text-primaryone" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Export Notes
          </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>


<script>
import { OnClickOutside } from '@vueuse/components';
import { updateMeetingSummarySent } from '../utilities/CalendarUtilities.js';

export default {
  name: 'MeetingNotesSharingModal',
  props: ['editorContent','meetingDocumentId','participants','meetingTitle','meetingDateTime','scheduledMeetingTime'],
  data: () => ({
    sharingMenu: 'Email',
    summaryType: 'Full summary',
    additionalNotes: false,
    asAgenda: false,
    publicNotes: false,
    additionalNotesContent: '',
    notesHeaderContent: '',
    notesFooterContent: '',
    meetingParticipants: [],
    selectedParticipants: [],
    isSelectingParticipants: false,
    recipientSearchFilter: '',
    htmlWrapper: '',
    isSendingEmail: false,
    isSendingExport: false,
    sendEmailError: '',
  }),
  components: {
    OnClickOutside
  },
  mounted() {
    
    const options = { year: "numeric", month: "long", day: "numeric" };
    let mDate = new Date().toLocaleDateString(undefined, options);
    let mTime = new Date().toLocaleTimeString();
    let tz = new Date().toLocaleString('en', {timeZoneName:'short'}).split(' ').pop();

    let summaryType = this.asAgenda ? "agenda for" : "summary of";
    let scheduledDateString = new Date(this.scheduledMeetingTime).toLocaleDateString(undefined, options)+' '+new Date(this.scheduledMeetingTime).toLocaleTimeString();
    let summaryDate = scheduledDateString+' '+tz;
    let titlePrefix = this.asAgenda ? "Agenda for: " : "";

    this.meetingParticipants = this.participants.slice();
    this.selectedParticipants = this.participants.slice();
    this.notesHeaderContent = '<p class="title">'+titlePrefix+this.meetingTitle+'</p><p class="subtitle">'+summaryDate+'<p>';
    this.notesFooterContent = '<p class="footer-participants"><br/><br/><br/>Participants:<br/>'+this.getMeetingParticipantList()+'<br/><br/></p><hr/><br/><p class="footer-footer">This '+summaryType+' '+this.meetingTitle+' was sent by '+this.userProfileDetails.displayName+' on '+mDate+' '+mTime+'</p>';
    
    //PDF styles
    this.htmlWrapper = '<html><head>';
    this.htmlWrapper += '<style>html, body {margin: 0;padding: 0;font-weight: 500;font-size: 10px;background: rgb(255,255,255);-webkit-print-color-adjust: exact;box-sizing: border-box;}';
    this.htmlWrapper += '.page {position: relative;height: 90mm;width: 50mm;display: block;background: black;page-break-after: auto;margin: 50px;overflow: hidden;}';
    this.htmlWrapper += '.title{font-size:20px;font-weight:bold;}';
    this.htmlWrapper += '.subtitle{font-size:16px;font-weight:bold;color:#666;}';
    this.htmlWrapper += '</style></head><body>';

    

 },
  methods: {
    closeSharingModal() {
      this.$emit('CloseSharingModal');
    },
    changeSummaryType() {
      
    },
    changeAdditionalNotes() {
      this.additionalNotesContent = '';
    },
    changeAsAgenda() {
        const options = { year: "numeric", month: "long", day: "numeric" };       
        let mDate = new Date().toLocaleDateString(undefined, options);
        let mTime = new Date().toLocaleTimeString();        
        let tz = new Date().toLocaleString('en', {timeZoneName:'short'}).split(' ').pop();

        let summaryType = this.asAgenda ? "agenda for" : "summary of";
        let scheduledDateString = new Date(this.scheduledMeetingTime).toLocaleDateString(undefined, options)+' '+new Date(this.scheduledMeetingTime).toLocaleTimeString();
        let summaryDate = scheduledDateString+' '+tz;
        let titlePrefix = this.asAgenda ? "Agenda for: " : "";
        
        this.notesHeaderContent = '<p class="title">'+titlePrefix+this.meetingTitle+'</p><p class="subtitle">'+summaryDate+'<p>';
        this.notesFooterContent = '<p class="footer-participants"><br/><br/><br/>Participants:<br/>'+this.getMeetingParticipantList()+'<br/><br/></p><hr/><br/><p class="footer-footer">This '+summaryType+' '+this.meetingTitle+' was sent by '+this.userProfileDetails.displayName+' on '+mDate+' '+mTime+'</p>';
    },
    convertNewLineToBR(textAreaContent) {     
      return textAreaContent.replace(/\n\r?/g, '<br/>');
    },
    removeSelectedParticipant(index) {
      this.selectedParticipants.splice(index, 1);
    },
    getMeetingParticipantList() {      
      let names = this.meetingParticipants.map(function(item) {
        let displayName = item.displayName ? item.displayName : ''
        return displayName+' '+item.email;
      });
      return names.join('<br>');
    },
    stripUnwantedTags(string) {
        //console.log('strip unwanted tags');
        //console.log(string);

        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');

        for (let i = 1;i<=20;i++) {
          //add inline styles for ql-indent-X
          let increment = 15;
          div.querySelectorAll('.ql-indent-'+i).forEach(el => {
              el.style.marginLeft = (increment * (i+1))+"px";
          });
        }

        elements.forEach(function(element){
          let p = document.createElement('p');
          p.innerHTML = 'Task: '+element.getAttribute("title");
          element.parentNode.replaceChild(p, element);
        });

        //strip image tags
        //TODO figure out how to support this
        var images = div.querySelectorAll('img');
        images.forEach(e => e.remove());

        //console.log(div.innerHTML);

        return div.innerHTML;
    },
    stripUnwantedTagsForPDF(string) {
        //console.log('strip unwanted tags');
        //console.log(string);

        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');

        for (let i = 1;i<=20;i++) {
          //add inline styles for ql-indent-X
          let increment = 15;
          div.querySelectorAll('.ql-indent-'+i).forEach(el => {
              el.style.marginLeft = (increment * (i))+"px";
          });
        }

        elements.forEach(function(element){
          let p = document.createElement('p');
          p.innerHTML = 'Task: '+element.getAttribute("title");
          element.parentNode.replaceChild(p, element);
        });

        //console.log(div.innerHTML);

        return div.innerHTML;
    },
    removeAllRecipients() {
      this.selectedParticipants = [];
    },
    isSelectedParticipant(email) {
      if (this.selectedParticipants.filter(e => e.email === email).length > 0) {
        return true;
      }
      return false;
    },
    isFilteredRecipient(emailDisplayName) {
      let dataArray = emailDisplayName.split('|');
      if (dataArray[0] == 'undefined') dataArray[0] = '';
      if (dataArray[1] == 'undefined') dataArray[1] = '';

      if (this.recipientSearchFilter == '') return true;
      if (dataArray[0].search(this.recipientSearchFilter) === -1 && dataArray[1].search(this.recipientSearchFilter) === -1) {
        return false;
      } else if (dataArray[0].search(this.recipientSearchFilter) !== -1 || dataArray[1].search(this.recipientSearchFilter) !== -1) {
        return true;
      }
      return false;
    },
    toggleSelectRecipients() {
      //console.log(this.isSelectingParticipants);
      this.isSelectingParticipants = !this.isSelectingParticipants;
    },
    toggleSelectedParticipant(participant) {
      //console.log(participant);
      if (!this.isSelectedParticipant(participant.email)) {        
        this.selectedParticipants.push(participant);
      } else {
        //remove this email from selectedParticipants
        this.selectedParticipants = this.selectedParticipants.filter(e => e.email !== participant.email)
      }
    },
    filterRecipientList() {
      //console.log(this.recipientSearchFilter);
    },
    async emailNotes() {
      let selectedEmails = [];
      let htmlContent = this.htmlWrapper;
      let subjectString = "";
      this.isSendingEmail = true;
      this.sendEmailError = '';

      this.selectedParticipants.forEach((item) => {
        selectedEmails.push(item.email);
      });

      if (this.additionalNotes) {
        htmlContent += this.convertNewLineToBR(this.additionalNotesContent)+'<br/><hr/>';
      }
      
      htmlContent += this.notesHeaderContent;
      htmlContent += this.stripUnwantedTags(this.editorContent);
      htmlContent += this.notesFooterContent;

      const options = { year: "numeric", month: "long", day: "numeric" };
      let mDate = new Date().toLocaleDateString(undefined, options);
      let mTime = new Date().toLocaleTimeString();

      let schdMeetingTime = new Date(this.scheduledMeetingTime).toLocaleDateString(undefined, options)+' '+new Date(this.scheduledMeetingTime).toLocaleTimeString();

      //console.log(schdMeetingTime);

      htmlContent += '</body></html>';

      let emailExportPayload = {
        actionType: 'email',
        emails: selectedEmails,
        htmlContent: htmlContent,
        summaryType: this.summaryType,
        meetingTitle: this.meetingTitle,
        meetingDateTime: mDate+' '+mTime,
        asAgenda: this.asAgenda,
        scheduledMeetingTime: schdMeetingTime,
        tz: new Date().toLocaleString('en', {timeZoneName:'short'}).split(' ').pop()
      }

     

      //console.log(emailExportPayload);

      let response = await this.$store.dispatch("application/emailExportMeetingNotes", emailExportPayload);      
      console.log(response.data);      

      //TODO
      //response should throw an error if Sendgrid fails

      if (response.data.result && response.data.result == "received") {

        //if this meeting was emailed, and this.asAgenda is false, update the meeting summarySent: true
        if (this.asAgenda == false && this.meetingDocumentId) {
          updateMeetingSummarySent(this.meetingDocumentId);

          //TODO should we await the response here?

          this.$emit('meetingSummaryWasSent');
        }

        //reset this modal checkbox
        this.asAgenda = false;
        this.changeAsAgenda(); //reset
        this.isSendingEmail = false;
        this.$emit('CloseSharingModal');
      } else if (response.data.result && response.data.result == "error") {
        let errorText = (response.data.message && response.data.message.errors && response.data.message.errors[0] && response.data.message.errors[0].message) ? response.data.message.errors[0].message : 'Server Error';
        this.sendEmailError = 'Error: ' + errorText;
        this.isSendingEmail = false;        
      }

    },
    async exportNotes() {     
      let htmlContent = this.htmlWrapper;
      this.isSendingExport = true;

      htmlContent += this.notesHeaderContent;
      htmlContent += this.stripUnwantedTagsForPDF(this.editorContent);
      htmlContent += this.notesFooterContent;

      const options = { year: "numeric", month: "long", day: "numeric" };
      let mDate = new Date().toLocaleDateString(undefined, options);
      let mTime = new Date().toLocaleTimeString();

      htmlContent += '</body></html>';

      let emailExportPayload = {
        actionType: 'export',     
        htmlContent: htmlContent,
        //summaryType: this.summaryType,
        meetingTitle: this.meetingTitle,
        meetingDateTime: mDate+' '+mTime
      }

      //console.log(emailExportPayload);

      let response = await this.$store.dispatch("application/emailExportMeetingNotes", emailExportPayload);      
      //console.log(response.data);
      if (response.data) {

        //TODO
        //fix pdf filename

        const blob = new Blob([response.data], { type: "application/pdf" });
        const a = document.createElement('a');
        a.setAttribute('download', emailExportPayload.meetingTitle+'-'+emailExportPayload.meetingDateTime+'.pdf');
        a.setAttribute('href', window.URL.createObjectURL(blob));
        a.click();    

        this.isSendingExport = false;
        this.$emit('CloseSharingModal');
      }
    }
  },
  computed: {
    userProfileDetails() {
      const user_details = this.$store.getters["authentication/getUser"];
      return user_details;
    }


  }
}
    
</script>

<style>
.share-modal {
  max-width: 50%;
  max-height: 50%;
  height: calc(90vh);
}
.email-preview-wrapper p:after {
  /*content:"";
  display:inline-block; */
}
.email-preview-wrapper .title {
  font-size: 18px;
  font-weight: bold;
}
.email-preview-wrapper .subtitle {
  font-size: 16px;
  color: gray;
}
.email-preview-wrapper hr {
  margin: 1rem 0;
}
.footer-participants {
  font-size: 14px;
}
.footer-footer {
  font-size: 14px;
  color: gray;
}
.email-preview-wrapper ul {
  list-style: disc;
  padding-left: 15px;
}
.email-preview-wrapper a {
  word-break: break-all;
  color: #06c;
  text-decoration: underline;
}
.email-preview-wrapper ul li:first-child {
  margin-left: 15px;
}
</style>