<template>
  <page-header/>
  <div class="content-body my-5 px-5 font-gtamerica">
    <div class="">
      <div class="">
        <div class="w-full">
          <div class="">
            <table id="upcomingMeeingsTable" class="w-full table-auto">
              <thead class="">
                <tr class="text-gtamerica text-sm">
                  <th class="py-3 px-2 text-left font-medium text-dark">Upcoming Meeting</th>
                  <th class="py-3 px-2 text-left font-medium text-dark">Date</th>
                  <th class="py-3 px-4 text-left font-medium text-dark">Time</th>
                  <th class="py-3 px-4 text-center font-medium text-dark">Actions</th>
                </tr>
              </thead>
              <tbody v-if="this.meetingsLoaded == true" class="text-sm"> 
                <meeting-card v-for="(meeting, index) in upcomingMeetingData" :key="index" :MeetingData="meeting" v-on:calendarEventHidden="renderCalendarEvents()"></meeting-card>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import MeetingCard from '../components/UpcomingMeetingCard.vue';



export default {
  name: 'MeetingsUpcoming',
  data: () => ({
    containerLoadComplete: false,
    fullPage: false,
    upcomingMeetingData: [],
    meetingsLoaded: false,
  }),
  components: {
    MeetingCard,
    PageHeader
  },
  methods: {
    // method to compare two dates to help determine order of upcoming events //
    dateCompare(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);  
      if (date1.valueOf() < date2.valueOf()) {
        return true
      }
    },
    logout() {
      this.$store.dispatch('meetings/logout');
      this.$store.dispatch('calendars/logout');
      this.$store.dispatch('integrations/logout');
      this.$store.dispatch('authentication/logout').then(() => {
        if (this.loader) {
          this.loader.hide();
          this.loader = null;
        }
        return this.$router.push('/auth/login');
      });
    },
    // primary function responsible for rendering the calendar events //
    async renderCalendarEvents() {      
      if (!this.$store.getters["meetings/getCalendarEvents"]) return;
      let allMeetings = this.$store.getters["meetings/getCalendarEvents"];
      let user = this.$store.getters["authentication/getUser"];
      let upcomingMeetings = [];


      let currentTime = new Date();
      currentTime.setHours(0,0,0,0);
      let twoDaysFromNow = currentTime.addDays(2);
      currentTime = currentTime.toISOString();
      twoDaysFromNow = twoDaysFromNow.toISOString();
      

      console.log({allMeetings});

      // stop flow is there are no meetings to load //
      if (!allMeetings) return;

      // define function to loop through allMeetings, which is the value of the calendarEvents array in local storage //
      async function allMeetingsLoop(allMeetings) {
        console.log('allMeetingsLoop');console.log(allMeetings);
           
        if (!user) {
          throw false;
        }

        for (let a = 0; a < allMeetings.length; a++) {
          let start = allMeetings[a]['start'];
          let startDateTime;
          if (start) {
            // somtimes the start and end times are dateTime and other times they're date //
            if (start.dateTime) {
              startDateTime = new Date(start['dateTime']).toISOString();
            } else if (start.date) {
              startDateTime = new Date(start['date']).toISOString();
            }

            //TODO
            //just prune this array so that its today and tomorrow meetings

            //if meeting has a recurrance property, it is a recurring parent, ignore it
            if (allMeetings[a].status == "cancelled") {
              continue;
            }            

            // determines if events are in the future aka upcoming //
            let isRemoved = false;
            if ((currentTime < startDateTime) && (twoDaysFromNow > startDateTime)) {
              // checks the user's hidden meetings and if the length of that value is at least one //
              if (user.hiddenMeetings && user.hiddenMeetings.length > 0) {
                if (user.hiddenMeetings.includes(allMeetings[a]['id'])) {
                  isRemoved = true;
                } 
              }
              if (user.hiddenTitleMeetings && user.hiddenTitleMeetings.length > 0) {
                if (user.hiddenTitleMeetings.includes(allMeetings[a]['summary'])) {
                  isRemoved = true;
                } 
              }

              if (!isRemoved) {
                upcomingMeetings = upcomingMeetings.concat(allMeetings[a]);
              }

            } 

          }
        }
        return upcomingMeetings;
      }
      
      // call the allMeetingsLoop function with await so it won't continue until it's done loading everything //
      try {
        await allMeetingsLoop(allMeetings);
      } catch(e) {
        if (e === false) {
          this.logout();
        }
      }
      
      
      // take the upcomingMeetings array and order it from soonest to latest //
      await upcomingMeetings.sort(function(a, b) {

        let a_startDateTime = null;
        let b_startDateTime = null;

        if (a.start.dateTime) {
          a_startDateTime = new Date(a.start['dateTime']);
        } else if (a.start.date) {
          a_startDateTime = new Date(a.start['date']);
        }
        if (b.start.dateTime) {
          b_startDateTime = new Date(b.start['dateTime']);
        } else if (b.start.date) {
          b_startDateTime = new Date(b.start['date']);
        }


        let keyA = new Date(a_startDateTime),
            keyB = new Date(b_startDateTime);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

   
      //TODO 
      //since above we keep the today and tomorrow meetings, no need to slice this
      // limit the number of displayed meetings to 50 //
      this.upcomingMeetingData = upcomingMeetings.slice(0,50);  

      console.log({
        upcomingMeetingData: JSON.parse(JSON.stringify(this.upcomingMeetingData))
      });

      this.meetingsLoaded = true;
      this.$emit("viewDataLoaded");
    }
  },
  beforeMount() {
    if (!this.$store.getters["meetings/getCalendarEvents"]) return;
    let allMeetings = this.$store.getters["meetings/getCalendarEvents"] ? this.$store.getters["meetings/getCalendarEvents"] : [];
      
    // if we have data beforeMount, we can go ahead and load
    if (allMeetings.length > 0) {
      this.renderCalendarEvents();
    }
  }
}
</script>