<template>
  <div class="select-none font-nunito">
    <main class="w-full h-screen">
      <div class="flex flex-no-wrap">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'AuthenticationContainer',
  data: () => ({

  }),
  components: {
    
  },
  computed: {
    
  },
  methods: {

  },
  async beforeMount() {
    
  }
}
</script>