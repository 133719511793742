import { createRouter, createWebHistory } from 'vue-router';

// application layouts //
import ApplicationContainer from './application/ApplicationContainer.vue';
import AuthenticationContainer from './authentication/AuthenticationContainer.vue';

// views //
import UpcomingMeetings from './application/views/UpcomingMeetingsView.vue';
import MeetingsCalendar from './application/views/MeetingsCalendarView.vue';
import NotesTemplates from './application/views/NotesTemplatesView.vue';
import NotesEditor from './application/views/NotesEditorView.vue';
import UserSettings from './application/views/UserSettingsView.vue';
//import MigrateTool from './application/views/JDHMigrateToolView.vue';
import MeetingsHistorical from './application/views/HistoricalMeetingsView.vue';

// authentication //
import LoginPage from './authentication/views/LoginPage.vue';
import SignupPage from './authentication/views/SignupPage.vue';
import ForgotPasswordPage from './authentication/views/ForgotPasswordPage.vue';

// route definitions //
const routes = [
  {
    path: '',
    component: ApplicationContainer,
    name: "ApplicationContainer",
    redirect: "/upcoming",
    children: [
      {
        path: 'editor/:meeting?/:document?',
        component: NotesEditor,
        name: 'NotesEditor'
      },
      {
        path: 'upcoming',
        component: UpcomingMeetings,
        name: "UpcomingMeetings",
        meta: { title: "Upcoming Meetings", subtitle: "All of your upcoming meetings, starting with the soonest." }
      },
      {
        path: 'calendar',
        component: MeetingsCalendar,
        name: "MeetingsCalendar",
        meta: { title: "Meetings Calendar", subtitle: "A visual representation of just how busy you really are." }
      },
      {
        path: 'templates',
        component: NotesTemplates,
        name: "NotesTemplates",
        meta: { title: "Templates", subtitle: "Your library of templates to spin up a document structure." }
      },
      {
        path: 'settings',
        component: UserSettings,
        name: "UserSettings",
        meta: { title: "User Settings", subtitle: "Control your personal details, integrations, and other preferences." }
      },   
      {
        path: 'search',
        component: MeetingsHistorical,
        name: "MeetingsHistorical",
        meta: { title: "Search Meetings", subtitle: "Search for your meetings by keyword." }
      },    
    ]
  },
  {
    path: '/auth',
    name: 'AuthenticationContainer',
    component: AuthenticationContainer,
    redirect: '/auth/login',
    children: [
      {
        path: '/auth/login',
        name: 'LoginPage',
        component: LoginPage
      },
      {
        path: '/auth/signup',
        name: 'SignupPage',
        component: SignupPage
      },
      {
        path: '/auth/forgotpassword',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage
      }
    ]
  }
]

// router definition //
const router = createRouter({ 
  history: createWebHistory(), 
  routes
});
router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) ? to.meta.title + ' - Holepunch' : 'Holepunch by McGaw.io';
  next();
});

export default router