<template>
  <div class="font-gtamerica">
    <main class="flex flex-row w-screen h-screen">
      <sidebar :isExpanded="sidebarIsExpanded"/>
      <div class="flex-1 relative overflow-hidden" >
        <div class="top-header w-full absolute z-50 flex items-center justify-between px-5 py-2 bg-white h-16">
          <div @click="this.sidebarIsExpanded = !this.sidebarIsExpanded" class="flex text-xl font-primaryfour cursor-pointer">
            <svg class="w-6 h-10 fill-current text-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2 11H22V13H2zM2 5H22V7H2zM2 17H22V19H2z"></path></svg>
            <div class="flex items-center font-gtamerica font-normal ml-6 px-5 h-10 text-sm rounded-full text-primaryone bg-primaryonelight">Greetings &amp; Salutations, <span class="font-semibold text-primaryone ml-1">{{userProfileDetailsFirstName}}!</span></div>
          </div>
          <div class="flex text-black">
            <router-link to="/upcoming" :class="this.$route.path == '/upcoming' ? 'text-primaryone' : 'text-dark'" class="flex items-center justify-center h-10 px-4 ml-auto text-sm hover:underline font-normal rounded-md">
              Upcoming Meetings
            </router-link>
            <router-link to="/calendar" :class="this.$route.path == '/calendar' ? 'text-primaryone' : 'text-dark'" class="flex items-center justify-center h-10 px-4 ml-auto text-sm hover:underline font-normal rounded-md">
              Meetings Calendar
            </router-link>
              <!--<button class="flex items-center justify-center h-10 px-4 ml-auto text-sm font-normal rounded-md">
                <span class="flex h-2 w-2 relative">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primaryone opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-2 w-2 bg-primaryone"></span>
                </span>
                <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M 23.277344 4.0175781 C 15.193866 4.3983176 9 11.343391 9 19.380859 L 9 26.648438 L 6.3496094 31.980469 A 1.50015 1.50015 0 0 0 6.3359375 32.009766 C 5.2696804 34.277268 6.9957076 37 9.5019531 37 L 18 37 C 18 40.295865 20.704135 43 24 43 C 27.295865 43 30 40.295865 30 37 L 38.496094 37 C 41.002339 37 42.730582 34.277829 41.664062 32.009766 A 1.50015 1.50015 0 0 0 41.650391 31.980469 L 39 26.648438 L 39 19 C 39 10.493798 31.863289 3.6133643 23.277344 4.0175781 z M 23.417969 7.0136719 C 30.338024 6.6878857 36 12.162202 36 19 L 36 27 A 1.50015 1.50015 0 0 0 36.15625 27.667969 L 38.949219 33.289062 C 39.128826 33.674017 38.921017 34 38.496094 34 L 9.5019531 34 C 9.077027 34 8.8709034 33.674574 9.0507812 33.289062 C 9.0507812 33.289062 9.0507812 33.287109 9.0507812 33.287109 L 11.84375 27.667969 A 1.50015 1.50015 0 0 0 12 27 L 12 19.380859 C 12 12.880328 16.979446 7.3169324 23.417969 7.0136719 z M 21 37 L 27 37 C 27 38.674135 25.674135 40 24 40 C 22.325865 40 21 38.674135 21 37 z"></path></svg>
              </button>-->
              <div class="relative">
                <OnClickOutside @trigger="this.userSettingsDropdownActive = false">
                  <button @click="this.userSettingsDropdownActive = !this.userSettingsDropdownActive" class="flex items-center justify-center h-10 px-2 ml-auto text-sm font-normal rounded bg-greythree focus:outline-none">
                    <svg :class="userSettingsDropdownActive ? 'transition duration-700 ease-in-out transform rotate-90' : 'transition duration-700 ease-in-out transform rotate-0'" class="w-7 fill-current text-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.5 5l-.4-2.6C13 2.2 12.8 2 12.6 2h-1.2c-.2 0-.5.2-.5.4L10.5 5H13.5zM10.5 19l.4 2.6c0 .2.2.4.5.4h1.2c.2 0 .5-.2.5-.4l.4-2.6H10.5zM19 13.5l2.6-.4c.2 0 .4-.2.4-.5v-1.2c0-.2-.2-.5-.4-.5L19 10.5V13.5zM5 10.5l-2.6.4C2.2 11 2 11.2 2 11.4v1.2c0 .2.2.5.4.5L5 13.5V10.5zM9.8 5.2l-1.7-2C8 3 7.7 2.9 7.5 3.1l-1 .6C6.3 3.8 6.2 4 6.3 4.2l.9 2.5L9.8 5.2zM14.2 18.8l1.7 2c.2.2.4.2.6.1l1-.6c.2-.1.3-.4.2-.6l-.9-2.5L14.2 18.8zM18.8 9.8l2-1.7C21 8 21.1 7.7 20.9 7.5l-.6-1c-.1-.2-.4-.3-.6-.2l-2.5.9L18.8 9.8zM5.2 14.2l-2 1.7C3 16 2.9 16.3 3.1 16.5l.6 1c.1.2.4.3.6.2l2.5-.9L5.2 14.2zM6.7 7.2L4.2 6.3C4 6.2 3.8 6.3 3.6 6.5l-.6 1C2.9 7.7 3 8 3.2 8.1l2 1.7L6.7 7.2zM17.3 16.8l2.5.9c.2.1.5 0 .6-.2l.6-1c.1-.2.1-.5-.1-.6l-2-1.7L17.3 16.8zM16.8 6.7l.9-2.5c.1-.2 0-.5-.2-.6l-1-.6C16.3 2.9 16 3 15.9 3.2l-1.7 2L16.8 6.7zM7.2 17.3l-.9 2.5c-.1.2 0 .5.2.6l1 .6C7.7 21.1 8 21 8.1 20.8l1.7-2L7.2 17.3zM12 15c-1.7 0-3-1.3-3-3 0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3C15 13.7 13.7 15 12 15zM12 11c-.6 0-1 .4-1 1 0 .6.4 1 1 1 .6 0 1-.4 1-1C13 11.4 12.6 11 12 11z"></path><path d="M12,5c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S15.9,5,12,5z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z" opacity=".3"></path><path d="M11 5H13V11H11z"></path><path d="M5.5 13H11.5V15H5.5z" transform="rotate(-29.991 8.537 14)"></path><path d="M14.5 11H16.5V17H14.5z" transform="rotate(-60.007 15.463 13.999)"></path><path d="M12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C20,16.4,16.4,20,12,20z M12,6c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6 c3.3,0,6-2.7,6-6C18,8.7,15.3,6,12,6z"></path></svg>          
                  </button>
                  <transition name="fade">
                    <div v-show="userSettingsDropdownActive" class="w-64 my-10 bg-white shadow-md rounded absolute top-0 right-0 z-10">
                      <div class="overflow-hidden">
                        <div class="text-center p-6">
                          <img v-if="userProfileDetails" class="h-24 w-24 rounded-xl border-4 border-light shadow-md mx-auto" :src="userProfileDetails.avatar"/>
                          <p v-if="userProfileDetails" class="pt-2 text-2xl font-gtsuperdisplay font-bold">{{userProfileDetails.displayName}}</p>
                          <p v-if="userProfileDetails" class="text-md text-gray-600">{{userProfileDetails.email}}</p>
                          <div class="mt-5">
                            <router-link to="/settings" @click="this.userSettingsDropdownActive = false" class="rounded-md py-2 px-5 text-sm cursor-pointer font-normal text-primaryone bg-primaryonelight rounded-full">
                              Manage your Account
                            </router-link>
                          </div>
                        </div>
                        <div class="">
                          <div @click="this.logout()" class="px-4 py-3 bg-greythree flex cursor-pointer">
                            <p class="text-xs mx-auto font-normal text-primaryone leading-none">Logout</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </OnClickOutside>
              </div>
          </div>
        </div>
        <div class="flex w-full bg-greythree h-screen">
          <div class="flex text-black w-full h-full">
            <div class="content-wrapper flex flex-col w-full h-full">
              <router-view v-if="containerLoadComplete" @viewDataLoaded="viewCompletedLoading()"/>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded absolute left-2 bottom-2" v-if="updateExists" >
  An update is available, please click to update the application. The page will reload.
  <button class='text-primaryone hover:text-primaryone border-b-2 border-primaryone' @click="refreshApp">
    Update
  </button>
</div>
</template>

<script>

// import components //
import Sidebar from './components/Sidebar.vue';
import PageHeader from './components/PageHeader.vue';
import { OnClickOutside } from '@vueuse/components';
import Swal from 'sweetalert2';

// import utility functions //
import { getMeetings, getCalendars, getCalendarEvents, getMeetingTemplates, getPersonalMeetingTemplates } from './utilities/CalendarUtilities.js';
import { getExistingUser, saveTrelloIntegrationData, updateGoogleUserIntegrationCredentails } from "./utilities/UserUtilities.js";
import { initializeTrelloIntegration } from './utilities/TrelloUtilities.js';
import { PROTOCOL, API_DOMAIN } from '../application/utilities/ServerUtilities';

// import supporting resources //
import { store } from '../store/index';
import avatarData from '../../public/images/avatars/avatars.json';

export default {
  name: 'ApplicationContainer',
  data: () => ({
    avatarData: avatarData,
    containerLoadComplete: false,
    fullPage: false,
    loader: null,
    sidebarIsExpanded: false,
    userSettingsDropdownActive: null,
    heartbeatInterval: null,
    registration: null,
    updateExists: false
  }),
  computed: {
    // pull user data from store //
    userProfileDetails() {
      const userDetails = this.$store.getters["authentication/getUser"];
      return userDetails;
    },
    // get user's first name from store //
    userProfileDetailsFirstName() {
      const userDetails = this.$store.getters["authentication/getUser"];
      if (userDetails) {
        let firstName = userDetails.firstName;
        if (!firstName) firstName = 'Friend';
        return firstName;
      }
    }
  },
  methods: {
    // called when a child view emits the viewDataLoaded event, indicating that it has finished loading //
    viewCompletedLoading() {
      if (this.loader) {
        this.loader.hide();
        this.loader = null;
      }
    },
    refreshApp() {
      //console.log('refreshApp');
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({type: "SKIP_WAITING"});      
    },
    // logout function //
    logout() {
      console.log('logout');
      if (this.heartbeatInterval) {clearInterval(this.heartbeatInterval);}
      this.$store.dispatch('meetings/logout');
      this.$store.dispatch('calendars/logout');
      this.$store.dispatch('integrations/logout');
      this.$store.dispatch('authentication/logout').then(() => {
        if (this.loader) {
          this.loader.hide();
          this.loader = null;
        }
        return this.$router.push('/auth/login');
      });
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    getUpcomingDateObjects() {
      let today = new Date();
      let yesterday = new Date(today);
      let tommorow = new Date(today);
      yesterday.setDate(yesterday.getDate()-30); // add X days to accommodate meetings that may have been rescheduled
      tommorow.setDate(tommorow.getDate()+30);

      let minDate = new Date(yesterday);
      let maxDate = new Date(tommorow);
      
      return {min:minDate,max:maxDate};
    },

    // pulls the most recent user data from the database on every refresh so that the user data in the store is accurate //
    async userDataRefresh() {
      //console.log("run userDataRefresh");
      const user = this.$store.getters["authentication/getUser"];      
      if (!user) {
        this.logout();
      }
      const uid = user.uid;
      const userData = getExistingUser(uid).then(users => {
        let user = users[0];
        this.$store.commit("authentication/storeUser", user);
        return user;
      });
      return userData;
    }
  },
  beforeUnmount() {
    if (this.heartbeatInterval) {clearInterval(this.heartbeatInterval);}
  },
  created() {
    //Google Identity Services script
    const GISplugin = document.createElement("script");
    GISplugin.setAttribute(
      "src",
      "https://accounts.google.com/gsi/client"
    );
    GISplugin.async = true;
    GISplugin.defer = true;
    //console.log(GSIplugin);
    document.head.appendChild(GISplugin);

    document.addEventListener("swUpdated", this.updateAvailable, {once: true});

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      //localStorage.clear(); //this will force logout
      window.location.reload();
    });
  },
  async beforeMount() {

    // activate the loading animation //
    this.loader = this.$loading.show({
      color: '#1f3fff',
      loader: 'spinner',
      container: this.fullPage ? null : this.$refs.loadingContainer,
      width: 100,
      height: 100,
      backgroundColor: '#ffffff',
      opacity: .5,
      zIndex: 999,
    });

    ////
    // refresh user data from database //
    this.userDataRefresh().then((userData) => {
      return userData;
    })
    
    // define necessary variables //
    const userObject = await this.$store.getters["authentication/getUser"];
    
    if (!userObject) {
      //throw new Error('Cannot get user from Vuex.');
          
      //if we don't have a user object, force reauth
      this.containerLoadComplete = true;
      this.viewCompletedLoading();
      this.logout();
    }

    console.log("User Object: ", userObject);
    const uid = (userObject && userObject.uid) ? userObject.uid : null;
    const documentId = userObject.documentId;

    //TODO
    //sometimes documentId is undefined here, need to investigate. Possible it is async?

    if (!uid) {
      //uid missing, force logout
      this.containerLoadComplete = true;
      this.viewCompletedLoading();
      this.logout();
    }

    //console.log("UID: ", uid);    
    //console.log("Document ID: ", documentId);
    let token = null;
    try {
      token = userObject.integrations.google.googleAccessToken;
    } catch(e) {
      //user does not have a google access token, skip
      console.log(e);
    }
    
    console.log("Google Access Token: ", token);

    // ** INTEGRATIONS ** //

    // TRELLO //
    // the HTrelloToken parameter is in the URL after a user has gone through the trello authentication //
    if (this.$route.query.HTrelloToken && this.$route.query.HTrelloSecret) {
      const trellotoken = this.$route.query.HTrelloToken;
      const secret = this.$route.query.HTrelloSecret;
      const trelloIntegrationsData = {
        trello: {
        token: trellotoken,
        secret: secret
        }
      }
      //remove those query vars
      this.$router.replace({'query': null});

      saveTrelloIntegrationData(documentId, userObject, trelloIntegrationsData);
      Swal.fire({
        toast: true,
        icon: 'success',
        title: 'Trello successfully integrated.',
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
      }).then(res => {});
    }

    // END TRELLO //
    // ** END INTEGRATIONS ** //


    

    // ** CALENDAR DATA ** //
    // instantiate calendarEvents array //
    let calendarEvents = new Array();

    async function loadUserCalendarData(token, callback, store) {
      console.log('loadUserCalendarData');

      if (!token) return null;


      let response = await getCalendars(token);

      console.log('getCalendars response',response);

      //if (!response || response.error) {
      //  console.log('some error in getCalendars', response);
      //  callback();
      //} 

      //token may be updated, get user object after getCalendars
      let userData = await store.getters["authentication/getUser"];

      //console.log('response',response);

      if (!response.items) {
        //there was an issue getting calendars, logout
        console.log('getCalendars reponse.items failure');
        callback();
      }

      //find primary
      store.commit('calendars/storeAvailableCalendars', response.items);
      for (let i = 0; i < response.items.length; i++) {
        if (response.items[i].primary) {
          primaryCalendar = response.items[i].id;
        }
      }

      // store the user's primary calendar //
      console.log("Primary Calendar: ", primaryCalendar);
      if (!primaryCalendar) {
        //force logout
        console.log("no primary calendar");
        callback();
      }

      await store.commit('calendars/storePrimaryCalendar', primaryCalendar);   

      //TODO
      //we need to possibly get the updated user data here, is there a better way to pass this forward
      userData = await store.getters["authentication/getUser"];

      //console.log('after primary cal userData',userData);
          
      //get calendar events
      let calendarEvents = await getCalendarEvents(userData.integrations.google.googleAccessToken, primaryCalendar);
      return calendarEvents;

    } //loadUserCalendarData
  



    // pull array of the user's calendar data //
    let loadedCalendarData = await loadUserCalendarData(token, this.logout, this.$store).then(response => {
      return JSON.parse(response);
    });

    // get the user's meeting data from the database. Since this is for the upcoming view, we only want yesterday through tomorrow + 1 //
    let upcomingDates = this.getUpcomingDateObjects(); 

    getMeetings(uid, store.getters["calendars/getPrimaryCalendar"],upcomingDates.min,upcomingDates.max).then(response => {
      store.commit('meetings/storeUserMeetings', response);
    });
    
    
    // the calendar includes the nextPageToken property //
    let primaryCalendar = this.$store.getters["calendars/getPrimaryCalendar"];
    let pagingToken = (loadedCalendarData && loadedCalendarData.nextPageToken) ? loadedCalendarData.nextPageToken : null;
    let keepGoing = true;
    let limiter = 0;

    // if the calendar data does not include a nextPageToken property, finish //
    if (loadedCalendarData && !loadedCalendarData.nextPageToken) {
      console.log("Response does not include the NextPageToken");
      this.$store.commit('meetings/storeCalendarEvents', loadedCalendarData.items);
      calendarEvents = loadedCalendarData.items;
    } else {
      // call getCalendarEvents using the nextPageToken from the previous call to receive the next page of results //
      // iterate through all event pages until the nextPageToken is no longer present //
      while ((keepGoing) && (limiter < 200)) {
        await new Promise(resolve => {
          let result = getCalendarEvents(token, primaryCalendar, pagingToken).then(response => {
            return JSON.parse(response);
          }).then(res => {
            if (res.items) {
              calendarEvents = calendarEvents.concat(res.items)
            }
            if (!res.nextPageToken) {
              keepGoing = false;
              return
            } else {
              return res;
            }
          })
          resolve(result).then(res => {
            return res;
          })
        }).then(result => {
          if (!result) return;
          pagingToken = result.nextPageToken
        })
        limiter++;
      }
    }

    console.log("Calendar Events: ", calendarEvents);

    // filter out events that are older than 180 days //
    let filteredCalendarEvents = [];
    let dateNow = new Date().toISOString();

    // helper function to calculate the cutoff date //
    function addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    };
    let cutoffDate = addDays(dateNow, 30);
    let startDateTime;
    
  
    // store the user's calendar events array //
    await this.$store.commit('meetings/storeCalendarEvents', calendarEvents);

    //END CALENDAR EVENTS

    

    // get the user's public templates from the database and then store them //
    await getMeetingTemplates(uid).then(response => {
      //console.log("User's Public Templates from Database: ", response);
      this.$store.commit('meetings/storeMeetingTemplates', response);
    });

    // get the user's personal meeting templates and then store them //
    await getPersonalMeetingTemplates(uid).then(response => {
      //console.log("User's Personal Email Templates from Database: ", response);
      this.$store.commit('meetings/storePersonalMeetingTemplates', response);
    });


    try {
      //only await initialize if not already set
      if (store.getters['integrations/getTrelloData']) {
        initializeTrelloIntegration();
      } else {
        //we don't need to await this call, it can complete in the background
        initializeTrelloIntegration();
      }
    } catch(e) {
      console.log(e);
    }
    

    this.containerLoadComplete = true;
    this.viewCompletedLoading();
    
    // ** END CALENDAR DATA ** //

    // ** HEARTBEAT FUNCTION TO CONTINUOUSLY SYNC DATA ** //
    this.heartbeatInterval = setInterval(async () => {
      
      // define necessary variables //
      let user = store.getters["authentication/getUser"];
      let expiryTimestamp = null;

      if (user && user.integrations && user.integrations.google && user.integrations.google.expiryTimestamp) {
        expiryTimestamp = user.integrations.google.expiryTimestamp;

        if (Date.now() > expiryTimestamp) {
          expiryTimestamp = false;
        }
      }
      
      //force logout here if for some reason user is error
      if (!user) {
        this.logout(); 
      }

      const uid = user.uid;

      // refresh user data from database //
      await getExistingUser(uid).then(async users =>  {
        //console.log(users);       

        if (users[0] && expiryTimestamp == null) {
          //update user, we have no google timestamp expiry
          let user = users[0];
          store.commit("authentication/storeUser", user);

        } else if (!users[0] || expiryTimestamp == false) {
          //we either do not have a user from Firebase, or we do but we have an expired timestamp from local user object

            //can't get user from DB, try and refresh
            if (!users[0]) {
              console.log("can't get user from DB");
            }
            if (expiryTimestamp == false) {
              console.log("GIS timestamp expire");
            }
            

            //TODO
            //If we are going to support user email/password login, we need to have a refresh method for that 
            //or, do we keep this tightly coupled with Google Cal?


            if (expiryTimestamp == null) {
              //TODO
              //this user doesn't have google timestamp
            } else if (user && user.integrations && user.integrations.google && user.integrations.google.googleIdToken && user.integrations.google.googleAccessToken && user.integrations.google.googleRefreshToken) {
              //we have google integration but timestamp expired so lets reauth with GIS and Firebase Google Auth
              let retryFirebase = await this.$store.dispatch('authentication/refreshFirebaseCredential', {id_token: user.integrations.google.googleIdToken, access_token: user.integrations.google.googleAccessToken, refresh_token: user.integrations.google.googleRefreshToken, uid: user.uid});
              if (retryFirebase) {
                await getExistingUser(uid).then(users => {
                  if (users[0]) {
                    let user = users[0];
                    store.commit("authentication/storeUser", user);
                  } else {
                    store.commit("authentication/storeUser", null);
                  }
                });
              }  
            } else {
              //can't refresh
              store.commit("authentication/storeUser", mull);
            }           
        }
        //////////////////////
      });

      console.log('heartbeat: '+user.email);

      // get the user's meetings from the database and then store them //
      await getMeetings(uid, primaryCalendar, upcomingDates.min, upcomingDates.max).then(response => {
        console.log('heartbeat getMeetings',response);
        store.commit('meetings/storeUserMeetings', response);
      });
      
    }, 30*1000);
    // ** END HEARTBEAT FUNCTION ** //

  },
  components: {
    OnClickOutside,
    PageHeader,
    Sidebar
  },
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .05s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.content-wrapper {
  padding-top: 65px;
}
.page-header {
  height: 100px;
  box-sizing: border-box;
}
.content-body {
  flex: 1 1 auto;
  height: calc(100% - 100px);
  box-sizing: border-box;
  overflow-y: auto;
}
</style>