<template>
  <page-header/>
  <div class="content-body my-5 px-5 font-gtamerica">
		<div class="flex flex-row w-full items-center justify-between bg-white">
			<div class="flex flex-row items-center w-full">
				 <div class="flex w-full">
          <div class="flex flex-row w-full items-center justify-between">
            <div class="flex flex-row items-center w-full">
              <div class="w-full border-b">
                <nav class="flex flex-col w-full sm:flex-row">
                  <button @click="this.templatesMenu='Public'" :class="templatesMenu=='Public' ? 'text-primaryone hover:text-primaryone border-b-2 border-primaryone' : 'text-greyone hover:text-greytwo hover:border-greytwo'" class="py-2 mt-2 mx-3 block focus:outline-none font-medium text-sm border-b-2 border-white">
                    Public Templates
                  </button>
                  <button @click="this.templatesMenu='Team'" :class="templatesMenu=='Team' ? 'text-primaryone hover:text-primaryone border-b-2 border-primaryone' : 'text-greyone hover:text-greytwo hover:border-greytwo'" class="py-2 mt-2 mx-3 block focus:outline-none font-medium text-sm border-b-2 border-white">
                    Team Templates
                  </button>
                  <button @click="this.templatesMenu='Personal'" :class="templatesMenu=='Personal' ? 'text-primaryone hover:text-primaryone border-b-2 border-primaryone' : 'text-greyone hover:text-greytwo hover:border-greytwo'" class="py-2 mt-2 mx-3 block focus:outline-none font-medium text-sm border-b-2 border-white">
                    Personal Templates
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
    <div class="bg-white border-t border-greythree">
      <div class="">
        <div class="w-full">
          <div class="">
            <table class="w-full table-auto pt-10 bg-white">
              <thead>
                <tr class="text-light text-gtamerica text-sm bg-white">
                  <th class="pb-3 pt-10 px-4 text-left font-medium text-dark">Template Name</th>
                  <th class="pb-3 pt-10 px-4 text-left font-medium text-dark">Created</th>
                  <th class="pb-3 pt-10 px-4 text-left font-medium text-dark">Created By</th>
                  <th class="pb-3 pt-10 px-4 text-left font-medium text-dark">Actions</th>
                </tr>
              </thead>
               <tbody class="text-sm "> 
								 <template-list-card @templateLoaded="onLoadChild" @templateDeleted="onDeleteChild" v-show="templatesMenu == 'Public'" v-for="(template, index) in publicTemplates" :key="`template-${index}`" :TemplateData="template" :UserObject="this.userObject"/>
                 <template-list-card @templateLoaded="onLoadChild" @templateDeleted="onDeleteChild" v-show="templatesMenu == 'Team'" v-for="(template, index) in teamTemplates" :key="`template-${index}`" :TemplateData="template" :UserObject="this.userObject"/>
								 <template-list-card @templateLoaded="onLoadChild" @templateDeleted="onDeleteChild" v-show="templatesMenu == 'Personal'" v-for="(template, index) in personalTemplates" :key="`template-${index}`" :TemplateData="template" :UserObject="this.userObject"/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div @click="createTemplate" class="cursor-pointer px-4 py-2 text-white text-sm rounded-full font-light bg-primaryone right-0 top-10 mt-2 inline-block">            
      Add New Template
    </div>
  </div>
  <transition name="fade">
    <template-editor-modal v-if="isTemplateLoaded"
      v-show="templateEditModalActive" 
      @UpdateTemplateModal="handleModalUpdate" 
      @UpdateTemplateModalTemplates="handleModalUpdateTemplates" 
      :editorContent="this.editorContent" 
      :editorQuillContent="this.templateQuillContent"
      :template="this.selectedTemplate"
      :isCreate="this.isCreate" 
      v-on:templateContentLoaded="loadFromTemplate"
    />
  </transition>
</template>


<script>
import MeetingCard from '../components/UpcomingMeetingCard.vue';
import PageHeader from '../components/PageHeader.vue';
import TemplateListCard from '../components/TemplateListCard.vue';
import TemplateEditorModal from '../components/TemplateEditorModal.vue';
import { getMeetingTemplates, getPersonalMeetingTemplates } from '../utilities/CalendarUtilities.js';


export default {
  name: 'MeetingsTemplates',
  data: () => ({
    publicTemplates: [],
    personalTemplates: [],
    teamTemplates: [],
    userObject: null,
    isTemplateLoaded: false,
    isCreate: false,
    templateEditModalActive: false,
    selectedTemplate: null,
    editorContent: '',
    loadedTemplate: null,
    loadMenu: 'Public',
		templatesMenu: 'Public'
  }),
  components: {
    MeetingCard,
    PageHeader,
		TemplateListCard,
    TemplateEditorModal
  },
  methods: {
    logout() {
      this.$store.dispatch('meetings/logout');
      this.$store.dispatch('calendars/logout');
      this.$store.dispatch('integrations/logout');
      this.$store.dispatch('authentication/logout').then(() => {
        if (this.loader) {
          this.loader.hide();
          this.loader = null;
        }
        return this.$router.push('/auth/login');
      });
    },
    handleModalUpdate() {
        console.log('handleModalUpdate');
        this.templateEditModalActive = false; 
        this.isTemplateLoaded = false;    
    },
    handleModalUpdateTemplates() {
        console.log('handleModalUpdateTemplates');
        this.updateTemplateStore();
    },
    getReadableDateTime(firestoreTimestamp) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        let mDate = new Date(firestoreTimestamp.seconds*1000).toLocaleDateString(undefined, options);
        let mTime = new Date(firestoreTimestamp.seconds*1000).toLocaleTimeString();
        return mDate+' '+mTime;         
    },  
    loadTemplateMenu(menu) {
        this.loadMenu = menu;
    }, 
    createTemplate() {
      console.log('createTemplate');
      this.isCreate = true;
      this.isTemplateLoaded = true;
      this.templateEditModalActive = true;
      this.templateQuillContent = null;
      this.selectedTemplate = null;
    },
    onDeleteChild(data) {    
      this.isCreate = false;  
      if (this.templatesMenu == 'Public') {
        let filteredTemplates = this.publicTemplates.filter(function( obj ) {
            return obj.id !== data;
        });
        this.publicTemplates = filteredTemplates;
      } else if (this.templatesMenu == 'Team') {
        let filteredTemplates = this.teamTemplates.filter(function( obj ) {
            return obj.id !== data;
        });
        this.teamTemplates = filteredTemplates;  
      } else if (this.templatesMenu == 'Personal') {
        let filteredTemplates = this.personalTemplates.filter(function( obj ) {
            return obj.id !== data;
        });
        this.personalTemplates = filteredTemplates;
      }
    },
    onLoadChild(data) {
      console.log('need to set editor',data);
      this.isCreate = false;
      this.isTemplateLoaded = true;
      this.templateEditModalActive = true;

      
      //cleanup task nodes
      for (let i = 0; i < data.quillContent.length; i++) {
          if (data.quillContent[i].insert && data.quillContent[i].insert.taskBlot && data.quillContent[i].insert.taskBlot.taskIsSynced) {
            data.quillContent[i].insert.taskBlot.taskIsSynced = "false";
          }
          if (data.quillContent[i].insert && data.quillContent[i].insert.taskBlot && data.quillContent[i].insert.taskBlot.trelloCard) {
            data.quillContent[i].insert.taskBlot.trelloCard = null;
          } 
      }       

      this.templateQuillContent = data.quillContent;
      this.selectedTemplate = data;
    },
    async updateTemplateStore() {
      console.log('updateTemplateStore');

      const user_details = this.$store.getters["authentication/getUser"];
      
      getMeetingTemplates(user_details.uid).then(response => {    
          console.log(response); 
          response.sort(function(a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });        
          this.$store.commit('meetings/storeMeetingTemplates', response);  
          this.publicTemplates = this.$store.getters["meetings/getMeetingTemplates"];              
      });            
      getPersonalMeetingTemplates(user_details.uid).then(response => {     
          console.log(response);  
          response.sort(function(a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });          
          this.$store.commit('meetings/storePersonalMeetingTemplates', response);  
          this.personalTemplates =  this.$store.getters["meetings/getPersonalMeetingTemplates"];        
      });   

      //sort alphabetical
      /*
      storepublicTemplates.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      storepersonalTemplates.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      */
      /*
      storeteamtemplates.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      */
    /*
      this.teamtemplates = storeteamtemplates;
      this.publicTemplates = storepublicTemplates;              
      this.personalTemplates = storepersonalTemplates;
    */
    }
    
  },
  computed: {
    
  },
  mounted() {
    const currentUrl = window.location.href;
    const hasTemplateParam = currentUrl.includes('template=create');
    if (hasTemplateParam) {
      this.createTemplate();
    }
  },
  async beforeMount() {
    /*
    let storeteamtemplates = this.$store.getters["meetings/getTeamMeetingTemplates"];
    let storepublicTemplates = this.$store.getters["meetings/getMeetingTemplates"];              
    let storepersonalTemplates = this.$store.getters["meetings/getPersonalMeetingTemplates"]; 

    //sort alphabetical
    
    storepublicTemplates.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    storepersonalTemplates.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    
    //storeteamtemplates.sort(function(a, b) {
        //var textA = a.name.toUpperCase();
        //var textB = b.name.toUpperCase();
        //return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    //});
    

    this.teamtemplates = storeteamtemplates;
    this.publicTemplates = storepublicTemplates;              
    this.personalTemplates = storepersonalTemplates; 
  */

    this.userObject = this.$store.getters["authentication/getUser"];
    if (!this.userObject) {
        this.logout();
      }

    await this.updateTemplateStore();


    this.$emit("viewDataLoaded");
 }
}
</script>