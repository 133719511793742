<template>
  <div class="w-screen h-screen absolute z-50 bg-black bg-opacity-10 backdrop-filter backdrop-blur-sm -mt-16 -ml-20">
    <div class="min-h-screen overflow-y-auto w-full flex items-center justify-center">
      <div class="template-editor-modal w-full">
				<div class="template-editor-modal-header shadow-lg flex flex-row w-full">
          <div class="px-4 py-5 bg-white rounded-tl-3xl w-full relative">
            <div class="text-4xl font-gteesti text-dark font-normal">
            	Template Editor
            </div>
            <p v-show="isCreate == true" class="text-md text-dark font-light">
              Create Template
            </p>
            <p v-show="isCreate == false" class="text-md text-dark font-light">
              Editing Template: <input type="text" v-model="editingTemplateName" @input="handleEditingTitleInput" class="template-input w-96"/>
            </p>
            <div @click="saveTemplate" v-if="templateChanged == true" class="absolute cursor-pointer px-4 py-2 text-white text-sm rounded-full font-light bg-primaryone right-0 top-10">            
              Save Changes
            </div>
          </div>
          <div class="px-4 py-2 relative bg-white rounded-tr-full" >
            <svg @click="closeTemplateEditorModal" class="-top-3 -right-3 w-10 h-10 p-2 absolute bg-white rounded-full cursor-pointer fill-current shadow-lg hover:shadow-md text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
        <div class="template-editor-modal-body px-6 py-2 w-full bg-white flex"> 
          <div v-show="loadMenu == 'Public'" class="w-full">
            <quill-private-notes-component ref="privateTemplateEditor" :content="templateQuillContent" @change="onTemplateContentUpdated"></quill-private-notes-component> 
          </div>
        </div>


        </div>
      </div>
    </div>
</template>


<script>
import { OnClickOutside } from '@vueuse/components';
import { saveMeetingTemplate, updateMeetingTemplateProperty, updateMeetingTemplateNameAndContent, getMeetingTemplates, getPersonalMeetingTemplates } from '../utilities/CalendarUtilities.js';
import QuillPrivateNotesComponent from '../components/QuillEditor/QuillPrivateNotesComponent.vue';
import Swal from 'sweetalert2';

export default {
  name: 'TemplateEditorModal',
  props: ['editorContent','editorQuillContent','template','isCreate'],
  emits: ['UpdateTemplateModal','UpdateTemplateModalTemplates'],
  data: () => ({    
    templateMenu: 'Load',
    loadMenu: 'Public',
    publicTemplate: false,   
    templateData: null,
    templateName: '', 
    editingTemplateName: '',
    templateDescription: '',
    templateContent: '',
    templateQuillContent: null,
    templateChanged: false,
    active_el: 0
  }),
  components: {
    OnClickOutside,
    QuillPrivateNotesComponent
  },
  mounted() {

 },
 async beforeMount() {    
    this.templateContent = this.stripUnwantedTags(this.editorContent);
    this.templateQuillContent = this.editorQuillContent;
    this.templateData = this.template;    
    this.templateName = this.template?.name; 
    this.editingTemplateName = this.template?.name; 
 },
 methods: {
    closeTemplateEditorModal() {
      this.templateName = ''; 
      this.templateDescription = '';
      this.templateContent = '',
      this.templateQuillContent = [],
      this.templateChanged = false;
      this.$emit('UpdateTemplateModal');
    },
    getReadableDateTime(firestoreTimestamp) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        let mDate = new Date(firestoreTimestamp.seconds*1000).toLocaleDateString(undefined, options);
        let mTime = new Date(firestoreTimestamp.seconds*1000).toLocaleTimeString();
        return mDate+' '+mTime;         
    },   
    onTemplateContentUpdated(value) {
      console.log('change: ',value);
      this.templateChanged = true;
    }, 
    activate(el) {        
        this.active_el = el;
    },
    handleEditingTitleInput() {
        this.templateChanged = true;
    },
    /*
    loadTemplateMenu(menu) {
        this.loadMenu = menu;
        this.active_el = 0;
    },
    */
    /*
    loadTemplate(index,type) {
        let content = null;
        
        if (type == "public" && this.publicTemplates[index]) {
            content = {
                body: this.publicTemplates[index].body,
                quillContent: this.publicTemplates[index].quillContent
            };
        } else if (type == "personal" && this.personalTemplates[index]) {
            content = {
                body: this.personalTemplates[index].body,
                quillContent: this.personalTemplates[index].quillContent
            };
        }
        //console.log(content);
        if (content !== null) {            
            this.$emit('templateContentLoaded', content);
        }
        this.closeTemplatesModal();
       
    }, */
    
    stripUnwantedTags(string) {
        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');        

        elements.forEach(e => e.parentNode.removeChild(e));
        return div.innerHTML;
    },
    /*
    stripUnwantedTagsForPreview(string) {
        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');    
        
        for (let i = 1;i<=20;i++) {
          //add inline styles for ql-indent-X
          let increment = 15;
          div.querySelectorAll('.ql-indent-'+i).forEach(el => {
              el.style.marginLeft = (increment * (i+1))+"px";
          });
        }

        elements.forEach(e => e.parentNode.removeChild(e));
        return div.innerHTML;
    },
*/


    
    async saveTemplate() {
      const user_details = this.$store.getters["authentication/getUser"];
      //console.log('user_details');
      //console.log(user_details);

      const editorData = await this.$refs.privateTemplateEditor.getCurrentData()

      const meetingTemplateUpdate = editorData?.ops;

      console.log(this.isCreate);

      if (this.templateData?.documentId) {
     
        console.log('trying to save template editor modal',this.templateData.documentId,meetingTemplateUpdate);

        //TODO
        //should we save a backup revision?
      
        let result = await updateMeetingTemplateNameAndContent(this.templateData.documentId,this.editingTemplateName, meetingTemplateUpdate);         
        console.log('result',result);

        if (result) {
          console.log('// -- got result from saving updated template');
              getMeetingTemplates(user_details.uid).then(response => { 
                  console.log('response',response);        
                  this.$store.commit('meetings/storeMeetingTemplates', response);            
              });            
              getPersonalMeetingTemplates(user_details.uid).then(response => {   
                  console.log('response',response);            
                  this.$store.commit('meetings/storePersonalMeetingTemplates', response);        
              });   
            //TODO
            //replace this with a different emit event, so that we only update the specific template in the list in the parent element  
            this.$emit('UpdateTemplateModalTemplates');          
          }
          this.closeTemplateEditorModal();
      } else if (this.isCreate) {
          Swal.fire({
            title: 'Save Template',
            html:
              '<label class="swal2-label block" for="templateName">Template Name</label>' +
              '<input id="templateName" class="swal2-input font-gtamerica" placeholder="Enter the name of your template">' +              

              '<label class="swal2-label block" for="templateDesc">Description</label>' +
              '<input id="templateDesc" class="swal2-input font-gtamerica" placeholder="Enter a description for your template">' +
              '<div class="flex justify-evenly mt-2 mb-2">' +
              '  <div>' +
              '    <input type="radio" id="public" name="access" value="public">' +
              '    <label class="swal2-label" for="public">Public Template</label>' +
              '  </div>' +
              '  <div>' +
              '    <input type="radio" id="private" name="access" checked="checked" value="private">' +
              '    <label class="swal2-label" for="private">Private Template</label>' +
              '  </div>' +
              '</div>',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            preConfirm: () => {
              const templateName = document.getElementById('templateName').value;
              const templateDesc = document.getElementById('templateDesc').value;
              const accessType = document.querySelector('input[name="access"]:checked').value;
              return { templateName, templateDesc, accessType };
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const { templateName, templateDesc, accessType } = result.value;
              await this.saveTemplateToDatabase(templateName, templateDesc, accessType, meetingTemplateUpdate, user_details);              
              Swal.fire('Template Saved', `Name: ${templateName}, Description: ${templateDesc}, Access Type: ${accessType}`);
              this.closeTemplateEditorModal();
              
            } else {
              //this.closeTemplateEditorModal();
            }
          });
      }      
      
      
    },  
     async saveTemplateToDatabase(templateName, templateDesc, accessType, meetingTemplateUpdate, user_details) {  

        console.log('save template to database');        

        let meetingTemplate = {
          name: templateName,        
          description: templateDesc,
          body: this.templateContent,
          public: accessType === "public",
          quillContent: meetingTemplateUpdate
        };
      
        let result = await saveMeetingTemplate(meetingTemplate, user_details);    

          if (result !== false) {
              console.log('saveTemplateToDatabase result',result);  
              this.$emit('UpdateTemplateModalTemplates');            
          }         

          return result;
     }
  },
  watch: { 
    editorContent: function(newVal, oldVal) { // watch it
        //remove html content we don't want
        this.templateContent = this.stripUnwantedTags(newVal);        
    },
    editorQuillContent: function(newVal, oldVal) {
        //console.log('newVal',newVal);     
        this.templateQuillContent = newVal;         
        this.$refs.privateTemplateEditor.updateFromEditTemplate(newVal); 
        this.templateChanged = false;      
    },
    template: function(newVal, oldVal) {
        this.templateData = newVal;
    }
  },
  computed: {
    userProfileDetails() {
      const user_details = this.$store.getters["authentication/getUser"];
      return user_details;
    }
  }
}
    
</script>

<style>
.template-editor-modal {
  max-width: 75%;
  max-height: 50%;
  height: calc(80vh);
}
.template-editor-modal-body, .template-editor-modal-body .ql-editor {
  height: calc(100% - 100px);
}
.template-editor-modal-body .ql-toolbar.ql-snow {
  width: 100%;
}
.template-editor-modal-body .task-blot {
  position: relative;
}
.template-editor-modal-body .task-blot input {
  top: 0 !important;
  left: 54px !important;
}
.template-editor-modal-body .ql-editor.editing {
  /*overflow: hidden;*/
}
.template-element .hide-element {
    display: none;
}
.template-element.active {
    background-color: #fff;
}
.template-element.active .hide-element {
    display: block;
}
.template-editor-modal-body div#syncButton {
    pointer-events: none;
    display: none;
}
.swal2-input {
    width: calc(100% - 3rem);
    margin: 10px auto 10px;
}
[class^="swal2-"] {
    font-family: GT-Eesti, sans-serif;
}
</style>