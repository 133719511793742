const fetch = require('node-fetch');
import { PROTOCOL, FULL_DOMAIN, DOMAIN, PROCESS_PORT, API_DOMAIN } from './ServerUtilities';

// create a new task //
export const createNewTask = async (userId, taskId) => {
	const res = await fetch(`${PROTOCOL}${API_DOMAIN}/api/tasks/createNewTask`, {
    method: 'POST',
    headers: {
      'Accept': '*'
    },
    body: {
      user: userId,
      task: taskId
    }
  }).then(res => {
    return res.json();
  })
  return res;
}



// delete a task //



