<template>
  <div class="relative min-h-screen w-full flex ">
    <div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
      <div class="relative sm:w-1/2 xl:w-3/5 h-full md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-primary text-white bg-fit relative"
        style="background-image: url(https://firebasestorage.googleapis.com/v0/b/alpine-realm-314501.appspot.com/o/img%2Fbackgrounds%2Fpattern-randomized.svg?alt=media&token=9763368a-8517-464e-8ce3-1c3f1423d828);">
        <div class="w-full lg:max-w-2xl md:max-w-md z-10">
          <div class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full shadow-md py-8 sm:rounded-lg md:rounded-none bg-white">
            <div class="max-w-xl w-full space-y-8">
              <div class="text-center">
                <h2 class="text-4xl font-thin font-gteesti text-dark">
                  Forgot Your Password?
                </h2>
                <p class="mt-2 text-sm text-dark">We can help you recover your account.</p>
              </div>
              <div>
              <div v-show="!this.loggedIn" class="flex items-center justify-center space-x-2 mt-5">
                <span class="h-px w-16 bg-gray-200"></span>
                <span class="text-gray-300 text-xs font-normal">enter your email address</span>
                <span class="h-px w-16 bg-gray-200"></span>
              </div>
            
              <form v-show="!this.loggedIn" @submit.prevent="passwordLogIn()" class="mt-8 space-y-6">
                <div class="relative">
                  <label class="ml-1 text-xs font-bold text-gray-700 tracking-wide">Email</label>
                  <input
                    class="w-full text-dark px-4 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:ring-none focus:border-gray-200"
                    type="email" placeholder="" v-model="email">
                </div>
                <div>
                  <button type="submit" class="w-1/2 align-middle flex mx-auto justify-center bg-primaryonelight text-primaryone p-2 rounded-full tracking-wide font-semibold focus:outline-none focus:shadow-outline hover:bg-primarytwolight cursor-pointer transition ease-in duration-300">
                    Recover Password
                  </button>
                </div>
                <p class="flex flex-col text-sm items-center justify-center mt-10 text-center text-md text-gray-500">
                  <span>Don't need to recover your password?</span>
                  <router-link to="/auth/login" class="text-primaryone underline text-xs hover:text-indigo-dark hover:underline cursor-pointer transition ease-in duration-300">Login here</router-link>
                </p>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "LoginPage",
  data: () => ({
    loggedIn: '',
    password: ''
  }),
  components: {

  },
  computed: {
    UserIsLoggedIn() {

    }
  },
  methods: {
    passwordLogIn() {
      this.$store.dispatch('authentication/passwordLogIn', {email: this.email, password: this.password});
    },
    googleLogIn() {
      this.$store.dispatch('authentication/googleLogIn');
    }
  },
  async beforeMount() {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
        return;
      } else {
        this.loggedIn = false;
        return;
      }
    });
  }
}
</script>