/* integrations.store.js */
import axios from "axios";

// define variables from environment variables //
import { DOMAIN, PROTOCOL, PROCESS_PORT, API_DOMAIN } from "../../application/utilities/ServerUtilities";

// initial state object //
const initialState = () => ({
  asana: null,
  box: null,
  dropbox: null,
  evernote: null,
  google: null,
  googleDrive: null,
  microsoftTeams: null,
  onedrive: null,
  outlook: null,
  slack: null,
  todoist: null,
  trello: null,
  zapier: null,
  zendesk: null
});

// integrations state object //
const state = initialState();

// integrations getter functions //
const getters = {

  // *** GENERAL *** //
  getintegrations(state) {
    return state;
  },
  // *** END GENERAL *** //

  // *** TRELLO *** //
  // accesses the stored token and secret from the integrations object //
  getTrelloData(state) {
    return state.trello
  },
  // gets the most recently-selected trello board and list //
  getTrelloSelection(state) {
    return state.trello.trelloSelection
  }
  // *** END TRELLO *** //

}

// integrations actions // 
const actions = {

  logout: async ({commit}) => {
    commit('integrationsLogout'); 
  },

  // *** TRELLO *** //
  // pulls all current trello data based on the provided token - member data, boards, lists, etc. //
  initializeTrelloMemberData: ({commit, dispatch}, payload) => {
    let getMemberData = async function() {
      let data = await fetch(`${PROTOCOL}${API_DOMAIN}/api/trello/getMemberDetails?token=` + payload.token, {
        method: 'GET',
        headers: {
          'Accept': '*'
        }
      }).then(response => {
        let memberData = response.json();
        return memberData;
      });
      return data;
    }

    let getMemberNestedBoardData = async function(memberId) {
      let data = await fetch(`${PROTOCOL}${API_DOMAIN}/api/trello/getMemberNestedBoardDetails?token=` + payload.token + '&memberid='+memberId, {
        method: 'GET',
        headers: {
          'Accept': '*'
        }
      }).then(response => {
        let memberNestedBoardData = response.json();
        return memberNestedBoardData;
      });
      return data;
    }

    let getBoardData = async function(boardId) {
      let data = await fetch(`${PROTOCOL}${API_DOMAIN}/api/trello/getBoards?token=` + payload.token + '&boardId=' + boardId, {
        method: 'GET',
        headers: {
          'Accept': '*'
        }
      }).then(response => {
        let boardData = response.json();
        return boardData;
      });
      return data;
    }

    let getListsByBoardData = async function(boardId) {
      let data = await fetch(`${PROTOCOL}${API_DOMAIN}/api/trello/getListsByBoard?token=` + payload.token + '&boardId=' + boardId, {
        method: 'GET',
        headers: {
          'Accept': '*'
        }
      }).then(response => {
        let boardData = response.json();
        return boardData;
      });
      return data;
    }

    return getMemberData().then( async (response) => {
      let returnedMemberData = response;
      let memberBoardIds = returnedMemberData.idBoards;
      let returnedBoardData = [];
      let returnedListData = [];
      let returnedNestedBoardData;
      let lastSync = new Date().getTime();

      //console.log('memberData');console.log(returnedMemberData);

      
      //testing getting nested board data
      try {
        await getMemberNestedBoardData(returnedMemberData.id).then((response) => {
          returnedNestedBoardData = response;
        });
      } catch (err) {
        console.log(err);
      }

      /*for (let i = 0;i< memberBoardIds.length;i++) {
        //console.log('processing board: '+memberBoardIds[i]);
        await getBoardData(memberBoardIds[i]).then((response) => {
          returnedBoardData = returnedBoardData.concat(response);
        });
        await getListsByBoardData(memberBoardIds[i]).then((response) => {
          let listPayload = { board: memberBoardIds[i], lists: response }
          returnedListData = returnedListData.concat(listPayload);                   
        });         
      }*/ 
      //console.log('commit updated trello dropdown data');


      //console.log('returnedNestedBoardData');console.log(returnedNestedBoardData);
      //console.log('returnedBoardData');console.log(returnedBoardData);


      commit('storeTrelloData', {
          member: returnedMemberData,
          boards: returnedNestedBoardData.boards,
          lists: returnedListData,
          lastSync: lastSync
        });
      return {
        member: returnedMemberData,
        boards: returnedNestedBoardData.boards,
        lists: returnedListData,
        lastSync: lastSync
      }
    });
  },
  getTrelloBoardListData: ({commit, dispatch}, payload ) => {
    return axios({
      method: 'post',
      url: `${PROTOCOL}${API_DOMAIN}/api/trello/getListsByBoard`,
      data: {
        token: payload.token,
        boardId: payload.boardId,       
      }
    }).then(response => {
      return response;
    })
  },  
  // function to create a new card in trello //
  createNewTrelloCard: ({commit, dispatch}, payload ) => {
    return axios({
      method: 'post',
      url: `${PROTOCOL}${API_DOMAIN}/api/trello/createNewTrelloCard`,
      data: {
        token: payload.token,
        board: payload.boardId,
        list: payload.listId,
        card: payload.newCard
      }
    }).then(response => {
      return response;
    })

  },
  getTrelloCard: ({commit, dispatch}, payload ) => { 
    return axios({
      method: 'post',
      url: `${PROTOCOL}${API_DOMAIN}/api/trello/getTrelloCard`,
      data: {
        token: payload.token,        
        cardId: payload.cardId
      }
    }).then(response => {
      return response;
    })

  },
  updateTrelloCard: ({commit, dispatch}, payload ) => { 
    return axios({
      method: 'post',
      url: `${PROTOCOL}${API_DOMAIN}/api/trello/updateTrelloCard`,
      data: {
        token: payload.token,        
        cardId: payload.cardId,
        data: payload.data
      }
    }).then(response => {
      return response;
    })

  },
  // *** END TRELLO *** //

}

// integrations mutations //
const mutations = {

  // *** GENERAL *** //
  storeintegrations (state, integrations) {
    state.integrations = integrations
  },
  integrationsLogout (state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
          state[key] = newState[key]
    });
  },
  // *** END GENERAL *** //

  // *** TRELLO *** //
  // updated the data stored in the trello integrations object //
  storeTrelloData (state, payload) {
    state.trello = payload;
  },
  // update the most recently-selected trello board and list //
  storeTrelloSelection (state, payload) {
    state.trello.trelloSelection = payload;
  },
  // *** END TRELLO *** //

}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}