<template>

  <div ref="editor" class="w-9/12 pt-24 mx-auto top-2 block bg-white rounded-md min-h-screen font-gtamerica" id="editor"></div>
  <div class="w-3/4 mx-auto py-5 text-right text-gray-400" id="counter"></div>
  
</template>

<script>
// import functions //
import { loadMeetingData, saveMeetingNotes } from '../../utilities/CalendarUtilities'; 
import { DOMAIN, WEBSOCKET_DOMAIN, WEBSOCKET_PROTOCOL } from '../../utilities/ServerUtilities.js';

// import libraries //
import _Quill from 'quill';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';

// import modules //
import MarkdownShortcuts from 'quill-markdown-shortcuts';
import MagicUrl from 'quill-magic-url';
import QuillCursors from 'quill-cursors';
import * as emoji from 'quill-emoji';
import { TaskBlot, Task } from './modules/task/task.js';
import { RecurringMeetingBlockBlot, RecurringMeetingBlock } from './modules/recurringMeetingBlock/recurringMeetingBlock.js';
import { Clipboard } from './modules/clipboard/clipboard.js';
import 'quill-mention';
import './modules/counter/counter';
import ImageCompress from 'quill-image-compress';

const Quill = window.Quill || _Quill;
var Delta = Quill.import('delta');

Quill.debug('error');

const mentionOptions = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@"],
  source: function(searchTerm, renderList, mentionChar) {
    let values = [
      { id: 1, value: "Jeffrey Brown" },
      { id: 2, value: "Joel Hatmaker" }
    ];
    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (
          ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
        )
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  }
}
let quillOptions = {
  debug: 'warn',
  modules: {
    cursors: {
      selectionChangeSource: null
    },
    //markdownShortcuts: true,
    magicUrl: true,
    mention: mentionOptions,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link'],
        ['emoji','task']
      ]
    },
    task: true,
    recurringMeetingBlock: true,
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
    counter: {
      container: '#counter',
      unit: 'word'
    },
    history: {
      userOnly: true
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 800, // default
      maxHeight: 800, // default
      imageType: 'image/jpeg', // default
      debug: true, // default
      suppressErrorLogging: false, // default
    }
  },
  boundary: document.body,
  placeholder: `"If you're not typing, you're doing it wrong." - Nik`,
  theme: 'snow',
  scrollingContainer: '.notes-editor-content'
};
    

// register modules as necessary //
Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/cursors', QuillCursors);
Quill.register('modules/imageCompress', ImageCompress);

export default {
  name: "QuillEditorComponent",
  data() {
    return {
      _options: {},      
      quillOptions
    }
  },
  computed: {
    documentId() {
      const documentId = this.$route.params.document
      return documentId;
    },
    userDetails() {
      return this.$store.getters['authentication/getUser'];
    },
    userId() {
      const userDetails = this.$store.getters['authentication/getUser'];
      return userDetails.uid;
    },
    trelloIsIntegrated() {
      let user = this.$store.getters['authentication/getUser'];
      if (user.integrations.trello) { var token = user.integrations.trello.token }
      if (token) return true;
    },
  },
  emits: ['input','change','ready'],
  methods: {
    async initialize() {

      if (!this.$el) {
        return;
      }

      // load meeting data //
      let document = this.$route.params.document;
      let result = await loadMeetingData(document);
      const meetingAttendees = result.attendees;

      // options //
      this._options = quillOptions;

      // instance //
      //check store if Trello integrated
      if (!this.trelloIsIntegrated) {
        this._options.modules.task = false;
      } 

      this.quill = new Quill(this.$refs.editor, this._options);

      // websocket config //
      const ydoc = new Y.Doc();
      const ytext = ydoc.getText('quill');

      try {
        //console.log('domain is');
        //console.log(`${WEBSOCKET_PROTOCOL}://${WEBSOCKET_DOMAIN}`);
        const provider = new WebsocketProvider(
          `${WEBSOCKET_PROTOCOL}://${WEBSOCKET_DOMAIN}`, this.documentId, ydoc
        );
        provider.on('status', event => {
          this.status = event.status;
          console.log('websocket event status: ', this.status);
        });
        const binding = new QuillBinding(ytext, this.quill, provider.awareness);
        const awareness = provider.awareness;
        const user = this.$store.getters['authentication/getUser'];
        const myColor = user.color;
        awareness.setLocalStateField('user', {
          name: this.userDetails.displayName, color: myColor
        });
      } catch (error) {
        console.log('Websocket Init error: ' + error);
      }

      // set editor content from prop //
      if (this.content) {
        this.quill.pasteHTML(this.content)
      }

      //custom hotkeys
      this.quill.keyboard.addBinding({
        key: 'X',
        shiftKey: true,
        shortKey: true,
        handler: function(range, context) {       
          let currentFormat = this.quill.getFormat(range.index, range.length);
          if (currentFormat && currentFormat.strike == true) {
            this.quill.formatText(range.index, range.length, 'strike', false);
          } else {
            this.quill.formatText(range.index, range.length, 'strike', true);
          }               
        }
      });
      this.quill.keyboard.addBinding({
        key: 220,
        shortKey: true,
        handler: function(range, context) {
          this.quill.formatText(range.index, range.length, {              
            'background': false,
            'bold': false,
            'color': false,
            'font': false,
            'code': false,
            'italic': false,
            'link': false,
            'size': false,
            'strike': false,
            'script': false,
            'underline': false,
            'blockquote': false,
            'header': false,
            'indent': false,
            'list': false,
            'align': false,
            'direction': false,
            'code-block': false
          });
        }
      });
      this.quill.keyboard.addBinding({
        key: "1",
        shortKey: true,
        altKey: true,
        handler: function(range, context) {
          this.quill.formatLine(range.index, range.length, 'header', 1);
        }
      });
      this.quill.keyboard.addBinding({
        key: "2",
        shortKey: true,
        altKey: true,
        handler: function(range, context) {
          this.quill.formatLine(range.index, range.length, 'header', 2);
        }
      });
      this.quill.keyboard.addBinding({
        key: "3",
        shortKey: true,
        altKey: true,
        handler: function(range, context) {
          this.quill.formatLine(range.index, range.length, 'header', 3);
        }
      });
      this.quill.keyboard.addBinding({
        key: "4",
        shortKey: true,
        altKey: true,
        handler: function(range, context) {
          this.quill.formatLine(range.index, range.length, 'header', 4);
        }
      });

      //Custom Backspace Methods (this doesn't work at offset 0?)
      /*this.quill.keyboard.addBinding({ 
        key: 'backspace', 
        //empty: true,
        //context: { format: ['list'] }, 
        handler: function (range, context) { 
          console.log('backspace');
          console.log(range,context);
          console.log(this.quill)
          //if (range.length === 0 && range.index !== 0) { this.quill.deleteText(range.index - 1, 1, Quill.sources.USER); }
        }
      });*/

      this.quill.on('text-change', (delta, oldDelta, source) => {
        //this will occur automatically when the document loads from websocket
        //it will also occur when a change occurs (someone editing the doc)
        try {
          let html = this.$refs.editor.getElementsByClassName("ql-editor")[0].innerHTML;          
          let text = this.quill.getText();
          if (html === '<p><br></p>') html = '';
          this._content = html;  
          
          let content = this.quill.getContents();

          console.log('text-change', {
            content: content
          });

          this.$emit('input', this._content);
          this.$emit('change', { html, text, content });             

        } catch (error) {
          
        }
      });

      // emit ready event //
      this.$emit('ready', this.quill);
    },
    updateFromTemplate(data) {
      //Yes, the function name below is scary. 
      //TODO we should make sure we are sanitizing our HTML here
 
      const mergeInPlace = (a, b, i=0) => a.splice(i, 0, ...b);
      let rangeIndex = this.quill.selection.savedRange.index ? this.quill.selection.savedRange.index : 0;

      if (data.quillContent) {
        let Delta = Quill.import('delta');
        let delta = this.quill.getContents();
        delta = delta.ops;     
        let templateQuillContent = data.quillContent;        

        //remove Trello links here, we do NOT support importing Trello-synced task nodes
        //TODO this for loop is probably deprecated, as we do support merging task noods from Templates now
        //review this and deprecate
        for (let i = 0; i < templateQuillContent.length; i++) {
          if (templateQuillContent[i].insert && templateQuillContent[i].insert.taskBlot && templateQuillContent[i].insert.taskBlot.taskIsSynced) {
            templateQuillContent[i].insert.taskBlot.taskIsSynced = "false";
          }
          if (templateQuillContent[i].insert && templateQuillContent[i].insert.taskBlot && templateQuillContent[i].insert.taskBlot.trelloCard) {
            templateQuillContent[i].insert.taskBlot.trelloCard = null;
          }        }

        mergeInPlace(delta, templateQuillContent, (delta.length-1));     
        let finalDelta = new Delta(delta);
        this.quill.setContents(finalDelta);
      } else {
        this.quill.clipboard.dangerouslyPasteHTML(rangeIndex, data.body, "user");
      }
      
    },
    updateHtml(html) {
      console.log('updateHtml', html);
      this.quill.clipboard.dangerouslyPasteHTML(html);
    },
  },
  mounted() {
    this.initialize();
  },
  updated() {
    // update model if text changes //
    var change = new Delta();
    
    // autosave implementation //
    const quill = this.quill;
    const documentId = this.documentId;
    setInterval(function() {
      if (change.length() > 0) {
        console.log('Saving changes', change);
        let contentsJSON = quill.getContents();
        saveMeetingNotes(documentId, contentsJSON.ops).then(() => {
          console.log('Saved Notes');
        })
        change = new Delta();
      }
    }, 30*1000);
  },
  beforeUnmount() {
    this.quill = null
    delete this.quill
  },
  props: {
    content: String,    
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
}
</script>
<style src="../../../../public/css/quill-styling.css"></style>
<style>
  /* general editor styling */
  #editor {
    border: none;
    font-size: .9rem;
  }
  .ql-editor {
    white-space: inherit;
    font-weight: 200;
    line-height: 1.6rem;
    padding: 1.5rem 2rem;
  }
  .ql-editor.ql-blank::before {
    left: 2rem;
    right: 2rem;
  }
  /* header styling */
  .ql-editor h2 {
    font-family: 'GT-Eesti','sans-serif';
    font-weight: 200
  }
  .ql-editor h3 {
    font-family: 'GT-Eesti','sans-serif';
    font-weight: 200
  }
  /* toolbar styling */
  .ql-toolbar.ql-snow {
    border-top: 1px solid #f4f6f8 !important;
    background-color: #ffffff !important;
    position: absolute;
    top: 128px;
    height: 64px;
    z-index: 10;
    width: 90%;
    padding: 6px;
  }
  /* mention styling */
  .mention {
    background-color: #EBEEFF;
    color: #1f3fff;
    border: 1px solid #EBEEFF;
    border-radius: 100px;
    margin-right: 2px;
    padding: .5rem 1rem;
    font-weight: 600;
    font-size: 14px;
    user-select: all;
  }
  .ql-mention-list-container {
    padding: 1rem;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .ql-mention-list {
    background-color:#ffffff;
  }
  .ql-mention-list-item {
    padding: 0 2rem;
    background-color: #ffffff;
    cursor: pointer;
    line-height: 44px;
    font-weight: 600;
    font-size: 14px;
    padding: 0px 10px;
    vertical-align: middle;
    text-align: left;
  }
  .ql-mention-list-item.selected {
    background-color: #EBEEFF;
    color: #1f3fff;
    border-radius: 5px;
    text-decoration: none;
    text-align: left;
    min-width: 10rem;
  }
  /* list styling */
  l-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1em;
  }
  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 2.25em;
  }
  .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 3.5em;
  }
  .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 4.75em;
  }
  .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
      padding-left: 6em;
  }
  .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
      padding-left: 7.25em;
  }
  .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
      padding-left: 8.5em;
  }
  .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
      padding-left: 9.75em;
  }
  .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
      padding-left: 11em;
  }
  /* task styling */
  .task-test[placeholder]:empty:before {
      content: attr(placeholder);
      color: #a1a9ae;
  }
  .ql-tooltip {
    left: 0 !important;
  } 
  /* input element styling */
  :focus-visible {
    outline: none !important;
  }
  [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
  }
  .ql-clipboard {
   position: fixed;
  }
  .ql-editor .task-blot {
    position: relative;
  }
  .ql-editor .task-blot input {
    top: 1px !important;
    left: 54px !important;
  }
  #taskTitleInput{
    padding: 10px 10px 10px 6px;
  }
  #taskTitleInput:focus {
    border: 1px solid #1f3fff;
  }
</style>