/* meetings.store.js */
import axios from "axios";

// define variables from environment variables //
import { DOMAIN, PROCESS_PORT, PROTOCOL, API_DOMAIN } from "../../application/utilities/ServerUtilities";

// define initial state object //
const initialState = () => ({
  primaryCalendar: null,
  selectedMeeting: null,
  calendarEvents: null,
  pagedCalendarEvents: null, //TODO
  pagingToken: null,
  userMeetings: null,
  pagedUserMeetings: null, //TODO
  removedMeetings: null,
  meetingTemplates: null,
  personalMeetingTemplates: null,
  teamMeetingTemplates: null,
  searchMeetings: null,
  searchMeetingsTerm: null
});

// load state object //
const state = initialState();

// meetings getter functions //
const getters = {
  getPrimaryCalendar(state) {
    return state.primaryCalendar
  },
  getActiveMeeting(state) {
    return state.activeMeeting
  },
  getCalendarEvents(state) {
    return state.calendarEvents
  },
  getPagedCalendarEvents(state) {
    return state.pagedCalendarEvents
  },
  getPagingToken(state) {
    return state.pagingToken
  },
  getUserMeetings(state) {
    return state.userMeetings
  },
  getPagedUserMeetings(state) {
    return state.pagedUserMeetings
  },
  getRemovedMeetings(state) {
    return state.removedMeetings
  },  
  getMeetingTemplates(state) {
    return state.meetingTemplates
  },  
  getPersonalMeetingTemplates(state) {
    return state.personalMeetingTemplates
  },
  getTeamMeetingTemplates(state) {
    return state.teamMeetingTemplates
  }, 
  getSearchMeetings(state) {
    return state.searchMeetings
  },
  getSearchMeetingsTerm(state) {
    return state.searchMeetingsTerm
  }
}

// meetings actions // 
const actions = {
  logout: async ({commit}) => {
    commit('meetingsLogout'); 
  },
  /*emailExportMeetingNotes: ({commit, dispatch}, payload ) => {   
    if (payload.actionType == 'email') {
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios({
          method: 'post',
          url: `${PROTOCOL}${API_DOMAIN}/emailnotes`,
          data: payload
        }).then(response => {
            resolve(response); 
        }, error => {
            reject(error);
        })
      });
    } else if (payload.actionType == 'export') {
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios({
          method: 'post',
          url: `${PROTOCOL}${API_DOMAIN}/exportnotes`,
          data: payload,
          responseType: 'arraybuffer'
        }).then(response => {
            resolve(response); 
        }, error => {
            reject(error);
        })
      });
    }
  }*/
}
// meetings mutations //
const mutations = {
  storePrimaryCalendar (state, calendar) {
    state.primaryCalendar = calendar
  },
  storeSelectedMeeting (state, meeting) {
    state.selectedMeeting = meeting
  },
  storeCalendarEvents (state, events) {
    state.calendarEvents = events
  },
  storePagedCalendarEvents (state, events) {
    state.pagedCalendarEvents = events
  },
  storePagingToken (state, pagingToken) {
    state.pagingToken = pagingToken
  },
  storeUserMeetings (state, meetings) {
    state.userMeetings = meetings
  },
  storePagedUserMeetings (state, meetings) {
    state.pagedUserMeetings = meetings
  },
  storeRemovedMeetings (state, meetings) {
    state.removedMeetings = meetings
  },  
  storeMeetingTemplates (state, meetingTemplates) {
    state.meetingTemplates = meetingTemplates
  }, 
  storePersonalMeetingTemplates (state, personalMeetingTemplates) {
    state.personalMeetingTemplates = personalMeetingTemplates
  },
  storeTeamMeetingTemplates (state, teamMeetingTemplates) {
    state.teamMeetingTemplates = teamMeetingTemplates
  },   
  storeSearchMeetings (state, searchMeetings) {
    state.searchMeetings = searchMeetings
  }, 
  storeSearchMeetingsTerm (state, searchMeetingsTerm) {
    state.searchMeetingsTerm = searchMeetingsTerm
  }, 
  meetingsLogout: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}