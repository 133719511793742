/* application.store.js */
import axios from "axios";

// define variables from environment variables //
import { DOMAIN, PROCESS_PORT, PROTOCOL, API_DOMAIN } from "../../application/utilities/ServerUtilities";

// initial state object //
const initialState = () => ({
  integrations: {},
  ui: {
    sidebar: {
      darkMode: false,
      expanded: false
    }
  }
});

// load initial state //
const state = initialState();

// application getter functions //
const getters = {
  
}

// application actions // 
const actions = {
  // function for emailing and exporting meeting notes //
  emailExportMeetingNotes: ({commit, dispatch}, payload ) => {   
    if (payload.actionType == 'email') {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${PROTOCOL}${API_DOMAIN}/emailnotes`,
          data: payload
        }).then(response => {
            resolve(response); 
        }, error => {
            reject(error);
        })
      });


    } else if (payload.actionType == 'export') {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${PROTOCOL}${API_DOMAIN}/exportnotes`,
          data: payload,
          responseType: 'arraybuffer'
        }).then(response => {
          resolve(response); 
        }, error => {
          reject(error);
        })
      });
    }
  }
  // ** END APP FUNCTIONS ** //
}

// application mutations //
const mutations = {
  // function that clears out application state on logout //
  applicationLogout (state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}