// import core libraries //
import Quill from 'quill';

// for assigning a uuid to the task nodes //
import { v4 as uuidv4 } from 'uuid';

// alert popup, specifically for deleting task nodes //
import Swal from 'sweetalert2';

// import store so we can have access to store actions and getters //
import { store } from '../../../../../store/index.js';

// core function for creating new tasks //
import { createNewTask } from '../../../../utilities/TaskUtilities.js';

// import the task contents template //
import { taskNode } from './task-contents.js';

// import quill blot type //
let BlockEmbed = Quill.import('blots/block/embed');
let ContainerBlot = Quill.import('blots/container');
let Inline = Quill.import('blots/inline');
let TextBlot = Quill.import('blots/text');

// define variable for the contents of the task //
const taskContent = taskNode;

class TaskBlot extends BlockEmbed {
  static create(value) {

    console.log('static create: ', value);

    let node = super.create();
    let currentTitle = '';
    let isSyncingWithAPI = false;

    console.log('created node',node);

    // sets the node content to the task html in the variable //
    node.innerHTML = taskContent;

    // define state of menus and flags //
    node.setAttribute('contenteditable', false);
    let syncMenuActive = false;
    let canBeSynced = true;

    // assign variables to associated task node elements to make it easy to use them //
    let syncNode = node.querySelector('.sync-element');
    let syncMenu = node.querySelector('.sync-menu');
    let checkBoxNode = node.querySelector('.checkmark');
    let taskTitleNode = node.querySelector('.task-title');
    let deleteTaskNode = node.querySelector('.delete-task');
    let linkNode = node.querySelector('.link-button');   

    console.log('created delete node', deleteTaskNode);

    // set task title to new task if one does not already exist for it //
    // when loading existing notes, a previously created task will already have a title //
    if (!value.title) {
      node.setAttribute('title', "New Task");
      value.title = "New Task";
      currentTitle = "New Task";
    } else {
      node.setAttribute('title', value.title);
      currentTitle = value.title;
    }

    // populates the task text with the title if it is available //
    if (node.title) taskTitleNode.value = node.title;

    // set uuid of task if one does not already exist //
    // when loading existing notes, a previously created task will already have a uuid //
    if (!value.uuid) {
      const uuid = uuidv4();
      node.setAttribute('uuid', uuid);
      value.uuid = uuid;
      taskNodeCreateNew(uuid);
    } else {
      node.setAttribute('uuid', value.uuid);
    }



    if (value.trelloCard) {
      node.setAttribute('trelloCard',value.trelloCard);
      let userData = store.getters['authentication/getUser'];
      let token = (userData.integrations.trello && userData.integrations.trello.token) ? userData.integrations.trello.token : null;

      if (token) {
    
      isSyncingWithAPI = true;
      node.style.pointerEvents = "none";

      store.dispatch("integrations/getTrelloCard", {token:token,cardId:value.trelloCard}).then(response => {

        //console.log(response);

        //set the node to pointer-events none, change the style, show an animation        
        if (response.status == 200) {
          node.style.pointerEvents = "auto";
          if (response.data) {
            taskTitleNode.value = response.data.name;
            value.title = response.data.name;
            node.setAttribute('title', response.data.name);

            let cardUrl = response.data.url;
            let linkElement = linkNode.querySelector('a');
            linkElement.classList.remove("hidden");
            linkElement.href = cardUrl;
            linkElement.setAttribute('target', '_blank');

            checkBoxNode.checked = response.data.dueComplete; 
            isSyncingWithAPI = false;
          }
          //once card data is pulled in, set the title and dueComplete status from the card
        } else {
          node.style.pointerEvents = "auto";
          isSyncingWithAPI = false;
          //failure, do nothing
          //TODO error handling
        }       
      
      }).catch(error => {
        node.style.pointerEvents = "auto";
      });
      
      }

    }

    //TODO
    // define task related other attributes //
    node.setAttribute('completed', false);
    node.setAttribute('completedAt', null);
    node.setAttribute('createdAt', null);
    node.setAttribute('dueAt', null);

    // apply style and attribute updates if task is already synced to provider //
    // if already synced, we don't want the title to be able to be edited again //

    //TODO we need to allow editability here
    if (value.taskIsSynced == 'true') {
      let taskIsSyncedIcon = node.querySelector('.taskIsSyncedIcon');
      let taskTitleInput = node.querySelector('.task-title');
      node.setAttribute('taskIsSynced', true);
      value.taskIsSynced = true;
      ////node.setAttribute('titleIsEditable', false);
      //value.titleIsEditable = false;
      taskIsSyncedIcon.classList.remove('hidden');
      //taskTitleInput.setAttribute("contenteditable", "false");
      taskTitleInput.classList.remove('text-dark');
      taskTitleInput.classList.add('text-greysix'); 
      canBeSynced = false;
      syncMenuActive = false;
      syncMenu.classList.add('hidden');
    } else {
      node.setAttribute('taskIsSynced', false);
      value.taskIsSynced = false;
      ////node.setAttribute('titleIsEditable', true);
      ////value.titleIsEditable = true;
    }

    // gets called whenever the task checkbox is toggled //
    checkBoxNode.onclick = function(e) {
      //console.log('Task is Complete: ', e.target.checked);
      let userData = store.getters['authentication/getUser'];
      let token = userData.integrations.trello.token;

      let data = {dueComplete: e.target.checked};
      if (value.trelloCard) {
        store.dispatch("integrations/updateTrelloCard", {token:token,cardId:value.trelloCard, data: data}).then(response => {
          //TODO error handling
        });
      }     

    }





    /*
    taskTitleNode.addEventListener("focus", function(e) {

      //TODO
      //handle based on initiator

      node.querySelector('.inner-task-wrapper').classList.add('bg-sky-50');
      taskTitleNode.classList.add('editing-title-node');
      if (taskTitleNode.value == "New Task") { 
        taskTitleNode.value = "New Task "; 
        setTimeout(function () { taskTitleNode.select(); }, 100);
      }
    });

    taskTitleNode.addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission if inside a form
            taskTitleNode.blur();

        }
    });

    // Listener for input losing focus
    
      taskTitleNode.addEventListener('blur', function(e) { 

        //TODO
        //handle based on initiator

        //console.log('blurred task title, try to refocus');
        
        //e.target.focus();

        

        node.setAttribute('title', taskTitleNode.value); 
        node.style.pointerEvents = 'auto';

        node.closest('.template-editor-modal-body')?.querySelector('.ql-editor').classList.remove('editing');

        let userData = store.getters['authentication/getUser'];
        let token = userData.integrations.trello.token;

        let data = {name: taskTitleNode.value};
        if (value.trelloCard) {
          store.dispatch("integrations/updateTrelloCard", {token:token,cardId:value.trelloCard, data: data}).then(response => {
            //TODO error handling
          });
        } else {
          //task is not synced to Trello
        } 
      });
    

    

    if (value.instantiator && value.instantiator == store.getters['authentication/getUser'].uid) {

      //TODO
      //handle based on initiator


      console.log('instantiator',value.instantiator);
      node.setAttribute('instantiator',value.instantiator);
      if (taskTitleNode.value == "New Task") {  
        //setTimeout(function () { taskTitleNode.select(); }, 100);
          setTimeout(function () {taskTitleNode.focus();}, 100);
          console.log('focus');     
      } 
    }

    */


    taskTitleNode.addEventListener("focus", function(e) { 

      console.log('on focus');
      //disable the node while text input is happening
      node.style.pointerEvents = 'none';

      console.log(taskTitleNode.offsetWidth);

      //TODO
      let proxyInput = document.createElement('input');
      proxyInput.style.width = taskTitleNode.offsetWidth + 'px';
      proxyInput.style.position = 'absolute'; 
      proxyInput.style.border = '0';   
      proxyInput.style.fontSize = '14px';       
      proxyInput.type = "text";     
      proxyInput.value = taskTitleNode.value;

      proxyInput.style.top = node.offsetTop + 'px';
      proxyInput.style.left = node.offsetLeft + 52 + 'px';

      document.querySelector('.notes-editor-content .ql-container').appendChild(proxyInput);

      if (proxyInput.value == 'New Task') {
        proxyInput.focus();
        setTimeout(function () { proxyInput.select(); }, 100);
        node.querySelector('.inner-task-wrapper').classList.add('bg-sky-50');
      } else {
        proxyInput.focus();
        node.querySelector('.inner-task-wrapper').classList.add('bg-sky-50');
      }

      //mutation observers on the node don't seem to work
      //but an interval can help keep the height in place
      let mutInterval = setInterval(observeHeightAndSet, 25); // 2000 ms = start after 2sec 
      function observeHeightAndSet() {
        if (proxyInput.style.top !== node.offsetTop) {
          proxyInput.style.top = node.offsetTop + 'px';   
        }
  
      }

     

      //on Enter, the proxy will set the input and remove itself
      proxyInput.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
          // code for enter
          taskTitleNode.value = proxyInput.value;
          node.setAttribute('title', proxyInput.value); 
          node.style.pointerEvents = 'auto';

          clearInterval(mutInterval);
          try {
            proxyInput.remove();
          } catch (e) {

          }

          //proxySave.remove();
          node.querySelector('.inner-task-wrapper').classList.remove('bg-sky-50');          


          let userData = store.getters['authentication/getUser'];
          let token = userData.integrations.trello.token;

          let data = {name: taskTitleNode.value};
          if (value.trelloCard) {
            store.dispatch("integrations/updateTrelloCard", {token:token,cardId:value.trelloCard, data: data}).then(response => {
              //TODO error handling
            });
          }  
        }
      });

      //on blur, the proxy will set the input and remove itself
      proxyInput.addEventListener("blur", function(e) {    
        
          taskTitleNode.value = proxyInput.value;
          node.setAttribute('title', proxyInput.value); 
          node.style.pointerEvents = 'auto';

          clearInterval(mutInterval);
          try {
            proxyInput.remove();
          } catch (e) {

          }

          //proxySave.remove();
          node.querySelector('.inner-task-wrapper').classList.remove('bg-sky-50');          


          let userData = store.getters['authentication/getUser'];
          let token = userData.integrations.trello.token;

          let data = {name: taskTitleNode.value};
          if (value.trelloCard) {
            store.dispatch("integrations/updateTrelloCard", {token:token,cardId:value.trelloCard, data: data}).then(response => {
              //TODO error handling
            });
          }  
          
      });

    }, false);


    if (value.instantiator && value.instantiator == store.getters['authentication/getUser'].uid) {
      if (taskTitleNode.value == "New Task") {  
        //setTimeout(function () { taskTitleNode.select(); }, 100);
        setTimeout(function () {taskTitleNode.focus();}, 100);
        console.log('focus');
      }
    }

   

    // function and associated logic to delete the node when the delete button is clicked //
    deleteTaskNode.onclick = function(e) {
      Swal.fire({
        title: 'Delete task?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1f3fff',
        cancelButtonColor: '#999999',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          node.remove();
          Swal.fire(
            'Deleted!',
            'Your task has been deleted.',
            'success'
          )
        }
      });
    }

    // * SYNC TASK * //
    // define trello sync dropdown elements
    let boardSelect = node.querySelector('.trelloBoardsSelect');
    let listSelect = node.querySelector('.trelloListsSelect');
    let trelloSyncButton = node.querySelector('.trelloSyncButton');

    // triggered when the sync button is clicked //
    syncNode.onclick = function(e) {

      // hide sync menu if it is already open //
      if (syncMenuActive && canBeSynced) {
        syncMenuActive = false;
        syncMenu.classList.add('hidden');
        return
      } else if (!syncMenuActive && canBeSynced) {
        syncMenuActive = true;
        syncMenu.classList.remove('hidden');
      }

      // NOT IN USE create an object containing the values at the time of click //
      /*let currentValue = {
        completed: node.getAttribute('completed', false),
        completedAt: node.getAttribute('completedAt', null),
        createdAt: node.getAttribute('createdAt', null),
        dueAt: node.getAttribute('dueAt', null),
        taskIsSynced: node.getAttribute('taskIsSynced', false),
        title: node.getAttribute('title'),
        titleIsEditable: node.getAttribute('titleIsEditable', true),
        uuid: node.getAttribute('uuid')        
      }*/

      //delete all existing dropdown options for the board select //
      removeAllChildNodes(boardSelect);
      removeAllChildNodes(listSelect);

      // populate boards dropdown options from store //
      let data = store.getters['integrations/getTrelloData'];
      if (!data) return;

      let d_opt = document.createElement('option');
      d_opt.value = "";
      d_opt.innerText = "Select a board...";
      boardSelect.appendChild(d_opt);

      data.boards.forEach(function(board) {
        let opt = document.createElement('option');
        opt.value = board.id;
        opt.innerText = board.name
        if (!boardSelect) return;
        if (board.status == 'closed') return;
        console.log('appending: '+opt);
        boardSelect.appendChild(opt);
      });

      // populate lists dropdown based on current selection //
      //This is changing. We will query lists on Board select
      /*let existingBoard = boardSelect.value;
      let trelloListOptions = [];
      let result = data.lists.find(lists => {
        return lists.board === existingBoard
      });
      trelloListOptions = result.lists;
      trelloListOptions.forEach(function(list) {
        let opt = document.createElement('option');
        opt.value = list.id;
        opt.innerText = list.name;
        listSelect.appendChild(opt);
      });*/


    }

    // update list options when the value of the board changes //
    boardSelect.onchange = function() {
      //let data = store.getters['integrations/getTrelloData'];
      removeAllChildNodes(listSelect);
      //let existingBoard = boardSelect.value;
      //let trelloListOptions = [];
      //let result = data.lists.find(lists => {
        //return lists.board === existingBoard
      //});
      //trelloListOptions = result.lists;
      
      if (boardSelect.value) {
        let userData = store.getters['authentication/getUser'];
        let token = userData.integrations.trello.token;
        store.dispatch("integrations/getTrelloBoardListData", {token:token,boardId:boardSelect.value}).then(response => {
          console.log(response);
          let data = response.data ? response.data : [];
          data.forEach(function(list) {
            let opt = document.createElement('option');
            opt.value = list.id;
            opt.innerText = list.name;
            listSelect.appendChild(opt);
          });
        });
      }
    }

    // sync task to trello //
    trelloSyncButton.onclick = function(e) {
      this.syncWithTrelloLoading = true;
      let selectedBoard = boardSelect.value;
      let selectedList = listSelect.value;
      let userData = store.getters['authentication/getUser'];
      let token = userData.integrations.trello.token;
      let currentLocation = window.location.href;
      let newCardPayload = {
        token: token,
        boardId: selectedBoard,
        listId: selectedList,
        newCard: {
          name: node.getAttribute('title'),
          desc: `This card was created from Holepunch: ${currentLocation}`,
          pos: "top",
          due: ""
        }
      }
      store.dispatch("integrations/createNewTrelloCard", newCardPayload).then(response => {
        let taskIsSyncedIcon = node.querySelector('.taskIsSyncedIcon');
        let taskTitleInput = node.querySelector('.task-title');
        console.log(response);
        if (response.status == 200) {
          node.setAttribute('taskIsSynced', true);
          node.setAttribute('trelloCard', response.data.id);

          //get url
          let cardUrl = response.data.url;
          let linkElement = linkNode.querySelector('a');
          linkElement.classList.remove("hidden");
          linkElement.href = cardUrl;
          linkElement.setAttribute('target', '_blank');
          


          value.trelloCard = response.data.id;
          value.taskIsSynced = true;
          //node.setAttribute('titleIsEditable', false);
          //value.titleIsEditable = false;
          taskIsSyncedIcon.classList.remove('hidden');
          //taskTitleInput.setAttribute("contenteditable", "false");
          taskTitleInput.classList.remove('text-dark');
          taskTitleInput.classList.add('text-greysix');
          canBeSynced = false;
          syncMenuActive = false;
          syncMenu.classList.add('hidden');
          Swal.fire({
            toast: true,
            icon: 'success',
            title: 'Synced Task to Trello',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
          });
        } else {
          Swal.fire({
            toast: true,
            icon: 'error',
            title: 'Something went wrong!',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
          });
        }
      });
    }
    // *** END SYNC TASK *** //

    // return the task node //
    return node;
  }

  // define the task node to be considered as a single element with a length of one //
  length() {
    return 1
  }

  // function to run when task node is deleted //
  // return false makes it so that the task node will not be deleted with backspaces //
  deleteAt(index, length) {
    super.deleteAt(index, length);
    this.cache = {};
    // return false;
  }

  /*update(mutations, context) {
    console.log(mutations, context);
  }*/

  // establish the initial values of the task node for each attribute //
  static value(node) {
    return {
      completed: node.getAttribute('completed'),
      completedAt: node.getAttribute('completedAt'),
      createdAt: node.getAttribute('createdAt'),
      dueAt: node.getAttribute('dueAt'),
      taskIsSynced: node.getAttribute('taskIsSynced'),
      title: node.getAttribute('title'),
      //titleIsEditable: node.getAttribute('titleIsEditable'),
      uuid: node.getAttribute('uuid'),
      trelloCard: node.getAttribute('trelloCard'),
      instantiator: node.getAttribute('instantiator')
    }
  }

  static formats(node) {
    return { instantiator: node.getAttribute('instantiator') };
  }

 

  

}



// // function that gets fired when a new task is created //
function taskNodeCreateNew(taskId) {
  const UserId = store.getters['authentication/getUser'];
  return createNewTask(UserId, taskId);
}

// function to remove all children from a given node //
// specifically used to remove trello board and list dropdown options //
function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

TaskBlot.blotName = 'taskBlot';
TaskBlot.tagName = 'taskBlot';
TaskBlot.className = 'task-blot';

export default TaskBlot;