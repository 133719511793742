const ENV = process.env.NODE_ENV;
const USE_TEST = process.env.VUE_APP_USE_TEST_FIREBASE;
const DEV_DOMAIN = process.env.VUE_APP_DEV_DOMAIN;
const PROD_DOMAIN = process.env.VUE_APP_PROD_DOMAIN;

const DEV_APPLICATION_PORT = process.env.VUE_APP_DEV_APPLICATION_SERVER_PORT;
const PROD_APPLICATION_PORT = process.env.VUE_APP_PROD_APPLICATION_SERVER_PORT;
const DEV_PROCESS_PORT = process.env.VUE_APP_DEV_PROCESS_SERVER_PORT;
const PROD_PROCESS_PORT = process.env.VUE_APP_PROD_PROCESS_SERVER_PORT;
const DEV_WEBSOCKET_PORT = process.env.VUE_APP_DEV_WEBSOCKET_SERVER_PORT;
const DEV_WEBSOCKET_PROTOCOL = process.env.VUE_APP_DEV_WEBSOCKET_PROTOCOL;
const PROD_WEBSOCKET_PORT = process.env.VUE_APP_PROD_WEBSOCKET_SERVER_PORT;
const PROD_WEBSOCKET_PROTOCOL = process.env.VUE_APP_PROD_WEBSOCKET_PROTOCOL;

const DEV_CLIENT_ID = process.env.VUE_APP_DEV_GOOGLE_CLIENT_ID;
const PROD_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;


//TODO
//Algolia dev

const PROD_ALGOLIA_APP_ID = process.env.VUE_APP_ALGOLIA_APP_ID;
const PROD_ALGOLIA_ADMIN_API_KEY = process.env.VUE_APP_ALGOLIA_ADMIN_API_KEY;

const PROD_FULL_DOMAIN = process.env.VUE_APP_PROD_APPLICATION_DOMAIN;
const PROD_API_DOMAIN = process.env.VUE_APP_PROD_PROCESS_DOMAIN;

export let PROTOCOL;
export let DOMAIN;
export let FULL_DOMAIN;
export let API_DOMAIN;
export let APPLICATION_PORT;
export let PROCESS_PORT;
export let WEBSOCKET_DOMAIN;
export let WEBSOCKET_PORT;
export let WEBSOCKET_PROTOCOL;
export let ALGOLIA_APP_ID;
export let ALGOLIA_ADMIN_API_KEY;
export let CLIENT_ID;

//console.log(USE_TEST);

if (USE_TEST == 'true') {
  DOMAIN = DEV_DOMAIN;
  PROTOCOL = `http://`;
  FULL_DOMAIN = `localhost:8080`;
  API_DOMAIN = `localhost:3000`;
  WEBSOCKET_DOMAIN = `localhost:8010`;
  APPLICATION_PORT = DEV_APPLICATION_PORT;
  PROCESS_PORT = DEV_PROCESS_PORT;
  WEBSOCKET_PORT = DEV_WEBSOCKET_PORT;
  WEBSOCKET_PROTOCOL = DEV_WEBSOCKET_PROTOCOL;
  ALGOLIA_APP_ID = null;
  ALGOLIA_ADMIN_API_KEY = null;
  CLIENT_ID = DEV_CLIENT_ID;
} else if (ENV == 'development') {
  DOMAIN = DEV_DOMAIN;
  PROTOCOL = `http://`;
  FULL_DOMAIN = `localhost:8080`;
  API_DOMAIN = `localhost:3000`;
  WEBSOCKET_DOMAIN = `localhost:8010`;
  APPLICATION_PORT = DEV_APPLICATION_PORT;
  PROCESS_PORT = DEV_PROCESS_PORT;
  WEBSOCKET_PORT = DEV_WEBSOCKET_PORT;
  WEBSOCKET_PROTOCOL = DEV_WEBSOCKET_PROTOCOL;
  ALGOLIA_APP_ID = PROD_ALGOLIA_APP_ID;
  ALGOLIA_ADMIN_API_KEY = PROD_ALGOLIA_ADMIN_API_KEY;
  CLIENT_ID = PROD_CLIENT_ID;
} else if (ENV == 'production') {
  PROTOCOL = `https://`;
  DOMAIN = PROD_DOMAIN;
  FULL_DOMAIN = PROD_FULL_DOMAIN;
  API_DOMAIN = PROD_API_DOMAIN;
  WEBSOCKET_DOMAIN = PROD_FULL_DOMAIN+`/ws`;
  APPLICATION_PORT = PROD_APPLICATION_PORT;
  PROCESS_PORT = PROD_PROCESS_PORT;
  WEBSOCKET_PORT = PROD_WEBSOCKET_PORT;
  WEBSOCKET_PROTOCOL = PROD_WEBSOCKET_PROTOCOL;
  ALGOLIA_APP_ID = PROD_ALGOLIA_APP_ID;
  ALGOLIA_ADMIN_API_KEY = PROD_ALGOLIA_ADMIN_API_KEY;
  CLIENT_ID = PROD_CLIENT_ID;
};
//console.log(CLIENT_ID);
