/* calendars.store.js */

// initial state object //
const initialState = () => ({
  availableCalendars: null,
  primaryCalendar: null
});

// calendars state object //
const state = initialState();

// calendars getter functions //
const getters = {
  getAvailableCalendars(state) {
    return state.availableCalendars;
  },
  getPrimaryCalendar(state) {
    return state.primaryCalendar;
  }
}

// calendars actions // 
const actions = {
  logout: async ({commit}) => {
    commit('calendarsLogout'); 
  },
}

// calendars mutations //
const mutations = {

  // *** GENERAL *** //
  storeAvailableCalendars (state, calendars) {
    state.availableCalendars = calendars;
  },
  storePrimaryCalendar (state, calendar) {
    state.primaryCalendar = calendar;
  },
  calendarsLogout (state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
          state[key] = newState[key]
    });
  },
  // *** END GENERAL *** //
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}