// import packages and libraries //
import { createApp } from "vue";
//import VueGapi from 'vue-gapi'
import App from "./App.vue";
import router from "./router.js";
import { store } from "./store/index.js";
import firebase from 'firebase/app';
import VueLoading from 'vue-loading-overlay';
import Popper from "vue3-popper";
import 'vue-loading-overlay/dist/vue-loading.css';
import "firebase/analytics";
import "firebase/firestore";

// import primary CSS files //
import '../public/css/tailwind.css';
import '../public/css/global.css';

import './registerServiceWorker'

// initialize application //
const app = createApp(App);
const ENV = process.env.NODE_ENV;
const USE_APP = process.env.VUE_APP_USE_TEST_FIREBASE;

// firebase configuration from environment variables and initialization //
if (USE_APP == 'true') {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_DEV_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_DEV_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_DEV_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_DEV_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_DEV_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_DEV_FIREBASE_MEASUREMENT_ID
  };
} else if (ENV == 'development') {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  };
} else if (ENV == 'production') {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  };
}
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// initialize gapi //
// TODO this is deprecated, remove //
/*
if (ENV == 'test') {
  //THIS DOESNT WORK
  //IT IS A NEWER CLIENT AND VueGapi doesn't work yet with new clients
  console.log({
    apiKey: process.env.VUE_APP_DEV_GOOGLE_API_KEY,
    clientId: process.env.VUE_APP_DEV_GOOGLE_CLIENT_ID,
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    scope: process.env.VUE_APP_GOOGLE_SCOPES,
  });
  app.use(VueGapi, {
    apiKey: process.env.VUE_APP_DEV_GOOGLE_API_KEY,
    clientId: process.env.VUE_APP_DEV_GOOGLE_CLIENT_ID,
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    scope: process.env.VUE_APP_GOOGLE_SCOPES,
  });
} else if (ENV == 'development') {
  app.use(VueGapi, {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    scope: process.env.VUE_APP_GOOGLE_SCOPES,
  });
} else if (ENV == 'production') {
  app.use(VueGapi, {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    scope: process.env.VUE_APP_GOOGLE_SCOPES,
  });
}
*/

// vue app declarations and init //

app.use(store);
app.use(router);

app.use(VueLoading);
app.component("Popper", Popper);
app.mount('#app');