import { store } from "../../store/index.js";
import router from "../../router.js";

const logout = () => {
  console.log('logout');
  store.dispatch('meetings/logout');
  store.dispatch('calendars/logout');
  store.dispatch('integrations/logout');
  store.dispatch('authentication/logout').then(() => {
    return router.push('/auth/login');
  });
}
    
export {
    logout,
}