<template>
  <div class="relative min-h-screen w-full flex ">
    <div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
      <div class="relative sm:w-1/2 xl:w-3/5 h-full md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-primary text-white bg-fit relative"
        style="background-image: url(https://firebasestorage.googleapis.com/v0/b/alpine-realm-314501.appspot.com/o/img%2Fbackgrounds%2Fpattern-randomized.svg?alt=media&token=9763368a-8517-464e-8ce3-1c3f1423d828);">
        <div class="w-full lg:max-w-2xl md:max-w-md z-10">
          <div class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full shadow-md py-8 sm:rounded-lg md:rounded-none bg-white">
            <div class="max-w-xl w-full space-y-8">
              <div class="text-center">
                <h2 class="text-4xl font-thin font-gteesti text-dark">
                  Welcome Back! {{userIsLoggedIn}}
                </h2>
                <p v-show="!this.loggedIn" class="mt-2 text-sm text-dark">Please sign in to your Holepunch account.</p>
                <p v-show="this.loggedIn" class="mt-2 text-md text-dark">You're already logged in! <router-link to="/" class="text-primary underline cursor-pointer">Click here</router-link> to access Holepunch, or you can <span class="text-primary underline cursor-pointer" @click="this.logout()">log out</span> of your account.</p>
              </div>
              <div>
              <button v-show="!this.loggedIn" @click="googleLogIn()" type="button" class="w-full block bg-white focus:bg-gray-100 transition ease-in duration-400 hover:shadow-md text-gray-900 font-semibold rounded-md px-4 py-3 border border-gray-300 focus:outline-none focus:ring-0 focus:ring-none focus:border-gray-300">
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-6 h-6" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
                  <span class="ml-4">Log in with Google</span>
                </div>
              </button>
              <div v-show="!this.loggedIn" class="flex items-center justify-center space-x-2 mt-5">
                <span class="h-px w-16 bg-gray-200"></span>
                <span class="text-gray-300 text-xs font-normal">or continue with</span>
                <span class="h-px w-16 bg-gray-200"></span>
              </div>
              <form v-show="!this.loggedIn" @submit.prevent="passwordLogIn()" class="mt-8 space-y-6">
                <div class="relative">
                  <label class="ml-1 text-xs font-bold text-gray-700 tracking-wide">Email</label>
                  <input
                    class="w-full text-dark px-4 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:ring-none focus:border-gray-200"
                    type="email" placeholder=""  v-model="email">
                </div>
                <div class="mt-8 content-center">
                  <label class="ml-1 text-xs font-bold text-gray-700 tracking-wide">
                    Password
                  </label>
                  <input
                    class="w-full text-dark px-4 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:ring-none focus:border-gray-200"
                    type="password" placeholder=""  v-model="password">
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <input id="remember_me" name="remember_me" type="checkbox"
                      class="rounded bg-gray-200 border-transparent cursor-pointer focus:border-none focus:ring-0 focus:ring-none text-primaryone focus:ring-0 focus:ring-offset-0 focus:ring-white">
                    <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                      Remember me
                    </label>
                  </div>
                  <div class="text-sm">
                    <router-link to="/auth/forgotpassword" class="font-medium text-dark hover:text-primaryone">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
                <div>
                  <button type="submit" class="w-1/2 align-middle flex mx-auto justify-center bg-primaryonelight text-primaryone p-2 rounded-full tracking-wide font-semibold focus:outline-none focus:shadow-outline hover:bg-primarytwolight cursor-pointer transition ease-in duration-300">
                    Sign In
                  </button>
                </div>
                <p class="flex flex-col text-sm items-center justify-center mt-10 text-center text-md text-gray-500">
                  <span>Don't have an account?</span>
                  <router-link to="/auth/signup" class="text-primaryone underline text-xs hover:text-indigo-dark hover:underline cursor-pointer transition ease-in duration-300">Sign up</router-link>
                </p>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { PROTOCOL, API_DOMAIN, CLIENT_ID } from '../../application/utilities/ServerUtilities';


export default {
  name: "LoginPage",
  data: () => ({
    email: '',
    loggedIn: '',
    password: '',
    loader: null
  }),
  components: {

  },
  computed: {
    
  },
  methods: {
    async authCallBack(response) {
      console.log('authCallBack');      
      let url = `${PROTOCOL}${API_DOMAIN}/api/googleAuth/authorize`;
      const postEventData = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XmlHttpRequest'
        },
        body: JSON.stringify({
          code: response.code,
          //
        })
      }).then(function(response) {
        return response.json();
      }).then(function(data) { 
        return data;
      }).catch(function(err){
        console.log(err);
        return;
      });
      //return response;
      console.log("User Logged In: ", postEventData); 

      if (postEventData && postEventData.data && postEventData.data.id) {
        this.loggedIn = true;
      }

      this.$store.dispatch('authentication/googleLogIn', postEventData);      
      this.loader.hide();
    },
    async googleLogIn() {
      console.log('googleLogIn');
      this.loader = this.$loading.show({
        color: '#1f3fff',
        loader: 'spinner',
        width: 100,
        height: 100,
        backgroundColor: '#ffffff',
        opacity: .5,
        zIndex: 999,
      });

       console.log('trying OAuth client');
       console.log(google.accounts.oauth2);
       
       //check data
       console.log(CLIENT_ID,process.env.VUE_APP_GOOGLE_SCOPES,'popup');

       try {
        const client = google.accounts.oauth2.initCodeClient( {        
          client_id: CLIENT_ID,
          scope: process.env.VUE_APP_GOOGLE_SCOPES,
          ux_mode: 'popup',
          callback: this.authCallBack
        });
        client.requestCode();
      } catch(e) {
        console.log(e);
      }

    },
    passwordLogIn() {
      console.log('passwordLogIn');
      let loader = this.$loading.show({
        color: '#1f3fff',
        loader: 'spinner',
        width: 100,
        height: 100,
        backgroundColor: '#ffffff',
        opacity: .5,
        zIndex: 999,
      });
      
        this.$store.dispatch('authentication/passwordLogIn', {email: this.email, password: this.password});
        loader.hide();
    },
    logout() {
      this.$gapi.logout()
    }
  },
  mounted() {
    
  },
  async created() {
    /*await this.$gapi.getGapiClient().then(client => {
      let auth2 = client.auth2.getAuthInstance();

      console.log(client);

      auth2.isSignedIn.listen(isSignedIn => {
        console.log(isSignedIn)
        if (isSignedIn) {
          console.log('auth2 is signed in');
          const currentUser = auth2.currentUser.get()
          const authResponse = currentUser.getAuthResponse(true)
          console.log(authResponse)
          authResponse.id_token
          authResponse.access_token
        }
      })

    })

    this.$gapi.listenUserSignIn((loggedIn) => {
      this.loggedIn = loggedIn
    })*/

    ///TODO
    //get GIS client or init it
    const GSIplugin = document.createElement("script");
    GSIplugin.setAttribute(
      "src",
      "https://accounts.google.com/gsi/client"
    );
    GSIplugin.async = true;
    GSIplugin.defer = true;
    //console.log(GSIplugin);
    document.head.appendChild(GSIplugin);
  },
}
</script>