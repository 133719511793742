<template>
  <tr class="border-b border-gray-200 font-gtamerica bg-white rounded">
    <td class="py-4 px-2 text-left whitespace-nowrap">
      <div class="flex items-center font-normal">             
        <router-link :to="{ name: 'NotesEditor', params: { meeting: MeetingData.sourceId, document: MeetingData.id}}" class="text-dark font-semibold hover:underline">{{ MeetingData.name }}</router-link>
      </div>
    </td>
    <td class="py-3 px-2 text-left">
      <div class="flex items-center">
        <span>{{ meetingDate }}</span>
      </div>
    </td>
    <td class="py-3 px-2 text-left">
      <div class="flex items-center">
        <span>{{ this.startTime }} - {{ this.endTime }}</span>
      </div>
    </td>    
  </tr>
</template>

<script>
import { getMeetings } from '../utilities/CalendarUtilities.js';
import { getExistingUser } from "../utilities/UserUtilities.js";
import Swal from 'sweetalert2';

export default {
  name: 'HistoricalMeetingCard',
  data: () => ({
    meetingDocumentId: null,
    userDocumentId: null,
    hoverMenu: ''
  }),
  methods: {
    /*importMeeting: async function(meetingData) {
      console.log(meetingData);
      
      let importer = await importMeeting(meetingData, this.$store.getters["authentication/getUser"], this.$store.getters["meetings/getPrimaryCalendar"]).then(res => {
        if (res) {
          Swal.fire({
            toast: true,
            icon: 'success',
            title: 'Meeting successfully imported.',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
          }).then(res => {
            this.$emit("newMeetingImported");
          });
          this.meetingDocumentId = res;
        }
      }).then(res => {
        const userId = this.$store.getters["authentication/getUser"];
        getMeetings(userId.uid, this.$store.getters["meetings/getPrimaryCalendar"]).then(response => {
          console.log(response)
          this.$store.commit('meetings/storeUserMeetings', response);
          return this.isImported;
        }).then(res => {
          console.log(res);

          //redirect
          this.$router.push({ name: 'NotesEditor', params: { meeting: meetingData.id, document: this.meetingDocumentId}});
        })
      })
      
      return importer;
      
    },*/
    meetingAttendees(attendeesData) {
      if (!attendeesData) return;
      let attendees = [];
      for (let i = 0;i < attendeesData.length;i++) {
        attendees.push(attendeesData[i].email);
      }
      return attendees;
    },
    /*async hideMeeting(selectedEvent) {
      // define user and event specific variables //
      let user_details = this.$store.getters["authentication/getUser"];
      let userDocumentId = user_details.documentId;
      let userId = user_details.uid;
      
      let hideMeetingResponse = await hideMeeting(userDocumentId, selectedEvent.id).then(async (response) => {
        let userData = await getExistingUser(userId).then(users => {
          let user = users[0];
          this.$store.commit("authentication/storeUser", user);
          return user;
        })
        return userData;
      }).then(() => {
        this.$emit('hidden');
        Swal.fire({
          toast: true,
          icon: 'success',
          title: 'Meeting successfully hidden.',
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: false,
        });
      })
      .then(res => {
        this.$emit('calendarEventHidden');
      })
      return hideMeetingResponse;
    }*/
  },
  computed: {
    startTime: function() {
      var startTime = new Date(this.MeetingData.start.dateTime);
      var hours = startTime.getHours();
      var minutes = (startTime.getMinutes()<10?'0':'') + startTime.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      return hours + ':' + minutes + ' ' + ampm;
    },
    startTimeFull: function() {
      var startTime = new Date(this.MeetingData.start.dateTime);
      return startTime.toString();
    },
    endTime: function() {
      var endTime = new Date(this.MeetingData.end.dateTime);
      var hours = endTime.getHours();
      var minutes = (endTime.getMinutes()<10?'0':'') + endTime.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      return hours + ':' + minutes + ' ' + ampm;
    },
    /*isImported: function() {
      let meetingId = this.MeetingData.id;
      let userMeetings = this.$store.getters["meetings/getUserMeetings"];
      let isImported;
      if (!userMeetings) return;
      for (let i = 0; i < userMeetings.length; i++) {
        if (userMeetings[i].sourceId == meetingId ) {
          this.meetingDocumentId = userMeetings[i].documentId
          return true;
        }
      }
    },*/
    meetingDate: function() {
      var startDate = new Date(this.MeetingData.start.dateTime);

      var month = startDate.getMonth();
      var year = startDate.getFullYear();
      var date = startDate.getDate();

      var dateString = (month + 1) + '/' + (date) + '/' + year;
      return dateString;

    },
    documentIdValidated: function() {
      if (!this.meetingDocumentId) return 0;
      return this.meetingDocumentId;
    }
  },
  props: [
    'MeetingData'
  ]
}
</script>