<template>
  <div class="w-screen h-screen absolute z-50 bg-black bg-opacity-10 backdrop-filter backdrop-blur-sm -mt-16 -ml-20">
    <div class="min-h-screen overflow-y-auto w-full flex items-center justify-center">
      <div class="templates-modal w-full">
				<div class="shadow-lg flex flex-row w-full">
          <div class="px-4 py-5 bg-white rounded-tl-3xl w-full">
            <div class="text-4xl font-gteesti text-dark font-normal">
            	Templates
            </div>
            <p class="text-md text-dark font-light">
              Save your meeting as a template, or load in a template.
            </p>
          </div>
          <div class="px-4 py-2 relative bg-white rounded-tr-full" >
            <svg @click="closeTemplatesModal" class="-top-3 -right-3 w-10 h-10 p-2 absolute bg-white rounded-full cursor-pointer fill-current shadow-lg hover:shadow-md text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
        <div class="flex w-full bg-white">
            <div class="flex flex-row w-full items-center justify-between border-r border-l rounded-sm">
            <div class="flex flex-row items-center w-full">
                <div class="bg-white w-full">
                    <nav class="flex flex-col w-full sm:flex-row">
                        <button @click="this.templateMenu = 'Load'" :class="templateMenu == 'Load' ? 'text-primary hover:text-primary border-primary' : 'text-gray-300 hover:text-gray-300 border-gray-300'" class="w-full py-2 px-6 block focus:outline-none border-b-2 font-medium">
                            Load Template
                        </button>
                        <button @click="this.templateMenu = 'Save'" :class="templateMenu == 'Save' ? 'text-primary hover:text-primary border-primary' : 'text-gray-300 hover:text-gray-300 border-gray-300'" class="w-full py-2 px-6 block focus:outline-none border-b-2 font-medium">
                            Save Notes as Template
                        </button>                        
                    </nav>
                </div>
            </div>
            </div>
        </div>

            <div v-show="templateMenu == 'Load'">
                <div class="px-6 py-2 w-full bg-white"> 
                <p class="text-center z-50 p-1 opacity-100 text-sm font-light flex justify-between items-center">
                    <div>Load a Template.</div>
                     <router-link to="/templates?template=create" class="cursor-pointer px-4 py-2 text-white text-sm rounded-full font-light bg-primaryone right-0 top-10 mt-2 inline-block">            
                        Create New Template
                    </router-link>
                </p>
                </div>
                <nav class="flex w-full px-6 bg-white">
                        <button @click="loadTemplateMenu('Public')" :class="loadMenu == 'Public' ? 'text-primary hover:text-primary border-primary' : 'text-gray-300 hover:text-gray-300 border-gray-300'" class="py-2 px-6 block focus:outline-none mx-2 border-2 border-b-0 rounded-t-md font-medium">
                            Public
                        </button>
                        <button @click="loadTemplateMenu('Personal')" :class="loadMenu == 'Personal' ? 'text-primary hover:text-primary border-primary' : 'text-gray-300 hover:text-gray-300 border-gray-300'" class="py-2 px-6 block focus:outline-none mx-2 border-2 border-b-0 rounded-t-md font-medium">
                            Personal
                        </button>
                    </nav>
                <div class="px-6 py-2 w-full bg-white flex"> 
                    <div v-show="loadMenu == 'Public'" class="w-full">
                        <div class="template-element cursor-pointer px-2 py-2 border-1 w-full my-2 shadow-sm bg-gray-50" 
                            v-for="(template, index) in this.publicTemplates" 
                            :key="`template-${index}`"
                            @click="activate(index)" 
                            :class="{ 'active shadow-md' : active_el == index }">
                            <div class="flex justify-between">
                                <span>{{ template.name }}</span>
                                <span class="text-gray-400">Author: {{ template.creator.displayName }} {{ template.creator.email }}</span>
                                <span class="text-gray-400">Created: {{ getReadableDateTime(template.created) }}</span>
                                <button class="px-2 py-2 border mx-2 hide-element hover:bg-gray-50" @click="loadTemplate(index, 'public')">Load</button>
                            </div>
                            <div class="text-gray-400">
                                {{ template.description }}
                            </div>
                        </div>
                    </div>
                    <div v-show="loadMenu == 'Personal'" class="w-full">
                        <div class="template-element cursor-pointer px-2 py-2 border-1 w-full my-2 shadow-sm bg-gray-50" 
                            v-for="(personaltemplate, index) in this.personalTemplates" 
                            :key="`personaltemplate-${index}`"
                             @click="activate(index)" 
                            :class="{ 'active shadow-md' : active_el == index }">
                            <div class="flex justify-between">
                                <span>{{ personaltemplate.name }}</span>
                                <span class="text-gray-400">Author: {{ personaltemplate.creator.displayName }} {{ personaltemplate.creator.email }}</span>
                                <span class="text-gray-400">Created: {{ getReadableDateTime(personaltemplate.created) }}</span>
                                <button class="px-2 py-2 border mx-2 hide-element hover:bg-gray-50" @click="loadTemplate(index, 'personal')">Load</button>
                            </div>
                            <div class="text-gray-400 hide-element">
                                {{ personaltemplate.description }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-between px-6 py-3 bg-lightgrey border-gray-100 border-b border-r border-l rounded-bl-lg rounded-br-lg">
                
                </div>
            </div>


            <div v-show="templateMenu == 'Save'">
                <div class="w-full bg-white flex relative"> 
                <div class="email-summary px-2 py-2 w-1/2 sm:w-full bg-gray-100 ">
                <h2>Save your Template</h2>          
                    
                    <div class="email-options my-8">
                        <h4 class="text-sm mb-2 uppercase text-gray-400">Template Title</h4>  
                        <div>
                            <input class="w-full mb-6" type="text" id="email-options-public-notes" v-model="templateName">
                        </div>
                        <h4 class="text-sm mb-2 uppercase text-gray-400">Instructions for the Organizer</h4>  
                        <div>
                            <textarea class="w-full mb-6" id="email-options-public-notes" v-model="templateDescription"></textarea>
                        </div>
                        <h4 class="text-sm mb-2 uppercase text-gray-400">Options</h4>  
                        <div class="email-options-inner">   
                            
                            <div>
                                <input type="checkbox" id="email-options-public-notes" value="publicTemplate" v-model="publicTemplate">
                                <label for="email-options-public-notes"> Public Template</label> 
                            </div>
                        </div>
                    </div>
                    </div>

                
                <div class="email-preview px-2 py-2 w-1/2 sm:w-full bg-gray-100 flex flex-col">
                    <h4 class="text-sm uppercase text-gray-400">Preview</h4>
                    <div class="email-preview-wrapper flex-grow px-2 py-2 h-72 bg-white overflow-y-scroll">
                    <div v-html="stripUnwantedTagsForPreview(templateContent)"></div>                    
                    </div>
                </div>         
                </div>
                <div class="px-6 py-2 w-full bg-white flex"> 
                <p class="text-center z-50 p-1 opacity-100 text-sm font-light">
                </p>
                </div>
                <div class="modal-footer flex flex-row items-center justify-between px-6 py-3 bg-greyone border-gray-200 border-b border-r border-l rounded-bl-lg rounded-br-lg">
                <button @click="saveTemplate()" class="px-4 py-2 text-white hover:underline text-sm rounded-sm font-normal bg-primary bg-gray-400">
                    Save Template
                </button>
                </div>
            </div>





        </div>
      </div>
    </div>
</template>


<script>
import { OnClickOutside } from '@vueuse/components';
import { saveMeetingTemplate, getMeetingTemplates, getPersonalMeetingTemplates } from '../utilities/CalendarUtilities.js';

export default {
  name: 'TemplatesModal',
  props: ['editorContent','editorQuillContent'],
  emits: ['templateContentLoaded','CloseTemplatesModal'],
  data: () => ({    
    templateMenu: 'Load',
    loadMenu: 'Public',
    publicTemplate: false,   
    templateName: '', 
    templateDescription: '',
    publicTemplates: [],
    personalTemplates: [],
    templateContent: '',
    templateQuillContent: null,
    active_el: 0
  }),
  components: {
    OnClickOutside
  },
  mounted() {
    
 },
 async beforeMount() {
    this.publicTemplates = this.$store.getters["meetings/getMeetingTemplates"];              
    this.personalTemplates = this.$store.getters["meetings/getPersonalMeetingTemplates"]; 
    this.templateContent = this.stripUnwantedTags(this.editorContent);
    
    //console.log(this.publicTemplates);  
    //console.log(this.personalTemplates);  
 },
 methods: {
    closeTemplatesModal() {
      this.publicTemplate = false;
      this.templateName = ''; 
      this.templateDescription = '';
      this.$emit('CloseTemplatesModal');
    },
    getReadableDateTime(firestoreTimestamp) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        let mDate = new Date(firestoreTimestamp.seconds*1000).toLocaleDateString(undefined, options);
        let mTime = new Date(firestoreTimestamp.seconds*1000).toLocaleTimeString();
        return mDate+' '+mTime;         
    },    
    activate(el) {        
        this.active_el = el;
    },
    loadTemplateMenu(menu) {
        this.loadMenu = menu;
        this.active_el = 0;
    },
    loadTemplate(index,type) {
        let content = null;
        if (type == "public" && this.publicTemplates[index]) {
            content = {
                body: this.publicTemplates[index].body,
                quillContent: this.publicTemplates[index].quillContent
            };
        } else if (type == "personal" && this.personalTemplates[index]) {
            content = {
                body: this.personalTemplates[index].body,
                quillContent: this.personalTemplates[index].quillContent
            };
        }
        //console.log(content);
        if (content !== null) {            
            this.$emit('templateContentLoaded', content);
        }
        this.closeTemplatesModal();
    },
    stripUnwantedTags(string) {
        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');        

        elements.forEach(e => e.parentNode.removeChild(e));
        return div.innerHTML;
    },
    stripUnwantedTagsForPreview(string) {
        let div = document.createElement('div');
        div.innerHTML = string;

        var elements = div.querySelectorAll('.task-blot');    
        
        for (let i = 1;i<=20;i++) {
          //add inline styles for ql-indent-X
          let increment = 15;
          div.querySelectorAll('.ql-indent-'+i).forEach(el => {
              el.style.marginLeft = (increment * (i+1))+"px";
          });
        }

        elements.forEach(e => e.parentNode.removeChild(e));
        return div.innerHTML;
    },



    
    async saveTemplate() {
      const user_details = this.$store.getters["authentication/getUser"];
      //console.log('user_details');
      //console.log(user_details);

      let meetingTemplate = {
        name: this.templateName,        
        description: this.templateDescription,
        body: this.templateContent,
        public: this.publicTemplate,
        quillContent: this.editorQuillContent.ops
      };
     
      let result = await saveMeetingTemplate(meetingTemplate, user_details);    

        if (result !== false) {
            getMeetingTemplates(user_details.uid).then(response => {             
                this.$store.commit('meetings/storeMeetingTemplates', response);  
                this.publicTemplates = this.$store.getters["meetings/getMeetingTemplates"];              
            });            
            getPersonalMeetingTemplates(user_details.uid).then(response => {            
                this.$store.commit('meetings/storePersonalMeetingTemplates', response);  
                this.personalTemplates =  this.$store.getters["meetings/getPersonalMeetingTemplates"];        
            });            
        }
        
      
      this.closeTemplatesModal();
    },     
  },
  watch: { 
    editorContent: function(newVal, oldVal) { // watch it
        //remove html content we don't want
        this.templateContent = this.stripUnwantedTags(newVal);        
    },
    editorQuillContent: function(newVal, oldVal) {
        //console.log(newVal);     
        this.templateQuillContent = newVal;          
    }
  },
  computed: {
    userProfileDetails() {
      const user_details = this.$store.getters["authentication/getUser"];
      return user_details;
    }
  }
}
    
</script>

<style>
.templates-modal {
  max-width: 50%;
  max-height: 50%;
  height: calc(90vh);
}
.template-element .hide-element {
    display: none;
}
.template-element.active {
    background-color: #fff;
}
.template-element.active .hide-element {
    display: block;
}
</style>