<template>
  <page-header/>
  <div class="content-body my-5 px-5 font-gtamerica">
    <div class="">
      <div class="">
        <div class="w-full">
          <div class="historicalMeetingsSearch">
            <input type="text" class="my-5 historicalMeetingsSearchInput" placeholder="Search by keyword..." v-model="searchTerm" v-on:keyup.enter="searchMeetings" /> 
            <button class="px-4 py-2 ml-5 text-white hover:underline text-sm rounded-sm font-normal bg-primary bg-gray-400" @click="searchMeetings()">Find my meetings</button>
          </div>
          <div class="historicalMeetingsWrapper">
            <table id="historicalMeetingsTable" class="w-full table-auto">
              <thead class="">
                <tr class="text-gtamerica text-sm">
                  <th class="py-3 px-2 text-left font-medium text-dark">Meeting</th>
                  <th class="py-3 px-2 text-left font-medium text-dark">Date</th>
                  <th class="py-3 px-4 text-left font-medium text-dark">Time</th>
                </tr>
              </thead>
              <tbody v-if="this.meetingsLoaded == true" class="text-sm"> 
                <historical-meeting-card v-for="(meeting, index) in historicalMeetingData" :key="index" :MeetingData="meeting" ></historical-meeting-card>
              </tbody>
            </table>
            <div class="historicalMeetingsLoading">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import HistoricalMeetingCard from '../components/HistoricalMeetingCard.vue';
import { getMeetings, searchMeetings } from '../utilities/CalendarUtilities.js'



export default {
  name: 'MeetingsHistorical',
  data: () => ({
    containerLoadComplete: false,
    fullPage: false,
    historicalMeetingData: [],
    meetingsLoaded: false,
    searchTerm: '',
    isSearching: false
  }),
  components: {
    HistoricalMeetingCard,
    PageHeader
  },
  methods: {
    logout() {
      this.$store.dispatch('meetings/logout');
      this.$store.dispatch('calendars/logout');
      this.$store.dispatch('integrations/logout');
      this.$store.dispatch('authentication/logout').then(() => {
        if (this.loader) {
          this.loader.hide();
          this.loader = null;
        }
        return this.$router.push('/auth/login');
      });
    },
    // method to compare two dates to help determine order of upcoming events //
    dateCompare(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);  
      if (date1.valueOf() < date2.valueOf()) {
        return true
      }
    },
    async searchMeetings(){
      console.log(this.searchTerm);
      let thisSearchTerm = this.searchTerm;

      let user = this.$store.getters["authentication/getUser"];      
      if (!user) {
        this.logout();
      }
      let primaryCalendar = this.$store.getters["calendars/getPrimaryCalendar"];

      //clear results
      this.historicalMeetingData = [];
      this.isSearching = true;
      this.loader = this.$loading.show({
        color: '#1f3fff',
        loader: 'spinner',
        container: this.fullPage ? null : this.$refs.loadingContainer,
        width: 100,
        height: 100,
        backgroundColor: '#ffffff',
        opacity: .5,
        zIndex: 999,
      });

      let searchedMeetings = [];
      if (!primaryCalendar) {        
        searchedMeetings = await searchMeetings(user.uid, user.email, this.searchTerm); 
      } else {
        searchedMeetings = await searchMeetings(user.uid, primaryCalendar, this.searchTerm);  
      }    
      this.$store.commit('meetings/storeSearchMeetings', searchedMeetings); 
      this.$store.commit('meetings/storeSearchMeetingsTerm', thisSearchTerm); 

      let foundMeetings = searchedMeetings;

      //array unique
      let foundMeetingsUnique = [...new Map(foundMeetings.map(v => [v.documentId, v])).values()];

      //sort meetings
      await foundMeetingsUnique.sort(function(a, b) {
        let a_startDateTime = null;
        let b_startDateTime = null;
        if (a.start.dateTime) {
          a_startDateTime = new Date(a.start['dateTime']);
        } else if (a.start.date) {
          a_startDateTime = new Date(a.start['date']);
        }
        if (b.start.dateTime) {
          b_startDateTime = new Date(b.start['dateTime']);
        } else if (b.start.date) {
          b_startDateTime = new Date(b.start['date']);
        }
        let keyA = new Date(a_startDateTime),
            keyB = new Date(b_startDateTime);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;         
      });

      this.historicalMeetingData = foundMeetingsUnique;
      this.isSearching = false;
      this.meetingsLoaded = true;
      this.loader.hide();

      return foundMeetingsUnique;

    },
    // primary function responsible for rendering the calendar events //
    async renderCalendarEvents() {      
      if (!this.$store.getters["meetings/getUserMeetings"]) return;     
      
      let searchMeetings = this.$store.getters["meetings/getSearchMeetings"] ? this.$store.getters["meetings/getSearchMeetings"] : [];
      let allMeetings = this.$store.getters["meetings/getUserMeetings"];
      let user = this.$store.getters["authentication/getUser"];
      let historicalMeetings = [];
      let foundDocumentIds = [];

      

      // stop flow is there are no meetings to load //
      ////if (!allMeetings && !searchMeetings) return;
      if (!allMeetings && !searchMeetings) return;

      // define function to loop through allMeetings, which is the value of the calendarEvents array in local storage //
      
      async function allMeetingsLoop(allMeetings) {
        for (let a = 0; a < allMeetings.length; a++) {
          if (!foundDocumentIds.includes(allMeetings[a].documentId)) {
            foundDocumentIds.push(allMeetings[a].documentId);
            historicalMeetings = historicalMeetings.concat(allMeetings[a]);
          }          
        }        
        return historicalMeetings;
      }

      if (searchMeetings) {
        historicalMeetings = searchMeetings;
      } else {
        await allMeetingsLoop(allMeetings);
      }
      
      // call the allMeetingsLoop function with await so it won't continue until it's done loading everything //
      
      
      // take the historicalMeetings array and order it from soonest to latest //
      
      await historicalMeetings.sort(function(a, b) {
        let a_startDateTime = null;
        let b_startDateTime = null;
        if (a.start.dateTime) {
          a_startDateTime = new Date(a.start['dateTime']);
        } else if (a.start.date) {
          a_startDateTime = new Date(a.start['date']);
        }
        if (b.start.dateTime) {
          b_startDateTime = new Date(b.start['dateTime']);
        } else if (b.start.date) {
          b_startDateTime = new Date(b.start['date']);
        }
        let keyA = new Date(a_startDateTime),
            keyB = new Date(b_startDateTime);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;         
      });


      //array unique
      historicalMeetings = [...new Map(historicalMeetings.map(v => [v.documentId, v])).values()];
      

   
      
      if (searchMeetings) {
        this.historicalMeetingData = historicalMeetings;
      } else {
        this.historicalMeetingData = historicalMeetings.slice(0,50);  
      }

      this.meetingsLoaded = true;
      this.$emit("viewDataLoaded"); 
         
    }
   
  },
  beforeMount() {

    //if we have search data already, lets load it

    if (!this.$store.getters["meetings/getUserMeetings"]) return;
    let searchMeetings = this.$store.getters["meetings/getSearchMeetings"] ? this.$store.getters["meetings/getSearchMeetings"] : [];
    let allMeetings = this.$store.getters["meetings/getUserMeetings"] ? this.$store.getters["meetings/getUserMeetings"] : [];

    this.searchTerm = this.$store.getters["meetings/getSearchMeetingsTerm"] ? this.$store.getters["meetings/getSearchMeetingsTerm"] : '';
      
    // if we have data beforeMount, we can go ahead and load
    if (allMeetings.length > 0 || searchMeetings.length > 0) {
      this.renderCalendarEvents();
    }
  }
}
</script>