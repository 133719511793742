import firebase from "firebase/app";
import { store } from "../../store/index.js";

// get user data //
export const getExistingUser = async (uid) => {
  const userQuery = await firebase.firestore().collection('users').where('uid', '==', uid).get();
  //console.log(userQuery);
  const userData = userQuery.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }));
  console.log('getExistingUser',userData);
  //console.log(userData);
  return userData;
}

// create new user from google signin //
export const createNewUserFromGoogle = async (newUser) => {
	const res = await firebase.firestore().collection('users').add(newUser).then(function(docRef) {
    return addDocumentIdToUser(docRef.id);
  });
  return res;
}

// update an existing google user with new integration credentials upon login //
export const updateGoogleUserIntegrationCredentails = async(documentId, uid, data) => {
  //console.log('doing updateGoogleUserIntegrationCredentails',documentId, uid, data);
const user = await getExistingUser(uid);
let update;

if (user[0].integrations) {
  update = Object.assign(user[0].integrations, data);
} else {
  update = data;
}

//console.log('updateGoogleUserIntegrationCredentails integrations update data',update);

const res = await firebase.firestore().collection('users').doc(documentId).update({ 
  integrations: update
});
//console.log('updateGoogleUserIntegrationCredentails result',res);
return res; //res is undefined here
}
// create new user from email and password signin //
export const createNewUserFromPassword = async (newUser) => {
	const res = await firebase.firestore().collection('users').add(newUser);
  return res;
}

// save user profile information //
export const saveUserProfile = async (documentId, profileData) => {
  const res = await firebase.firestore().collection('users').doc(documentId).update({
    firstName: profileData.firstName,
    lastName: profileData.lastName,
    displayName: profileData.displayName,
    phone: profileData.phone,
    company: profileData.company,
    title: profileData.title,
    avatar: profileData.avatar,
    color: profileData.color
  })
  .then(() => {
    return "success";
  })
  .catch(error => {
    return error;
  });
  return res;
}

// save user preference information //
export const saveUserPreferences = async (documentId, preferenceData) => {
  const res = await firebase.firestore().collection('users').doc(documentId).update({
    preferences: preferenceData
  })
  .then(() => {
    return "success";
  })
  .catch(error => {
    return error;
  });
  return res;
}

export const saveTrelloIntegrationData = async (id, userData, trelloIntegrationData) => {
  const res = await firebase.firestore().collection('users').doc(id).update({
    integrations: Object.assign(userData.integrations, trelloIntegrationData)
  });
	return res;
}

// update user document with its own document id //
export const addDocumentIdToUser = async(documentId) => {
  const res = await firebase.firestore().collection('users').doc(documentId).update({
    documentId: documentId
  });
  return documentId;
}

// helper function for clearing out all state and local storage data on logout //
export const stateCleanupOnLogout = async () => {
  store.commit('application/applicationLogout');
  store.commit('authentication/authLogout');
  store.commit('calendars/calendarsLogout');
  store.commit('integrations/integrationsLogout');
  store.commit('meetings/meetingsLogout');
  store.commit('templates/templatesLogout');
}