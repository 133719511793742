// import necessary libraries //
import Quill from 'quill';
import RecurringMeetingBlot from './format-recurringMeetingBlock-blot.js';

// import store so we can have access to store actions and getters //
import { store } from '../../../../../store/index.js';

// define module-specific variables //
const Delta = Quill.import('delta');
const Module = Quill.import('core/module');

// register format-recurringMeetingBlock-blot //
Quill.register('formats/recurringMeetingBlock', RecurringMeetingBlot);

// declare TaskModule //
class RecurringMeetingModule extends Module {
  constructor(quill, options) {
    super(quill, options);

    // define constructor variables //
    this.quill = quill;
    this.onClose = options.onClose;
    this.onOpen = options.onOpen;
    this.toolbar = quill.getModule('toolbar');
    this.ignoreTags = ['PRE'];


    // initialize task module //
    fn_recurringMeetingBlockInit(quill);
  }

  // function for determining if a value is valid //
  isValid (text, tagName) {
    return (
      typeof text !== 'undefined' && text && this.ignoreTags.indexOf(tagName) === -1
    )
  }


}


RecurringMeetingModule.DEFAULTS = {

};

// main initializiation function for the task module //
function fn_recurringMeetingBlockInit(quill) {

    //quill.insertEmbed(quill.selection.savedRange.index, 'recurringMeetingBlockBlot', {title: "", uuid: "", instantiator: store.getters['authentication/getUser'].uid}, Quill.sources.USER);
    
   
 
}

export default RecurringMeetingModule;