<template>
  <header class="page-header shadow-sm bg-white border-dark font-gtamerica pt-3 pb-1 w-full">
    <div class="max-auto justify-between">
      <div class="flex justify-between px-5 ">
        <div class="">
          <div class="text-4xl font-thin text-black font-gteesti">{{this.$route.meta.title}}</div>
          <div class="font-gtamerica font-normal text-left text-md text-dark rounded-md py-2 rounded-sm">{{this.$route.meta.subtitle}}</div>
        </div>
        <div class="flex items-center">
          <div class="relative">
            <!--<button @click="createInstantMeeting()" class="flex items-center justify-center h-10 px-8 ml-auto text-sm font-normal rounded-full bg-primarytwolight text-primarytwo hover:underline">
              <svg v-show="instantMeetingLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-primarytwo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Instant Meeting
            </button>-->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>


<script>
import { createInstantMeeting, getMeetings } from '../utilities/CalendarUtilities.js';
import { v4 as uuidv4 } from 'uuid';
export default {
  
  name: 'PageHeader',
  data: () => ({
    instantMeetingLoading: false
  }),
  components: {
    
  },
  methods: {
    async createInstantMeeting() {
      this.instantMeetingLoading = true;
      let user = this.$store.getters["authentication/getUser"];
      let currentTime = new Date();
      let startTime = currentTime.toISOString();
      let endTime = new Date();
      endTime.setHours(currentTime.getHours() + 1);
      endTime = endTime.toISOString();

      let newMeetingId = uuidv4();
      let newMeetingData = {
        name: 'Instant Meeting Name',
        uid: user.uid,
        creator: user.uid,
        organizer: user.uid,
        start: startTime,
        end: endTime,
        id: newMeetingId
      };

      await createInstantMeeting(newMeetingData, user).then(async (res) => {
        let createResponse = res.createResponse;
        let newDocumentId = res.newDocumentId;
        if (createResponse.status == 200) {
          await getMeetings(user.uid).then(response => {
            console.log(response)
            this.$store.commit('meetings/storeUserMeetings', response);
            console.log("Response: ", response);
            return;
          }).then(() => {
            this.$router.push({ name: 'NotesEditor', params: { meeting: newMeetingData.id, document: newDocumentId}})
          })
        }
      }).catch(error => {
        return error;
      })
    }
  },
  computed: {

  }
}
</script>